import praanLogo from "../assets/images/praan.svg";
import hive from "../assets/images/hive.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { LogoutIcon } from "../assets/icons/icons";
import { useAppDispatch } from "../store/hooks";
import { logout } from "../store/features/authSlice";
import notificationImage from "../assets/icons/notification.png";
import Modal from "./common/Modal";
import { isDesktop } from "react-device-detect";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const dispatch = useAppDispatch();

  const logoutUser = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <div className="w-screen lg:shadow-container shadow-gray-100 h-16 lg:h-[8vh] flex items-center overflow-hidden z-20 fixed top-0 left-0">
      {visible && (
        <Modal onClose={() => setVisible(false)}>
          <h1 className="font-bold text-xl w-11/12 m-auto">Are you sure you want to Logout?</h1>

          <div className="btns grid grid-cols-2 mt-5 gap-2">
            <button onClick={() => setVisible(false)} className="w-full  bg-[#323232] text-white  font-semibold rounded-lg h-9">
              No
            </button>
            <button onClick={logoutUser} className="w-full bg-[#EFEFEF] text-black rounded-lg font-semibold h-9">
              Yes
            </button>
          </div>
        </Modal>
      )}
      <div className="flex w-full items-center justify-between h-full bg-white px-5">
        <img className="w-20" src={praanLogo} alt="praan logo" />
        {/* <img className="w-4/12" src={hive} alt="Hive" /> */}
        <div className="">
          {location.pathname.includes("settings") && !isDesktop ? (
            <h1 onClick={() => setVisible(true)}>
              {" "}
              <LogoutIcon />
            </h1>
          ) : location.pathname.includes("/rooms/info") ? (
            <div className="">
              {/* <div className="w-[4px] h-[4px] bg-high rounded-full absolute -right-0.5 -top-0.5"></div> */}
              {/* <img src={notificationImage} alt="notification" /> */}
              {/* icon is in the roominfo page only */}
            </div>
          ) : (
            <h1 className="italic font-bold text-2xl">HIVE</h1>
          )}
        </div>
      </div>
    </div>
  );
};
export default Header;
