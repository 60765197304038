import { useListRoomsMutation } from "../../store/services/roomsApi";
import { IoIosArrowForward } from "react-icons/io";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SkeletonText } from "@chakra-ui/react";

const RoomLists = () => {
  const [list, { data: roomData, error: roomError, isError: isRoomError, isSuccess: isRoomSuccess, isLoading: isLoading }] = useListRoomsMutation();

  const navigate = useNavigate();

  const [roomsList, setRoomList] = useState([]);
  useEffect(() => {
    list();
  }, []);

  useEffect(() => {
    if (isRoomSuccess) {
      console.log(roomData);
      setRoomList(roomData);
      console.log("room list updated-> ", roomsList);
    }
  }, [roomData, isRoomSuccess]);

  return (
    <div className="pb-5">
      {isLoading && (
        <div className="w-11/12  flex flex-col gap-5 m-auto">
          {/* <SkeletonCircle /> */}
          <SkeletonText speed={2} startColor="#ededed" />
        </div>
      )}
      {roomsList &&
        roomsList.map((data) => {
          return (
            <div
              key={data._id}
              onClick={() => navigate(`/dashboard/rooms/info/${data._id}`)}
              className="container relative shadow-container w-11/12 px-4 py-3 m-auto mt-3  h-20 flex flex-col justify-between rounded-2xl border border-opacity-10"
            >
              <h4 className="font-semibold text-left">{data.name}</h4>
              <h5 className="  absolute right-1 top-1/2 transform -translate-y-1/2">
                <IoIosArrowForward className="text-2xl text-slate-500" />
              </h5>
              <p className=" text-slate-500 text-left text-sm">
                {data.area}sq. ft. x {data.height}ft.
              </p>
            </div>
          );
        })}
    </div>
  );
};
export default RoomLists;
