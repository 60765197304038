import { useEffect } from "react";
import { useDeviceListMutation } from "../../store/services/deviceApi";
import { useNavigate } from "react-router-dom";

import { IoIosArrowForward } from "react-icons/io";
import NetworkStrength from "../common/NetworkStrength";
import { AiOutlinePoweroff } from "react-icons/ai";
import { SkeletonText } from "@chakra-ui/react";
import hiveImg from "../../assets/images/hiveImage.png";
import { isDesktop } from "react-device-detect";

const DeviceList = () => {
  const [list, { data: deviceData, error: deviceError, isError: isDeviceError, isSuccess: isDeviceSuccess, isLoading: isLoading }] =
    useDeviceListMutation();

  const navigate = useNavigate();

  useEffect(() => {
    list();
  }, []);

  return (
    <div className="pb-5">
      {isLoading && (
        <div className={`   ${isDesktop ? "w-10/12 mt-10" : "w-11/12"} flex flex-col gap-5 m-auto`}>
          {/* <SkeletonCircle /> */}
          <SkeletonText speed={2} startColor="#ededed" />
        </div>
      )}
      {deviceData && (
        <div className="">
          {deviceData.length == 0 && (
            <h1 className="w-4/5 m-auto text-[#9b9b9b]">There are no Devices added yet, please select a room and add a device. </h1>
          )}
          {deviceData.map((device) => {
            return (
              <div
                key={device._id}
                onClick={() => navigate(`/dashboard/devices/${device._id}`)}
                className={`container relative mt-3   ${
                  isDesktop ? "w-10/12 " : "w-11/12"
                } px-4 py-3.5 m-auto shadow-container h-[6.3rem] flex flex-col justify-between rounded-2xl border border-opacity-10`}
              >
                <div className="topRow flex  items-center">
                  <h4 className="font-semibold text-left">{device.name}</h4>
                  <p className="text-slate-500 text-xs font-light ml-1"> | {device.roomObject.name} </p>
                  {/* <p className=" text-slate-500 text-left text-xs">{device.roomObject.name}</p> */}
                </div>
                <img className="w-1/2 max-w-[200px] mt-1" src={hiveImg} alt="" />
                <h5 className="w-full absolute right-2 top-1/2 transform -translate-y-1/2 flex justify-end">
                  <IoIosArrowForward className="text-2xl text-slate-500" />
                </h5>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DeviceList;
