import { useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { SkeletonText } from "@chakra-ui/react";
import { isDesktop } from "react-device-detect";

const Modal = (props) => {
  const { isLoading } = props.isLoading ? props.isLoading : false;
  const navigate = useNavigate();

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="w-screen h-screen z-40 bg-black backdrop-blur-[2px] bg-opacity-80 flex justify-center items-center  fixed top-0 left-0">
      <div className={`modal bg-white rounded-2xl ${isDesktop ? "w-4/12" : "w-10/12"}  ${props.modalStyle ? props.modalStyle : ""}  `}>
        <div className={`relative w-full  h-full py-10 px-5 `}>
          <span onClick={handleClose} className="absolute top-3 right-4 text-2xl">
            {" "}
            {props.isLoading && (
              <div className="w-11/12   flex flex-col gap-5 m-auto">
                {/* <SkeletonCircle /> */}
                <SkeletonText speed={2} startColor="#ededed" />
              </div>
            )}
            <AiOutlineClose />{" "}
          </span>
          {props.children}
        </div>
      </div>
    </div>
  );
};
export default Modal;
