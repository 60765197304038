import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";

import { useCreateDeviceMutation } from "../../store/services/deviceApi";
import { useEffect } from "react";

import { useToast } from "@chakra-ui/react";

import Modal from "../common/Modal";

import { useState } from "react";
import { useZxing } from "react-zxing";
import { FaCamera } from "react-icons/fa6";
import CustomDropdown from "../common/CustomDropdown";

const initialValues = {
  name: "",
  deviceId: "",
};

const roomsSchema = Yup.object().shape({
  name: Yup.string().min(2, "Name is too short!").max(50, "Name is too long!").required("Device Name is required"),
  deviceId: Yup.string().required("Device ID Is Required"),
});

const AddDevice = () => {
  const navigate = useNavigate();
  const [createDevice, { data: deviceData, error: deviceError, isError: isDeviceError, isSuccess: isDeviceSuccess }] = useCreateDeviceMutation();
  const toast = useToast();

  const { roomID } = useParams();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: initialValues,
    validationSchema: roomsSchema,
    onSubmit: (values, action) => {
      console.log(values);
      const bodyObject = {
        room: roomID,
        body: values,
      };
      createDevice(bodyObject);
      // action.resetForm();
    },
  });

  useEffect(() => {
    if (isDeviceSuccess) {
      toast({
        title: "Device Added Successfully",
        status: "success",
        isClosable: true,
      });
      navigate(`/dashboard/devices/${deviceData._id}`);
    }
  }, [isDeviceSuccess, deviceError]);

  useEffect(() => {
    if (isDeviceError) {
      console.log(deviceError);
      toast({
        title: `${deviceError.message}`,
        status: "error",
        isClosable: true,
      });
    }
  }, [isDeviceError, deviceError]);

  const [openCamera, setOpenCamera] = useState(false);
  // const [devices, setDevices] = useState([]);
  // const [selectedDevice, setSelectedDevice] = useState([]);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const availableDevices = await navigator.mediaDevices.enumerateDevices();
  //       const availableVideoDevices = availableDevices.filter((device) => device.kind === "videoinput");
  //       if (availableVideoDevices.length === 0) {
  //         console.log("No cameras found");
  //       } else {
  //         setDevices(availableVideoDevices);
  //         console.log(availableDevices);
  //       }
  //     } catch (e) {
  //       console.log(e);
  //       // setSnackbarMessage('Failed to find cameras. This could be permissions problem');
  //       // setSnackbarOpen(true);
  //     }
  //   })();
  // }, []);

  const { ref } = useZxing({
    onError(err) {
      console.log("error", err);
    },
    paused: !openCamera,
    onDecodeResult(result) {
      console.log(typeof +result.getText());
      if (+result.getText()) {
        setFieldValue("deviceId", result.getText());
        setOpenCamera(false);
      }
    },
    // deviceId: selectedDevice,
  });

  return (
    <Modal>
      <h1 className="font-bold text-2xl">Add Device</h1>

      {openCamera && (
        <Modal onClose={() => setOpenCamera(false)}>
          <div className="my-4 relative">
            <h1 className="font-semibold  text-xl">Scan Bar Code On Device</h1>
            {/* <p className="font-semibold text-left">Select Camera</p>
            <p>{JSON.stringify(devices)}</p>
            <CustomDropdown
              options={devices.map((device) => {
                return { value: device.deviceId, label: device.label };
              })}
              onChange={(val) => setSelectedDevice(val)}
              selectedValue={selectedDevice}
            /> */}
          </div>
          <video ref={ref} className={`${openCamera ? "block" : "hidden"}`} />
        </Modal>
      )}

      <form onSubmit={handleSubmit} className="mt-5" action="">
        <div className="inpGrp text-left relative">
          <label className=" font-semibold">Name Of The Device</label>
          <input
            name="name"
            id="name"
            value={values.name}
            onChange={handleChange}
            type="text"
            placeholder=""
            className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md w-full"
          />
          <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.name && touched.name ? errors.name : ""}</span>
        </div>

        <div className="inpGrp text-left mt-4 relative">
          <label className=" font-semibold">Device Number</label>
          <div className="flex bg-[#F5F5F5] rounded-md pr-3">
            <input
              name="deviceId"
              id="deviceId"
              value={values.deviceId}
              onChange={handleChange}
              type="text"
              placeholder=""
              className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md w-full relative"
            />
            <button type="button" onClick={() => setOpenCamera(true)}>
              <FaCamera />
            </button>
          </div>
          <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.deviceId && touched.deviceId ? errors.deviceId : ""}</span>
        </div>
        <button type="submit" className="bg-[#323232] text-white w-3/5 h-8 mt-6 rounded-md">
          Add Device
        </button>
      </form>
    </Modal>
  );
};

export default AddDevice;
