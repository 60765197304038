//React/Next/ThirdPart/Custom
import { useEffect } from "react";

import { useFormik } from "formik";
import { useForgotPasswordMutation } from "../../store/services/authApi";

import { useAppDispatch } from "../../store/hooks";
import { setUser } from "../../store/features/authSlice";

import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import praanLogo from "../../assets/images/praan.svg";

const initialValues = {
  email: "",
};
const ForgotPassword = () => {
  //importing api mutation to call api and access data
  const [sendForgotRequest, { data: forgotData, error: forgotError, isError: isforgotError, isSuccess: isForgotSuccess }] =
    useForgotPasswordMutation();

  const dispatch = useAppDispatch();
  const toast = useToast();
  const navigate = useNavigate();

  //using formik to manage form
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
      sendForgotRequest(values);
      action.resetForm();
    },
  });

  //displaying toast on successfull login and saving the userdata along with the token
  useEffect(() => {
    if (isForgotSuccess) {
      toast({
        title: forgotData,
        status: "success",
        isClosable: true,
      });
    }
  }, [isForgotSuccess, forgotError]);

  //displaying a toast if an error occurs
  useEffect(() => {
    if (isforgotError) {
      console.log(forgotError);
      toast({
        title: `${forgotError.message}`,
        status: "error",
        isClosable: true,
      });
    }
  }, [forgotError, isforgotError]);

  return (
    <main className="h-full  flex p-10">
      <section className="flex-1 flex items-center justify-center text-black ">
        <div className="w-full max-w-[30rem]">
          <p className="w-full flex justify-center ">
            <img src={praanLogo} alt="praan logo" className="max-w-[10rem] w-6/12 align-middle " />
          </p>
          <form onSubmit={handleSubmit} className="flex flex-col text-black ">
            <h1 className="font-medium text-[#323232] text-2xl text-center">Forgot Password?</h1>
            <br />
            <p className="text-sm text-gray-400">
              Enter the email address associated with your account and we'll send you a link to reset your password.
            </p>
            <br />
            <div className="flex items-center w-full bg-[#EDEDED] my-2 py-2 px-4 rounded-md ">
              <input
                className="bg-[#EDEDED] w-full focus:outline-none text-slate-700"
                type="email"
                name="email"
                id="email"
                placeholder="abc@company.com"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {errors.email && touched.email ? <p className="text-red-600">{errors.email}</p> : null}
            <div onClick={() => navigate("/login")} className="forget cursor-pointer underline text-[#41464E] font-light underline-offset-2">
              Back
            </div>
            <div className="flex justify-center mt-8">
              <button
                type="submit"
                className="text-white w-4/5 bg-[#323232] hover:bg-blue-800 focus:ring-4 focus:outline-none h-8 font-medium rounded-lg text-sm text-center mr-3 md:mr-0"
              >
                Continue
              </button>
            </div>
          </form>
        </div>
      </section>
    </main>
  );
};

export default ForgotPassword;
