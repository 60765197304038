import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box } from "@chakra-ui/react";
// import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import hiveImg from "../../assets/images/hiveImage.png";
import { useDeviceListMutation } from "../../store/services/deviceApi";
import { useGetFaqsMutation } from "../../store/services/faqApi";
import { useNewTicketMutation } from "../../store/services/ticketApi";

import NetworkStrength from "../../components/common/NetworkStrength";
import { SkeletonText } from "@chakra-ui/react";

import { IoAlertCircleOutline } from "react-icons/io5";
import Modal from "../../components/common/Modal";
import CustomDropdown from "../../components/common/CustomDropdown";
import { useFormik } from "formik";

import { useToast } from "@chakra-ui/react";
import { isDesktop } from "react-device-detect";
let initialValues = {
  device: "",
  message: "",
};

const Support = () => {
  const toast = useToast();

  const [deviceList, { data: deviceData, isSuccess: deviceSuccess, isLoading: deviceLoading }] = useDeviceListMutation();
  const [getFaq, { data: faqData, isLoading: faqLoading }] = useGetFaqsMutation();
  const [newTicket, { data: ticketData, isSuccess: ticketSuccess, isError: isTicketError, error: ticketError }] = useNewTicketMutation();

  const [isVisible, setIsVisible] = useState(false);
  const [options, setOptions] = useState([{ label: "", value: "" }]);
  // const navigate = useNavigate();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
      console.log(values);

      if (!values.device) {
        toast({
          title: `Please Select A Device!`,
          status: "error",
          isClosable: true,
        });
      } else {
        const bodyObject = {
          deviceId: values.device,
          body: values,
        };
        newTicket(bodyObject);
        action.resetForm();
      }
    },
  });

  const onClose = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    if (ticketSuccess) {
      toast({
        title: "Ticket Created Successfully",
        description: "A Support Person will soon contact you regarding your concern.",
        status: "success",
        isClosable: true,
      });
    }
  }, [ticketSuccess, ticketData]);

  useEffect(() => {
    if (isTicketError) {
      toast({
        title: `${ticketError.message ? ticketError.message : "Error Creating Ticket please try again"}`,
        status: "error",
        isClosable: true,
      });
    }
  }, [ticketError, isTicketError]);

  useEffect(() => {
    deviceList();
    getFaq();
  }, []);

  useEffect(() => {
    if (deviceSuccess) {
      let optionValues = [];
      for (let i = 0; i < deviceData.length; i++) {
        let device = deviceData[i];
        optionValues.push({ label: device.name, value: device._id });
      }
      setOptions(optionValues);
    }
  }, [deviceSuccess, deviceData]);

  return (
    <div className={`text-left  ${isDesktop ? "w-10/12 mt-10" : "w-11/12"}  relative h-screen-safe m-auto`}>
      {isVisible && (
        <Modal onClose={onClose}>
          <h1 className="font-semibold text-xl text-center">Report A Problem</h1>

          <form onSubmit={handleSubmit}>
            <h2 className="font-semibold mt-4 mb-2">Select Device</h2>
            {/* dropdown here */}
            <div className="w-full ">
              <CustomDropdown options={options} selectedValue={values.device} onChange={(device) => setFieldValue("device", device)} />
            </div>
            <h2 className="font-semibold mt-4 mb-2">Problem Description</h2>
            <textarea
              type="textarea"
              name="message"
              value={values.message}
              onChange={handleChange}
              rows={7}
              placeholder="Explain your problem here..."
              className="bg-[#f5f5f5] w-full m-auto rounded-lg  p-2 px-3 text-sm resize-none "
            />
            <div className="flex justify-center w-full">
              <button type="submit" className="bg-black font-semibold text-white rounded-lg h-10 w-32  mt-5 ">
                Report
              </button>
            </div>
          </form>
        </Modal>
      )}
      <h1 className="font-bold text-2xl mt-3 mb-5 ml-1 ">Support</h1>
      <h1 className="font-bold text-xl mt-3 mb-5 ml-1 ">FAQs</h1>
      {faqLoading && (
        <div className="  flex flex-col gap-5 m-auto">
          {/* <SkeletonCircle /> */}
          <SkeletonText speed={2} startColor="#ededed" />
        </div>
      )}

      <Accordion borderRadius={"3xl"} allowMultiple mb={"2"}>
        {faqData &&
          faqData.map((faq) => {
            return (
              <AccordionItem
                minHeight={"12"}
                borderRadius={"xl"}
                // bgColor={"#F9F9F9"}
                // shadow={"md"}
                className="shadow-container"
                border={"0px"}
                marginTop={"3"}
                borderColor={"rgba(0, 0, 0, 0.1)"}
                _hover={{ bgColor: "#fff" }}
              >
                <h2>
                  <AccordionButton position={"relative"} _hover={{ bgColor: "#fff" }} borderRadius={"xl"} fontSize={"2xl"}>
                    <Box as="span" flex="1" textAlign="left" fontWeight={"semibold"} fontSize={"md"}>
                      {faq.heading}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={"8"} borderRadius={"xl"}>
                  {faq.description}
                </AccordionPanel>
              </AccordionItem>
            );
          })}
      </Accordion>

      <div
        className={`report w-full   fixed bottom-safe-offset-20 left-1/2 text-semiBlack -translate-x-1/2 flex flex-col items-center justify-center`}
      >
        <div onClick={() => setIsVisible(true)} className="inline-flex items-center border-b-[1px] border-current mb-5">
          <IoAlertCircleOutline className="text-xl" /> <p className="ml-1"> Report</p>
        </div>
        <p className="text-sm  ">For any further queries, write to us at:</p>
        <p className="text-sm ">hive_support@praan.io</p>
      </div>

      <div className="comment">
        {/* <Accordion borderRadius={"3xl"} allowMultiple>
        {deviceData &&
          deviceData.map((device) => {
            return (
              <AccordionItem
                minHeight={"14"}
                borderRadius={"xl"}
                bgColor={"#F9F9F9"}
                border={"1px"}
                marginTop={"3"}
                borderColor={"rgba(0, 0, 0, 0.1)"}
                _hover={{ bgColor: "#F9F9F9" }}
              >
                {({ isExpanded }) => (
                  <>
                    <h2>
                      <AccordionButton position={"relative"} borderRadius={"xl"} _hover={{ bgColor: "#F9F9F9" }}>
                        <Box as="span" flex="1" textAlign="left" fontWeight={"bold"}>
                          <div className="flex items-center">
                            {device.name} <p className="text-slate-500 text-xs font-light ml-1"> | {device.roomObject.name} </p>
                          </div>
                          <img className="w-1/2 mt-1" src={hiveImg} alt="" />
                        </Box>
                        <div className="network absolute h-4/5 items-center flex flex-col justify-between  top-2 right-2">
                          <NetworkStrength signalStrength={device.networkStrength} signalType={device.networkType} />{" "}
                          <AccordionIcon fontSize={"2xl"} />
                        </div>
                      </AccordionButton>
                    </h2>

                    <AccordionPanel pb={"8"} bgColor={"#F9F9F9"} borderRadius={"xl"} borderColor={"rgba(0, 0, 0, 0.1)"}>
                      <div className="h-16 relative mt-1">
                        <div className="name w-full items-center">
                          <div className="flex items-center">
                            <p className="font-semibold text-[13px] mr-1">HEPA Filter:</p>{" "}
                            <p className="text-[13px]">{device.deviceFilterStatus.hepaDaysRemaining} Days </p>
                          </div>
                          <div className="flex items-center">
                            <p className="font-semibold text-[13px] mr-1">ACF Filter:</p>{" "}
                            <p className="text-[13px]">{device.deviceFilterStatus.acfDaysRemaining} Days </p>
                          </div>
                        </div>
                        <div className="maintainenceBtns  flex justify-between items-center mt-3 text-[14px]">
                          <div className="underline  underline-offset-2">Maintainence History</div>
                          <button className="bg-black text-white rounded-md p-1 px-2">Schedule Maintainence</button>
                        </div>
                      </div>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            );
          })}
      </Accordion> */}
      </div>
    </div>
  );
};
export default Support;
