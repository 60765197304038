import { useNavigate } from "react-router-dom";

const Offline = () => {
  const navigate = useNavigate();
  return (
    <div className="w-screen fixed  top-0 z-20 h-screen flex-col bg-red-500 flex justify-center items-center">
      <h1 className="w-4/5 text-xl font-bold">Unable to contact server. Please Check your internet connection</h1>
      <button onClick={() => navigate("/dashboard")} className="bg-black text-white mt-10 px-4 py-1 rounded-md text-lg">
        Try Again
      </button>
    </div>
  );
};
export default Offline;
