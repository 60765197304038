//React/Next/ThirdPart/Custom
import { useEffect, useState } from "react";

import { useFormik } from "formik";
import { useDeleteUserMutation } from "../../store/services/authApi";

import { useAppDispatch } from "../../store/hooks";
import { setUser } from "../../store/features/authSlice";

import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import praanLogo from "../../assets/images/praan.svg";
import { isDesktop } from "react-device-detect";

const initialValues = {
  email: "",
  password: "",
};
const DeleteAccount = () => {
  //importing api mutation to call api and access data
  const [deleteUser, { data: deleteData, error: deleteError, isError: isdeleteError, isSuccess: isdeleteSuccess }] = useDeleteUserMutation();
  const dispatch = useAppDispatch();
  const toast = useToast();
  const navigate = useNavigate();

  //using formik to manage form
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
      deleteUser(values);
      action.resetForm();
    },
  });

  //displaying toast on successfull delete and saving the userdata along with the token
  useEffect(() => {
    if (isdeleteSuccess) {
      console.log("delete DATA = ", deleteData);

      toast({
        title: "Success",
        description: "Your account has been deleted successfully",
        status: "success",
        isClosable: true,
      });
      navigate("/login");
    }
  }, [isdeleteSuccess, deleteError]);

  //displaying a toast if an error occurs
  useEffect(() => {
    if (isdeleteError) {
      console.log(deleteError);
      toast({
        title: `${deleteError.message}`,
        status: "error",
        isClosable: true,
      });
    }
  }, [isdeleteError, deleteError]);

  return (
    <main className="h-full  flex items-center p-10">
      <section className="flex-1 flex items-center justify-center text-black ">
        <div className="w-full max-w-[30rem]">
          <p className="w-full flex justify-center ">
            <img src={praanLogo} alt="praan logo" className="max-w-[10rem] w-6/12 align-middle " />
          </p>
          <form onSubmit={handleSubmit} className="flex flex-col text-black ">
            <h1 className="font-medium text-[#323232] text-2xl text-center">Deleting Your HIVE Account</h1>
            <h3 className="font-medium  text-sm text-slate-500 text-center">
              Please Note: Deleting your HIVE Account will permanently delete all of your previous data from our servers (including the rooms and
              devices you have addeds) and you will not be able to control your HIVE device.
            </h3>
            <br />
            <div className="flex items-center w-full bg-[#EDEDED] my-2 py-2 px-4 rounded-md ">
              <input
                className="bg-[#EDEDED] w-full focus:outline-none text-slate-700"
                type="email"
                name="email"
                id="email"
                placeholder="Email Address"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {errors.email && touched.email ? <p className="text-red-600">{errors.email}</p> : null}

            <div className="flex items-center w-full bg-[#EDEDED] my-2 py-2 px-4 rounded-md ">
              <input
                className="bg-[#EDEDED] w-full focus:outline-none text-slate-700"
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <br />

            <div className="flex justify-center mt-8">
              <button
                type="submit"
                className="text-white w-4/5 bg-[#323232] hover:bg-blue-800 focus:ring-4 focus:outline-none h-10 font-medium rounded-lg text-sm text-center mr-3 md:mr-0"
              >
                D E L E T E
              </button>
            </div>
          </form>
        </div>
      </section>
    </main>
  );
};

export default DeleteAccount;
