import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box } from "@chakra-ui/react";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import hiveImg from "../../assets/images/hiveImage.png";
import { TbSettings } from "react-icons/tb";
import { BiSolidEdit } from "react-icons/bi";

import { useDeviceListMutation } from "../../store/services/deviceApi";
import { useListRoomsMutation } from "../../store/services/roomsApi";
import { isDesktop } from "react-device-detect";
import { useToggleNotificationsMutation, useGetUserInfoMutation } from "../../store/services/usersApi";

import { Switch } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";

import CustomDropDown from "../../components/common/CustomDropdown";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";

const Settings = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userRole = useAppSelector((state) => state?.auth?.user?.response?.role);

  const [deviceList, { data: deviceData }] = useDeviceListMutation();
  const [roomList, { data: roomData }] = useListRoomsMutation();
  const [getUserInfo, { data: userData, isSuccess: userSuccess }] = useGetUserInfoMutation();
  const [toggleNotifications, { data: notificationData, isSuccess: notificationSuccess }] = useToggleNotificationsMutation();
  const toast = useToast();
  const [notifications, setNotifications] = useState({
    p1: true,
    p25: true,
    p10: true,
    temperature: true,
    humidity: true,
    voc: true,
    co2: true,
    sound: true,
  });

  let [selectedInterval, setSelectedInterval] = useState("hour");
  let alertIntervalOptions = [
    { label: "1 per hour", value: "hour" },
    { label: "1 per day", value: "day" },
    { label: "1 per Week", value: "week" },
  ];

  const handleSubmit = () => {
    toggleNotifications({ notification: notifications, interval: selectedInterval });
  };

  useEffect(() => {
    if (userSuccess) {
      if (userData.notification) setNotifications(userData.notification);
      if (userData.notificationInterval) setSelectedInterval(userData.notificationInterval);
    }
    console.log(userData);
  }, [userSuccess, userData]);

  useEffect(() => {
    if (notificationSuccess) {
      toast({
        title: "Notification Toggled Successfully",
        status: "success",
        isClosable: true,
      });
    }
    console.log(notifications);
  }, [notificationSuccess, notificationData]);

  useEffect(() => {
    deviceList();
    getUserInfo();
    roomList();
  }, []);

  return (
    <div className={`text-left   ${isDesktop ? "w-10/12 mt-10" : "w-11/12"} m-auto `}>
      <h1 className="font-bold text-2xl mt-3 mb-5 ml-1 ">Settings</h1>

      <Accordion borderRadius={"3xl"} allowMultiple mb={"2"}>
        <AccordionItem
          minHeight={"14"}
          borderRadius={"xl"}
          // bgColor={"#F9F9F9"}
          // shadow={"md"}
          className="shadow-container"
          border={"0px"}
          marginTop={"3"}
          borderColor={"rgba(0, 0, 0, 0.1)"}
          _hover={{ bgColor: "#fff" }}
        >
          {({ isExpanded }) => (
            <>
              <h2 className="flex items-center pt-1">
                <AccordionButton borderRadius={"xl"} display={"flex"} alignContent={"center"} _hover={{ bgColor: "#fff" }}>
                  <Box as="span" flex="1" textAlign="left" fontWeight={"bold"} fontSize={"lg"}>
                    Devices
                  </Box>
                  <AccordionIcon fontSize={"3xl"} />
                </AccordionButton>
              </h2>
              {isExpanded && <div className=" h-px w-4/5 m-auto"></div>}
              <AccordionPanel overflow={"scroll"} pb={4} bgColor={"#fff"} borderRadius={"xl"} borderColor={"rgba(0, 0, 0, 0.1)"}>
                {deviceData &&
                  deviceData.map((device) => {
                    return (
                      <div className="min-h-[64px] relative mt-2">
                        <div className="name flex w-4/5 items-center">
                          <h1 className="font-bold text-md mr-1">{device.name}</h1> |{" "}
                          <p className="text-xs text-slate-500 ml-1">{device.roomObject.name}</p>
                        </div>
                        <img className="w-1/2 max-w-[200px] mt-1" src={hiveImg} alt="" />
                        <div className="action absolute right-2 top-2 flex text-xl">
                          {/* <TbSettings onClick={() => navigate(`/settings/deviceControls/${device._id}`)} className="mr-4 cursor-pointer" /> */}
                          <BiSolidEdit
                            className="text-gray-500"
                            onClick={() => navigate(`/settings/editDevice/${device.name}/${device._id}/${device.room}`)}
                          />
                        </div>
                      </div>
                    );
                  })}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
        <AccordionItem
          minHeight={"14"}
          borderRadius={"xl"}
          // bgColor={"#F9F9F9"}
          // shadow={"md"}
          className="shadow-container"
          border={"0px"}
          marginTop={"3"}
          borderColor={"rgba(0, 0, 0, 0.1)"}
          _hover={{ bgColor: "#fff" }}
        >
          {({ isExpanded }) => (
            <>
              <h2 className="flex items-center pt-1">
                <AccordionButton borderRadius={"xl"} _hover={{ bgColor: "#fff" }}>
                  <Box as="span" flex="1" textAlign="left" fontWeight={"bold"} fontSize={"lg"}>
                    Rooms
                  </Box>
                  <AccordionIcon fontSize={"3xl"} />
                </AccordionButton>
              </h2>
              {isExpanded && <div className=" h-px w-4/5 m-auto"></div>}
              <AccordionPanel pb={4} bgColor={"#fff"} borderRadius={"xl"} borderColor={"rgba(0, 0, 0, 0.1)"}>
                {roomData &&
                  roomData.map((room) => {
                    return (
                      <div className="h-12 relative mt-2">
                        <div className="name w-3/5 items-center">
                          <h1 className="font-bold text-md mr-1">{room.name}</h1>
                          <p className="text-xs text-slate-500">
                            {room.area}sq. ft. x {room.height}ft.
                          </p>
                        </div>
                        <div className="action absolute right-2 top-2 flex text-xl">
                          <BiSolidEdit className="text-gray-500" onClick={() => navigate(`/settings/editRooms/${room._id}`)} />
                        </div>
                      </div>
                    );
                  })}
                <p onClick={() => navigate("/dashboard/addRoom")} className="text-center text-sm mt-3 text-gray-500 underline">
                  + Add Room
                </p>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
        <AccordionItem
          minHeight={"14"}
          borderRadius={"xl"}
          // bgColor={"#F9F9F9"}
          // shadow={"md"}
          className="shadow-container"
          border={"0px"}
          marginTop={"3"}
          borderColor={"rgba(0, 0, 0, 0.1)"}
          _hover={{ bgColor: "#fff" }}
        >
          {({ isExpanded }) => (
            <>
              <h2 className="flex items-center pt-1">
                <AccordionButton borderRadius={"xl"} _hover={{ bgColor: "#fff" }}>
                  <Box as="span" flex="1" textAlign="left" fontWeight={"bold"} fontSize={"lg"}>
                    Toggle Alerts
                  </Box>
                  <AccordionIcon fontSize={"3xl"} />
                </AccordionButton>
              </h2>
              {isExpanded && <div className=" h-px w-4/5 m-auto"></div>}
              <AccordionPanel pb={4} bgColor={"#fff"} borderRadius={"xl"} borderColor={"rgba(0, 0, 0, 0.1)"}>
                <div className="h-12 relative mt-2 ">
                  <div className="name w-3/5 items-center">
                    <h1 className="font-bold text-md mr-1 absolute top-1/2 -translate-y-1/2">Alert Interval</h1>
                  </div>
                  <div className="action z-30 absolute right-2 top-1/2 -translate-y-1/2 flex text-xl ">
                    <CustomDropDown
                      onChange={(val) => setSelectedInterval(val)}
                      selectedValue={selectedInterval}
                      options={alertIntervalOptions}
                    ></CustomDropDown>
                  </div>
                </div>
                <p className="text-sm text-center text-gray-400">Please select the values most relevant for you.</p>

                <div className="h-12 relative mt-2">
                  <div className="name w-3/5 items-center">
                    <h1 className="font-bold text-md mr-1">PM 1.0</h1>
                  </div>
                  <div className="action absolute right-2 top-2 flex text-xl">
                    {" "}
                    <Switch
                      id="isChecked"
                      isChecked={notifications.p1}
                      onChange={(change) =>
                        setNotifications((val) => {
                          return { ...val, p1: change.target.checked };
                        })
                      }
                      value={notifications.p1}
                    />
                  </div>
                </div>
                <div className="h-12 relative mt-2">
                  <div className="name w-3/5 items-center">
                    <h1 className="font-bold text-md mr-1">PM 2.5</h1>
                  </div>
                  <div className="action absolute right-2 top-2 flex text-xl">
                    {" "}
                    <Switch
                      id="isChecked"
                      isChecked={notifications.p25}
                      onChange={(change) =>
                        setNotifications((val) => {
                          return { ...val, p25: change.target.checked };
                        })
                      }
                      value={notifications.p25}
                    />
                  </div>
                </div>
                <div className="h-12 relative mt-2">
                  <div className="name w-3/5 items-center">
                    <h1 className="font-bold text-md mr-1">PM 10</h1>
                  </div>
                  <div className="action absolute right-2 top-2 flex text-xl">
                    {" "}
                    <Switch
                      id="isChecked"
                      isChecked={notifications.p10}
                      onChange={(change) =>
                        setNotifications((val) => {
                          return { ...val, p10: change.target.checked };
                        })
                      }
                      value={notifications.p10}
                    />
                  </div>
                </div>
                <div className="h-12 relative mt-2">
                  <div className="name w-3/5 items-center">
                    <h1 className="font-bold text-md mr-1">Temperature</h1>
                  </div>
                  <div className="action absolute right-2 top-2 flex text-xl">
                    {" "}
                    <Switch
                      id="isChecked"
                      isChecked={notifications.temperature}
                      onChange={(change) =>
                        setNotifications((val) => {
                          return { ...val, temperature: change.target.checked };
                        })
                      }
                      value={notifications.temperature}
                    />
                  </div>
                </div>
                <div className="h-12 relative mt-2">
                  <div className="name w-3/5 items-center">
                    <h1 className="font-bold text-md mr-1">Humidity</h1>
                  </div>
                  <div className="action absolute right-2 top-2 flex text-xl">
                    {" "}
                    <Switch
                      id="isChecked"
                      isChecked={notifications.humidity}
                      onChange={(change) =>
                        setNotifications((val) => {
                          return { ...val, humidity: change.target.checked };
                        })
                      }
                      value={notifications.humidity}
                    />
                  </div>
                </div>
                <div className="h-12 relative mt-2">
                  <div className="name w-3/5 items-center">
                    <h1 className="font-bold text-md mr-1">CO2</h1>
                  </div>
                  <div className="action absolute right-2 top-2 flex text-xl">
                    {" "}
                    <Switch
                      id="isChecked"
                      isChecked={notifications.co2}
                      onChange={(change) =>
                        setNotifications((val) => {
                          return { ...val, co2: change.target.checked };
                        })
                      }
                      value={notifications.co2}
                    />
                  </div>
                </div>
                <div className="h-12 relative mt-2">
                  <div className="name w-3/5 items-center">
                    <h1 className="font-bold text-md mr-1">VOC</h1>
                  </div>
                  <div className="action absolute right-2 top-2 flex text-xl">
                    {" "}
                    <Switch
                      id="isChecked"
                      isChecked={notifications.voc}
                      onChange={(change) =>
                        setNotifications((val) => {
                          return { ...val, voc: change.target.checked };
                        })
                      }
                      value={notifications.voc}
                    />
                  </div>
                </div>
                <div className="h-12 relative mt-2">
                  <div className="name w-3/5 items-center">
                    <h1 className="font-bold text-md mr-1">Sound</h1>
                  </div>
                  <div className="action absolute right-2 top-2 flex text-xl">
                    {" "}
                    <Switch
                      id="isChecked"
                      isChecked={notifications.sound}
                      onChange={(change) =>
                        setNotifications((val) => {
                          return { ...val, sound: change.target.checked };
                        })
                      }
                      value={notifications.sound}
                    />
                  </div>
                </div>

                <div className="flex justify-center">
                  <button
                    onClick={() => {
                      handleSubmit();
                    }}
                    className="text-center p-2 border-2 px-4 rounded-md border-black bg-black text-white  font-semibold m-auto text-sm mt-3"
                  >
                    Update
                  </button>
                </div>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
        {/* <AccordionItem
          minHeight={"14"}
          borderRadius={"xl"}
          // bgColor={"#F9F9F9"}
          // shadow={"md"}
          className="shadow-container"
          border={"0px"}
          marginTop={"3"}
          borderColor={"rgba(0, 0, 0, 0.1)"}
          _hover={{ bgColor: "#fff" }}
        >
          {({ isExpanded }) => (
            <>
              <h2 className="flex items-center pt-1">
                <AccordionButton borderRadius={"xl"} _hover={{ bgColor: "#fff" }}>
                  <Box as="span" flex="1" textAlign="left" fontWeight={"bold"} fontSize={"lg"}>
                    Schedule
                  </Box>
                  <AccordionIcon fontSize={"3xl"} />
                </AccordionButton>
              </h2>
              {isExpanded && <div className=" h-px w-4/5 m-auto"></div>}
              <AccordionPanel pb={4} bgColor={"#fff"} borderRadius={"xl"} borderColor={"rgba(0, 0, 0, 0.1)"}>
                {roomData &&
                  roomData.map((room) => {
                    return (
                      <div className="h-12 relative mt-2">
                        <div className="name w-3/5 items-center">
                          <h1 className="font-bold text-md mr-1">{room.name}</h1>
                          <p className="text-xs text-slate-500">
                            {room.area}sq. ft. x {room.height}ft.
                          </p>
                        </div>
                        <div className="action absolute right-2 top-2 flex text-xl">
                          <BiSolidEdit className="text-gray-500" onClick={() => navigate(`/settings/schedule/${room._id}`)} />
                        </div>
                      </div>
                    );
                  })}
              </AccordionPanel>
            </>
          )}
        </AccordionItem> */}
      </Accordion>

      {(userRole == "admin" || userRole == "super-admin") && (
        <div
          onClick={() => navigate("/clients")}
          className="container mt-3 w-full  bg-white shadow-container px-5 flex  text-lg items-center    m-auto h-14 rounded-xl mb-3 font-bold"
        >
          Clients
        </div>
      )}
    </div>
  );
};

export default Settings;
