import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const alertsApi = createApi({
  reducerPath: "alertsApi",
  // configuring baseQuery
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
    prepareHeaders: (headers, { getState }) => {
      console.log("getState", getState);
      const token = JSON.parse(localStorage.getItem("user") || "").token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
    },
  }),
  endpoints: (builder) => ({
    // check if the user has room
    getAlertCount: builder.mutation({
      query: (alertObject) => {
        return {
          url: `/alerts/${alertObject.roomId}`,
          method: "get",
          params: { date: alertObject.date },
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
  }),
});

export const { useGetAlertCountMutation } = alertsApi;
