import { useEffect, useState } from "react";
import {
  useFindDeviceByIdQuery,
  useDeviceControlMutation,
  useDownloadDeviceDataMutation,
  useResetFilterHealthMutation,
} from "../../store/services/deviceApi";
import { useParams } from "react-router-dom";
import hepaImage from "../../assets/images/hepaFilter.png";

import hivefan0 from "../../assets/images/hive_fan_0.png";
import hiveFan1 from "../../assets/images/hive_fan_1.png";
import hiveFan2 from "../../assets/images/hive_fan_2.png";
import hiveFan3 from "../../assets/images/hive_fan_3.png";
import hiveFan4 from "../../assets/images/hive_fan_4.png";
import NetworkStrength from "../common/NetworkStrength";
import { AiOutlinePoweroff } from "react-icons/ai";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useAppSelector } from "../../store/hooks.js";
import { Switch, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import Loading from "../common/Loading.jsx";

import { useNavigate } from "react-router-dom";
import { FanSilent, FanStandard, FanTurbo, FanDeep, Filter } from "../../assets/icons/icons.jsx";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";

import Modal from "../common/Modal.jsx";
import { Spinner } from "@chakra-ui/react";

import PerformanceReport from "../../pages/performanceReport/PerformanceReport.jsx";

let initialValues = {
  displayStatus: true,
  displayValue: "01",
  fanMode: "",
};

const DeviceInfoDesktop = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const {
    data: deviceData,
    isSuccess: deviceSuccess,
    error: deviceError,
    isError: isDeviceError,
    refetch,
  } = useFindDeviceByIdQuery(id, {
    pollingInterval: 2 * 60 * 1000,
    refetchOnMountOrArgChange: true,
  }); //calling every 2 mins

  const [sendPacket, { data: controlData, isSuccess: controlSuccess, error: controlError, isError: isControlError, isLoading: controlLoading }] =
    useDeviceControlMutation();

  const [downloadReport, { data: reportData, error: reportError, isError: isReportError, isSuccess: isReportSuccess, isLoading: reportLoading }] =
    useDownloadDeviceDataMutation();

  const [resetFitlerHealth, { data: resetData, isSuccess: resetSucccess, error: resetError, isError: isResetError, isLoading: resetLoading }] =
    useResetFilterHealthMutation();

  const [powerStatus, setPowerStatus] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [isReportOpen, setIsReportOpen] = useState(false);
  const [isPerformanceReportOpen, setIsPerformanceReportOpen] = useState(false);

  const [filterClick, setFilterClick] = useState(0);
  const [isResetFilterOpen, setIsResetFilterOpen] = useState(false);
  const [confirmReset, setConfirmReset] = useState(false);

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const userRole = useAppSelector((state) => state?.auth?.user?.response?.role);

  const onDownloadReport = () => {
    downloadReport({ body: { ...dateRange[0], deviceId: deviceData.deviceID }, device: id });
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
      console.log(values);

      const bodyObject = {
        device: id,
        body: values,
      };
      sendPacket(bodyObject);
      //   action.resetForm();
    },
  });

  const handlePowerBtn = () => {
    // setPowerStatus((powerStatus) => !powerStatus);
    const bodyObject = {
      device: id,
      body: { powerStatus: !powerStatus },
    };
    sendPacket(bodyObject);
  };

  useEffect(() => {
    console.log(filterClick);

    if (filterClick === 1) resetAfter10sec();
    if (filterClick === 5) {
      setIsResetFilterOpen(true);
      setFilterClick(0);
    }
  }, [filterClick]);

  const resetAfter10sec = () => {
    setTimeout(() => {
      setFilterClick(0);
    }, 5000);
  };

  const handleResetFilter = () => {
    resetFitlerHealth(id);
    setIsResetFilterOpen(false);
    setConfirmReset(false);
  };
  useEffect(() => {
    if (resetSucccess) {
      toast({
        title: "Filter Reset Success!",
        status: "success",
        isClosable: true,
      });
      refetch();
    }
  }, [resetSucccess]);

  useEffect(() => {
    if (deviceSuccess) {
      //     displayStatus: true,
      // displayValue: "01",
      // fanMode: "",
      setFieldValue("displayValue", deviceData.displayValue.toString().padStart(2, "0"));
      setFieldValue("displayStatus", deviceData.displayStatus);
      if (!deviceData.room.preClean) setFieldValue("fanMode", deviceData.fanMode.toString().padStart(2, "0"));
      setPowerStatus(deviceData.powerStatus);
    }
  }, [deviceData, deviceSuccess]);

  useEffect(() => {
    if (controlSuccess) {
      if (controlData == false) {
        toast({
          title: "Error!",
          description: "Error Sending command to device. please try again",
          status: "error",
          isClosable: true,
        });
      } else {
        toast({
          title: "Device Updated!",
          status: "success",
          isClosable: true,
        });
      }
      //call find device here again//
      refetch();
    }
  }, [controlData, controlSuccess]);

  const dropdownItems = [
    { label: "PM1", value: "01", api: "p1", unit: "µg/m³" },
    { label: "PM2.5", value: "02", api: "p25", unit: "µg/m³" },
    { label: "PM10", value: "03", api: "p10", unit: "µg/m³" },
    { label: "Temp", value: "04", api: "temperature", unit: "°C" },
    { label: "Humidity", value: "05", api: "humidity", unit: "%" },
    { label: "CO2", value: "06", api: "co2", unit: "ppb" },
    { label: "VOC", value: "07", api: "voc", unit: "Unit" },
    { label: "Sound", value: "08", api: "sound", unit: "dB" },
  ];

  return (
    <div className="h-screen w-screen fixed left-0 z-20 bg-white">
      <div className={`${controlLoading ? "" : "hidden"} `}>
        <Loading />
      </div>

      {isPerformanceReportOpen && (
        <Modal modalStyle="w-auto" onClose={() => setIsPerformanceReportOpen(false)}>
          <PerformanceReport deviceId={id} />
        </Modal>
      )}

      {isResetFilterOpen && (
        <Modal modalStyle="px-5 pt-3" onClose={() => setIsResetFilterOpen(false)}>
          <h1 className="capitalize font-bold text-2xl">Reset filter health</h1>
          <p className="mt-5 text-left font-semibold">
            If you have replaced the Filter on this device, you can let the app know by resetting the Filter Heath to get accurate filter data.
          </p>
          <div className=" flex gap-3 items-center mt-8">
            {/* increase the size of the checkbox */}
            <input
              onChange={(e) => setConfirmReset(e.target.checked)}
              checked={confirmReset}
              className="w-5 h-5 inline accent-black"
              type="checkbox"
              name="resetConfirm"
              id="resetConfirm"
            />
            <label htmlFor="resetConfirm" className="text-left inline font-bold ">
              I have replaced the Filters on this device
            </label>
          </div>

          <p className="text-left mt-5 font-semibold text-gray-400">
            Performing this action without a filter replacement may result in inaccurate readings and could also shorten the life of the device.
          </p>

          <button
            disabled={!confirmReset}
            onClick={handleResetFilter}
            className="uppercase rounded-lg bg-black disabled:bg-gray-500 disabled:text-white disabled:border text-white mt-8 w-3/4 h-10"
          >
            RESET Filter
          </button>
        </Modal>
      )}

      {isReportOpen && (
        <Modal modalStyle="w-auto" className="" onClose={() => setIsReportOpen(false)}>
          {reportLoading && (
            <div className="h-screen w-screen fixed top-0 left-0 flex justify-center items-center bg-black backdrop-blur-[2px] bg-opacity-80 z-50">
              <Spinner color="white" />
            </div>
          )}
          <h1 className="font-bold text-xl mb-9 ">Download HIVE Report</h1>
          <div className="">
            <DateRangePicker
              onChange={(item) => setDateRange([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={1}
              ranges={dateRange}
              direction="vertical"
              inputRanges={[]}
              maxDate={new Date()}
            />
          </div>
          <button
            onClick={onDownloadReport}
            className="rounded-lg border-[1px] mt-10 h-9 px-5 border-black font-semibold hover:bg-[#4e9bff] hover:text-white hover:border-none"
          >
            Download Now!
          </button>
        </Modal>
      )}

      {deviceData && (
        <div className={`parent w-full flex relative h-[92vh]`}>
          <div className="parent flex">
            <div className={`left   overflow-auto w-2/5 pt-10 relative flex flex-col justify-center items-center`}>
              <div className="absolute top-10 w-full">
                <div className="top w-10/12 flex items-center m-auto justify-center relative ">
                  <div onClick={() => navigate(-1)} className={`power left-0  text-3xl  top-1/2 -translate-y-1/2 absolute`}>
                    <IoIosArrowBack />
                  </div>
                  <div className="">
                    <h1 className="font-bold text-2xl">{deviceData.name}</h1>
                    <p className="text-xs text-slate-500 ">
                      <span className=""> {deviceData.roomObject.name} </span>
                    </p>
                  </div>
                  <div className="networkStrength  right-0 absolute top-1/2 -translate-y-1/2">
                    <NetworkStrength signalStrength={deviceData.networkStrength} signalType={deviceData.networkType} height={3} />
                  </div>
                </div>
              </div>

              <div className="image w-full p-0 m-auto flex flex-col  justify-center items-center  ">
                <img
                  src={
                    !deviceData.powerStatus
                      ? hivefan0
                      : +deviceData.fanMode == 1
                      ? hiveFan1
                      : +deviceData.fanMode == 2
                      ? hiveFan2
                      : +deviceData.fanMode == 3
                      ? hiveFan3
                      : +deviceData.fanMode == 4
                      ? hiveFan4
                      : hivefan0
                  }
                  alt=""
                />
                <div className="filterHealth text-left relative w-3/5 m-auto">
                  <div className="flex justify-between items-center">
                    <h1 className="font-semibold text-xl">Filter Health</h1>
                    {/* insert filterhealth icon here */}
                    <div
                      onClick={() =>
                        setFilterClick((prev) => {
                          console.log("prevval -->", prev);
                          return prev + 1;
                        })
                      }
                      className=""
                    >
                      {deviceData.deviceFilterStatus.hepaPercentage < 33 ? (
                        <Filter className="absolute right-2 top-0 " fill1="stroke-high" fill2="fill-high" fill3="fill-high" />
                      ) : deviceData.deviceFilterStatus.hepaPercentage < 66 ? (
                        <Filter className="absolute right-2 top-0 " fill1="stroke-high" fill2="fill-slate-300" fill3="fill-slate-300" />
                      ) : (
                        <Filter className="absolute right-2 top-0 " fill1="stroke-good" fill2="fill-good" fill3="fill-good" />
                      )}
                    </div>
                  </div>

                  <p className="text-[#747474]  text-xs mt-3">
                    Disclaimer: Filter Health is an estimated value calculated based on Usage hours, mode, average PM concentration
                  </p>
                </div>
                <div className={`powerBtn mt-5 w-3/5 flex flex-col items-center content-center  mb-5  ${powerStatus ? "text-high" : "text-good"}`}>
                  <button
                    onClick={handlePowerBtn}
                    disabled={deviceData.room.preClean}
                    className=" w-full  disabled:border-gray-400 disabled:text-gray-400 text-current border-current border-2 py-2 rounded-xl mt-5 font-semibold text-xl"
                  >
                    Power {powerStatus ? `Off` : `On`}
                  </button>
                  {deviceData.room.preClean && <p className="text-high text-sm">Device is in preclean mode</p>}
                </div>

                {(userRole == "admin" || userRole == "super-admin") && (
                  <div className="btn powerBtn mt-2 w-3/5 flex flex-col items-center content-center  mb-5 ">
                    <button
                      onClick={() => setIsReportOpen(true)}
                      className="w-full  disabled:border-gray-400 disabled:text-gray-400 text-current border-current border-2 py-2 rounded-xl  font-semibold text-xl"
                    >
                      Data Download
                    </button>
                    <button
                      // onClick={() => navigate(`/performanceReport/${id}`)}
                      onClick={() => setIsPerformanceReportOpen(true)}
                      className="w-full  disabled:border-gray-400 disabled:text-gray-400 text-current border-current border-2 py-2 rounded-xl  font-semibold text-xl mt-2"
                    >
                      Performance Report
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className={`right h-full w-4/5 overflow-auto mt-10`}>
              <div className="w-10/12 m-auto flex flex-col justify-between">
                <div className="controls  text-left">
                  <h1 className="font-bold text-xl">Device Controls</h1>

                  <form className=" flex flex-col justify-between h-full" onSubmit={handleSubmit}>
                    <div className="display mt-10 w-full">
                      <div className="toggle flex items-center justify-between">
                        <h1 className="font-semibold text-xl">Device Display</h1>
                        <Switch
                          id="displayStatus"
                          name="displayStatus"
                          size={"lg"}
                          isChecked={values.displayStatus}
                          onChange={(e) => {
                            handleChange(e);
                            setFormChanged(true);
                          }}
                          onClick={() => setFormChanged(true)}
                          colorScheme="blackAlpha"
                          sx={{ ".chakra-switch__track[data-checked]:not([data-theme])": { backgroundColor: "black" } }}
                        />
                      </div>

                      {/* <div className="displaySelect flex justify-end items-center px-5 bg-black h-12 rounded-r-full w-full left-0 mt-5 -translate-x-[12%]"> */}
                      <div className="displaySelect flex justify-center items-center px-5 bg-black h-12 rounded-full w-full left-0 mt-5  ">
                        <div className="item  justify-between w-full text-white flex items-center text-lg ">
                          <IoIosArrowBack
                            className="text-2xl"
                            onClick={() => {
                              if (+values.displayValue > 1) {
                                setFieldValue("displayValue", (+values.displayValue - 1).toString().padStart(2, "0"));
                                setFormChanged(true);
                                console.log(values);
                              } else {
                                setFieldValue("displayValue", "08");
                              }
                            }}
                          />
                          <div className=" w-1/2 flex justify-around ">
                            <p className="text-white">{dropdownItems[dropdownItems.findIndex((item) => item.value == values.displayValue)]?.label}</p>{" "}
                            <p className={`${deviceData.sensorData ? "" : "hidden"}`}>
                              {deviceData.sensorData && (
                                <div className="">
                                  {Math.floor(
                                    deviceData.sensorData[dropdownItems[dropdownItems.findIndex((item) => item.value == values.displayValue)]?.api]
                                  )}
                                  {dropdownItems[dropdownItems.findIndex((item) => item.value == values.displayValue)]?.unit}
                                </div>
                              )}
                            </p>
                            <p className={`${!deviceData.sensorData ? "" : "hidden"}`}>- </p>
                          </div>
                          {/*use formik to control the displayed text and chakra ui for animation*/}
                          <IoIosArrowForward
                            className="text-2xl"
                            onClick={() => {
                              if (+values.displayValue < 8) {
                                setFieldValue("displayValue", (+values.displayValue + 1).toString().padStart(2, "0"));
                                setFormChanged(true);
                                console.log(values);
                              } else {
                                setFieldValue("displayValue", "01");
                              }
                            }}
                          />
                        </div>
                      </div>

                      <div className="CleaningMode mt-10">
                        <h2 className="text-left font-semibold text-xl">Cleaning Mode</h2>
                        {deviceData.room.preClean && <p className="text-sm text-high">Device Is Currently In Pre Clean Mode</p>}
                        <div className="grid mt-2 w-full grid-rows-4 gap-3 rounded-xl  py-2">
                          <div>
                            <input
                              type="radio"
                              id="fanMode-1"
                              name="fanMode"
                              value="01"
                              disabled={deviceData.room.preClean}
                              checked={values.fanMode === "01"}
                              onChange={(e) => {
                                setFormChanged(true);
                                handleChange(e);
                              }}
                              className="peer hidden"
                            />
                            <label
                              htmlFor="fanMode-1"
                              className="block  bg-[#F1F1F1] text-[#AEAEAE] py-1 px-1 shadow-container cursor-pointer select-none rounded-xl  text-center peer-checked:bg-black peer-checked:font-bold peer-checked:text-white"
                            >
                              <div className="flex w-full px-5 py-2  justify-between items-center">
                                <p className="font-bold text-md text-current ">Silent</p>
                                <div className="icon flex items-center ">
                                  <FanSilent className="  fill-current w-6 stroke-current " />
                                  <p className="ml-3">50 dB</p>
                                </div>
                              </div>
                            </label>
                          </div>

                          <div>
                            <input
                              type="radio"
                              id="fanMode-2"
                              name="fanMode"
                              disabled={deviceData.room.preClean}
                              value="02"
                              checked={values.fanMode === "02"}
                              onChange={(e) => {
                                setFormChanged(true);
                                handleChange(e);
                              }}
                              className="peer hidden"
                            />
                            <label
                              htmlFor="fanMode-2"
                              className="block  bg-[#F1F1F1] text-[#AEAEAE] py-1 px-1 shadow-container cursor-pointer select-none rounded-xl  text-center peer-checked:bg-black peer-checked:font-bold peer-checked:text-white"
                            >
                              <div className="flex w-full px-5 py-2  justify-between items-center">
                                <p className="font-bold text-md text-current ">Standard</p>
                                <div className="icon flex items-center  ">
                                  <FanStandard className="  fill-current w-6 stroke-current " />
                                  <p className="ml-3">57 dB</p>
                                </div>
                              </div>
                            </label>
                          </div>

                          <div>
                            <input
                              type="radio"
                              id="fanMode-3"
                              name="fanMode"
                              value="03"
                              disabled={deviceData.room.preClean}
                              checked={values.fanMode === "03"}
                              onChange={(e) => {
                                setFormChanged(true);
                                handleChange(e);
                              }}
                              className="peer hidden"
                            />
                            <label
                              htmlFor="fanMode-3"
                              className="block  bg-[#F1F1F1] text-[#AEAEAE] py-1 px-1 shadow-container cursor-pointer select-none rounded-xl  text-center peer-checked:bg-black peer-checked:font-bold peer-checked:text-white"
                            >
                              <div className="flex w-full px-5 py-2  justify-between items-center">
                                <p className="font-bold text-md text-current ">Turbo</p>
                                <div className="icon flex items-center ">
                                  <FanTurbo className="  fill-current w-6 stroke-current " />
                                  <p className="ml-3">69 dB</p>
                                </div>
                              </div>
                            </label>
                          </div>

                          <div>
                            <input
                              type="radio"
                              id="fanMode-4"
                              name="fanMode"
                              value="04"
                              disabled={deviceData.room.preClean}
                              checked={values.fanMode === "04"}
                              onChange={(e) => {
                                setFormChanged(true);
                                handleChange(e);
                              }}
                              className="peer hidden"
                            />
                            <label
                              htmlFor="fanMode-4"
                              className="block  bg-[#F1F1F1] text-[#AEAEAE] py-1 px-1 shadow-container cursor-pointer select-none rounded-xl  text-center peer-checked:bg-black peer-checked:font-bold peer-checked:text-white"
                            >
                              <div className="flex w-full px-5 py-2  justify-between items-center">
                                <p className="font-bold text-md text-current ">Deep Clean</p>
                                <div className="icon flex items-center ">
                                  <FanDeep className="  fill-current  w-6 stroke-current " />
                                  <p className="ml-3">75 dB</p>
                                </div>
                              </div>
                            </label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="fanMode-5"
                              name="fanMode"
                              value="05"
                              disabled={deviceData.room.preClean}
                              checked={values.fanMode === "05"}
                              onChange={(e) => {
                                setFormChanged(true);
                                handleChange(e);
                              }}
                              className="peer hidden"
                            />
                            <label
                              htmlFor="fanMode-5"
                              className="block  bg-[#F1F1F1] text-[#AEAEAE] py-1 px-1 shadow-container cursor-pointer select-none rounded-xl  text-center peer-checked:bg-black peer-checked:font-bold peer-checked:text-white"
                            >
                              <div className="flex w-full px-5 py-2  justify-between items-center">
                                <p className="font-bold text-md text-current ">Auto Mode</p>
                                <div className="icon flex items-center ">
                                  <FanDeep className="  fill-current  w-6 stroke-current " />
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="btn mt-5">
                        <button
                          type="submit"
                          disabled={!formChanged}
                          className="w-full disabled:bg-white disabled:opacity-40 disabled:text-black disabled:border-2 disabled:border-black bg-black text-white py-2 rounded-xl mt-5 font-semibold text-xl"
                        >
                          Update
                        </button>
                        <p className="text-xs mt-2 text-slate-400">
                          Please click on the Update button for the changes to get reflected on the device
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default DeviceInfoDesktop;
