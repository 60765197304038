import { useNavigate } from "react-router-dom";
import { useDevicePerformanceReportMutation } from "../../store/services/deviceApi";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import xlsx from "json-as-xlsx";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";

const PerformanceReport = ({ deviceId }) => {
  const navigate = useNavigate();
  // const { deviceId } = useParams();
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  console.log("Deviceid --> ", deviceId);

  const [
    getPerformanceReport,
    { data: performanceData, error: performanceError, isError: isperformanceError, isSuccess: isperformanceSuccess, isLoading: performanceLoading },
  ] = useDevicePerformanceReportMutation();

  useEffect(() => {
    if (isperformanceSuccess) {
      let excelData = [
        {
          sheet: "Overall Report",
          columns: [
            { label: "User Email", value: (row) => row?.deviceData?.userEmail },
            { label: "Device ID", value: (row) => row?.deviceData?.deviceNo },
            { label: "Pre Clean Used", value: (row) => row?.preCleanUsedCount + " times" },
            // { label: "Fan Speed", value: (row) => row?.preCleanUsedCount?.fanMode },
            // { label: "Time In Fan Speed", value: (row) => row?.preCleanUsedCount?.totalDurationInMinutes },
            // { label: "Event End Date", value: "event_stopped_at" },
            // { label: "Drop Percent", value: "pmDropPercent" },
            // { label: "Power Status", value: "devicePower" },
            // { label: "Room Name", value: "roomName" },
            // { label: "Room Area", value: "roomArea" },
            // { label: "Room Height", value: "roomHeight" },
          ],
          content: performanceData.overall,
        },
        {
          sheet: "Fan Speed Usage Report",
          columns: [
            { label: "Date", value: (row) => row?.date },
            { label: "Off", value: (row) => row?.fanData?.find((x) => x.fanMode == "00")?.duration },
            { label: "Silent", value: (row) => row?.fanData?.find((x) => x.fanMode == "01")?.duration },
            { label: "Standard", value: (row) => row?.fanData?.find((x) => x.fanMode == "02")?.duration },
            { label: "Turbo", value: (row) => row?.fanData?.find((x) => x.fanMode == "03")?.duration },
            { label: "Deep Clean", value: (row) => row?.fanData?.find((x) => x.fanMode == "04")?.duration },
            { label: "Auto Mode", value: (row) => row?.fanData?.find((x) => x.fanMode == "05")?.duration },
            // { label: "Time In Fan Speed (Minutes)", value: (row) => row?.totalDurationInMinutes + " Minutes" },
          ],
          content: performanceData.deviceWorkingCount,
        },
        {
          sheet: "Pre Clean Report",
          columns: [
            // { label: "User Email", value: "userEmail" },
            // { label: "Device ID", value: "deviceNo" },
            { label: "Event Start Date", value: "converted_start_date" },
            { label: "Event End Date", value: "converted_end_date" },
            { label: "Fan Mode", value: "fanMode" },
            { label: "Pre Clean Time", value: (row) => row.preCleanTime + " Minutes" },
            { label: "Initial PM", value: (row) => row?.sensorData[0]?.initial?.p25 },
            { label: "Final PM", value: (row) => row?.sensorData[0]?.final?.p25 },
            { label: "Power Status", value: "devicePower" },
            { label: "Room Name", value: "roomName" },

            // { label: "Room Area", value: "roomArea" },
            // { label: "Room Height", value: "roomHeight" },
          ],
          content: performanceData.devicePreCleanHistory,
        },
        {
          sheet: "CADR Report",
          columns: [
            // { label: "User Email", value: "userEmail" },
            // { label: "Device ID", value: "deviceNo" },
            { label: "Event Start Date", value: "event_started_at" },
            { label: "Event End Date", value: "event_stopped_at" },
            { label: "Fan Mode", value: "fanMode" },
            { label: "CADR", value: "cadr" },
            { label: "Drop Percent", value: "pmDropPercent" },
            { label: "Initial PM", value: (row) => row?.sensorData[0]?.initial?.p25 },
            { label: "Final PM", value: (row) => row?.sensorData[0]?.final?.p25 },
            { label: "Power Status", value: "devicePower" },
            { label: "Room Name", value: "roomName" },
            { label: "Room Area", value: "roomArea" },
            { label: "Room Height", value: "roomHeight" },
          ],
          content: performanceData.fanData,
        },
      ];
      let settings = {
        fileName: "HIVE Performance Report", // Name of the resulting spreadsheet
        extraLength: 5, // A bigger number means that columns will be wider
        writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
        writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
        RTL: false, // Display the columns from right-to-left (the default value is false)
      };
      xlsx(excelData, settings);
    }
  }, [performanceData, isperformanceSuccess]);

  const handleSubmit = () => {
    let bodyObject = {
      body: dateRange[0],
      params: {
        deviceId: deviceId,
      },
    };
    getPerformanceReport(bodyObject);
  };
  return (
    <div className="">
      <h1 className="font-bold text-xl mb-9 ">Download HIVE Performance Report</h1>

      <div className="">
        <DateRangePicker
          onChange={(item) => setDateRange([item.selection])}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={1}
          ranges={dateRange}
          direction="vertical"
          inputRanges={[]}
          maxDate={new Date()}
        />
      </div>
      <button
        className="rounded-lg border-[1px] mt-8 h-9 px-5 border-black font-semibold hover:bg-[#4e9bff] hover:text-white hover:border-none"
        onClick={handleSubmit}
      >
        Submit
      </button>
    </div>
  );
};
export default PerformanceReport;
