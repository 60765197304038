import Modal from "../common/Modal";
import { Switch } from "@chakra-ui/react";
import { Menu, MenuButton, MenuList, MenuItem, MenuItemOption, MenuGroup, MenuOptionGroup, MenuDivider } from "@chakra-ui/react";
import { Select } from "@chakra-ui/react"; // Import the Select component

import { IoIosArrowForward } from "react-icons/io";
import { FaFan } from "react-icons/fa";

import { useFindDeviceByIdQuery, useDeviceControlMutation } from "../../store/services/deviceApi";
import { useParams } from "react-router-dom";

import { useFormik } from "formik";
import { useEffect } from "react";

import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import CustomDropdown from "../common/CustomDropdown";

const DeviceControl = () => {
  const { deviceId } = useParams();
  const toast = useToast();
  const navigate = useNavigate(0);

  let initialValues = {
    powerStatus: true,
    displayStatus: false,
    displayValue: "01",
    fanMode: "01",
  };

  const {
    data: deviceData,
    isSuccess: deviceSuccess,
    error: deviceError,
    isError: isDeviceError,
    isLoading: isLoading,
  } = useFindDeviceByIdQuery(deviceId, {
    refetchOnMountOrArgChange: true,
  });

  const [sendPacket, { data: controlData, isSuccess: controlSuccess, error: controlError, isError: isControlError }] = useDeviceControlMutation();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
      console.log(values);
      const bodyObject = {
        device: deviceId,
        body: values,
      };
      sendPacket(bodyObject);
      //   action.resetForm();
    },
  });

  useEffect(() => {
    if (deviceSuccess) {
      setFieldValue("powerStatus", deviceData.powerStatus);
      setFieldValue("displayStatus", deviceData.displayStatus);
      setFieldValue("displayValue", deviceData.displayValue);
      setFieldValue("fanMode", deviceData.fanMode);
    }
  }, [deviceData, deviceSuccess]);

  useEffect(() => {
    if (controlSuccess) {
      toast({
        title: "Command Sent To Device",
        status: "success",
        isClosable: true,
      });
      navigate(`/dashboard/devices/${deviceId}`);
    }
  }, [controlSuccess, controlData]);

  const dropdownItems = [
    { label: "PM1", value: "01" },
    { label: "PM2.5", value: "02" },
    { label: "PM10", value: "03" },
    { label: "Temperature", value: "04" },
    { label: "Humidity", value: "05" },
    { label: "CO2", value: "06" },
    { label: "VOC", value: "07" },
    { label: "Sound", value: "08" },
  ];

  return (
    <Modal isLoading={isLoading} className="">
      <form onSubmit={handleSubmit}>
        <div className="container ">
          <h1 className="font-bold text-xl">Device Settings</h1>
          <div className="inputGrp mt-5 flex justify-between ">
            <h2 className="font-semibold text-lg uppercase">Power</h2>
            <Switch id="powerStatus" name="powerStatus" isChecked={values.powerStatus} onChange={handleChange} colorScheme="green" />
          </div>
          <div className="aod">
            <div className="inputGrp mt-5 flex justify-between ">
              <h2 className="font-semibold text-lg capitalize">Always On Display</h2>
              <Switch id="displayStatus" name="displayStatus" isChecked={values.displayStatus} onChange={handleChange} colorScheme="green" />
            </div>
            {/* create a dropdown here containing dynamic labels and values taken from an object */}
            <div className="flex">
              <CustomDropdown
                selectedValue={values.displayValue}
                options={dropdownItems}
                onChange={(value) => handleChange({ target: { name: "displayValue", value } })}
              />
            </div>
          </div>

          <div className="fanSpeed mt-5">
            <div className="head flex items-center ">
              <FaFan /> <h2 className="ml-2 font-semibold text-lg capitalize">Fan Speed</h2>
            </div>
            <div className="radio">
              {/*  */}
              <div className="grid mt-2 w-full grid-cols-4 gap-2 rounded-xl bg-gray-200 p-2">
                <div>
                  <input
                    type="radio"
                    id="fanMode-1"
                    name="fanMode"
                    value="01"
                    checked={values.fanMode === "01"}
                    onChange={handleChange}
                    className="peer hidden"
                  />
                  <label
                    htmlFor="fanMode-1"
                    className="block cursor-pointer select-none rounded-xl px-2 py-1.5 text-center peer-checked:bg-black peer-checked:font-bold peer-checked:text-white"
                  >
                    1
                  </label>
                </div>

                <div>
                  <input
                    type="radio"
                    id="fanMode-2"
                    name="fanMode"
                    value="02"
                    checked={values.fanMode === "02"}
                    onChange={handleChange}
                    className="peer hidden"
                  />
                  <label
                    htmlFor="fanMode-2"
                    className="block cursor-pointer select-none rounded-xl px-2 py-1.5 text-center peer-checked:bg-black peer-checked:font-bold peer-checked:text-white"
                  >
                    2
                  </label>
                </div>

                <div>
                  <input
                    type="radio"
                    id="fanMode-3"
                    name="fanMode"
                    value="03"
                    checked={values.fanMode === "03"}
                    onChange={handleChange}
                    className="peer hidden"
                  />
                  <label
                    htmlFor="fanMode-3"
                    className="block cursor-pointer select-none rounded-xl px-2 py-1.5 text-center peer-checked:bg-black peer-checked:font-bold peer-checked:text-white"
                  >
                    3
                  </label>
                </div>

                <div>
                  <input
                    type="radio"
                    id="fanMode-4"
                    name="fanMode"
                    value="04"
                    checked={values.fanMode === "04"}
                    onChange={handleChange}
                    className="peer hidden"
                  />
                  <label
                    htmlFor="fanMode-4"
                    className="block cursor-pointer select-none rounded-xl px-2 py-1.5 text-center peer-checked:bg-black peer-checked:font-bold peer-checked:text-white"
                  >
                    4
                  </label>
                </div>
              </div>
              {/*  */}
            </div>
            <button type="submit" className="bg-black text-white w-3/5 h-8 mt-6 rounded-md">
              Update
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default DeviceControl;
