import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useCreateRoomMutation } from "../../store/services/roomsApi";
import { useEffect, useState } from "react";

import { useToast } from "@chakra-ui/react";

import { AiOutlineClose } from "react-icons/ai";

import Modal from "../common/Modal";
import UnClosableModal from "../common/UnClosableModal";

import { roomTypes } from "../../Helper/helper";
import CustomDropdown from "../common/CustomDropdown";

const initialValues = {
  name: "",
  roomCategory: "",
  roomType: "",
  area: "",
  height: "",
};

const roomsSchema = Yup.object().shape({
  name: Yup.string().min(2, "Name is too short!").max(50, "Name is too long!").required("Name is required"),
  roomCategory: Yup.string().required("Room Category Is Required"),
  roomType: Yup.string().min(1).required("Type Of Room Is Required"),
  area: Yup.number().required("Area Is Required"),
  height: Yup.number().required("Height Is Required"),
});

const AddRoom = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [createRoom, { data: roomData, error: roomError, isError: isRoomError, isSuccess: isRoomSuccess }] = useCreateRoomMutation();
  const [showModal, setShowModal] = useState(false);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: initialValues,
    validationSchema: roomsSchema,
    onSubmit: (values, action) => {
      console.log(values);
      createRoom(values);
      action.resetForm();
    },
  });

  useEffect(() => {
    if (isRoomSuccess) {
      toast({
        title: "Room Added Successfully",
        status: "success",
        isClosable: true,
      });
      setShowModal(true);
      // navigate(`/dashboard/rooms/${roomData._id}`);
    }
  }, [isRoomSuccess, roomError]);

  useEffect(() => {
    if (isRoomError) {
      console.log(roomError);
      toast({
        title: `${roomError.message ? roomError.message : "Something went wrong. Please Try Again"}`,
        status: "error",
        isClosable: true,
      });
    }
  }, [isRoomError, roomError]);

  return (
    <>
      {showModal && (
        <UnClosableModal>
          <h1 className="font-bold text-xl w-10/12 m-auto">Do You Want to add more room?</h1>

          <div className="btns grid grid-cols-2 mt-5 gap-2">
            <button onClick={() => navigate(`/dashboard/rooms/`)} className="w-full bg-[#EFEFEF] text-black font-semibold rounded-lg h-9">
              No
            </button>
            <button onClick={() => setShowModal(false)} className="w-full bg-[#323232] text-white rounded-lg font-semibold h-9">
              Yes
            </button>
          </div>
        </UnClosableModal>
      )}
      {!showModal && (
        <Modal>
          <h1 className="font-bold text-2xl">Add Room</h1>

          <form onSubmit={handleSubmit} className="mt-5" action="">
            <div className="inpGrp text-left relative">
              <label className=" font-semibold">Name Of The Room</label>
              <input
                name="name"
                id="name"
                value={values.name}
                onChange={handleChange}
                type="text"
                placeholder=""
                className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md w-full"
              />
              <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.name && touched.name ? errors.name : ""}</span>
            </div>
            {/* room category and type */}
            <div className="inpGrp text-left mt-4  relative">
              <label className=" font-semibold">Room Category</label>
              <CustomDropdown
                options={roomTypes.map((categories) => {
                  return { label: categories.category, value: categories.category };
                })}
                selectedValue={values.roomCategory}
                onChange={(val) => {
                  // console.log(val);
                  setFieldValue("roomType", "");
                  setFieldValue("roomCategory", val);
                }}
              />
              <span className="text-red-500 text-xs absolute -bottom-4 left-0">
                {errors.roomCategory && touched.roomCategory ? errors.roomCategory : ""}
              </span>
            </div>
            {values.roomCategory && (
              <div className="inpGrp text-left mt-4  relative">
                <label className=" font-semibold">Room Type</label>
                {values.roomCategory !== "Others" ? (
                  <CustomDropdown
                    options={roomTypes
                      .find((cat) => cat.category == values.roomCategory)
                      .type.map((types) => {
                        return { label: types, value: types };
                      })}
                    selectedValue={values.roomType}
                    onChange={(val) => {
                      // console.log(val);
                      setFieldValue("roomType", val);
                    }}
                  />
                ) : (
                  <input
                    name="roomType"
                    id="roomType"
                    value={values.roomType}
                    onChange={handleChange}
                    type="String"
                    placeholder=""
                    className="bg-[#F5F5F5] font-semibold focus:ring-0 focus:outline-black px-2 py-2 rounded-md w-full relative"
                  />
                )}
                <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.roomType && touched.roomType ? errors.roomType : ""}</span>
              </div>
            )}
            {console.log(JSON.stringify(errors))}
            {/* room category and type */}
            <div className="inpGrp text-left mt-4 relative">
              <label className=" font-semibold">Area</label>
              <input
                name="area"
                id="area"
                value={values.area}
                onChange={handleChange}
                type="Number"
                placeholder=""
                className="bg-[#F5F5F5] font-semibold focus:ring-0 focus:outline-black px-2 py-2 rounded-md w-full relative"
              />
              <div className="span text-xs font-bold bottom-2 absolute right-3 text-[#9D9D9D]">Sq. Ft.</div>
              <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.area && touched.area ? errors.area : ""}</span>
            </div>
            <div className="inpGrp text-left mt-4 relative">
              <label className=" font-semibold">Height</label>
              <input
                name="height"
                id="height"
                value={values.height}
                onChange={handleChange}
                type="Number"
                placeholder=""
                className="bg-[#F5F5F5] font-semibold focus:ring-0 focus:outline-black px-2 py-2 rounded-md w-full relative"
              />
              <div className="span text-xs font-bold bottom-2 absolute right-3 text-[#9D9D9D]">In. Ft.</div>
              <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.height && touched.height ? errors.height : ""}</span>
            </div>

            {values.height && values.area ? (
              <span className="text-xs font-semibold mt-3 block">
                Recommended Number Of HIVEs for this room :{Math.round((5 * values.height * values.area) / (230 * 60)) || 1}
              </span>
            ) : (
              ""
            )}

            <button type="submit" className="bg-[#323232] text-white w-3/5 h-8 mt-6 rounded-md">
              Add Room
            </button>
          </form>
        </Modal>
      )}
    </>
  );
};

export default AddRoom;
