//React/Next/ThirdPart/Custom
import { useEffect } from "react";

import { useFormik } from "formik";
import { useValidateResetTokenMutation, useResetPasswordMutation } from "../../store/services/authApi";

import { useAppDispatch } from "../../store/hooks";
import { setUser } from "../../store/features/authSlice";

import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import praanLogo from "../../assets/images/praan.svg";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";

const initialValues = {
  password: "",
  confirmPassword: "",
};

const resetSchema = Yup.object().shape({
  password: Yup.string().min(8, "Password must be at least 8 characters").required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

const ResetPassword = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email");

  //importing api mutation to call api and access data
  const [validateToken, { data: validateData, error: ValidateError, isError: isValidateError, isSuccess: isValidateSuccess }] =
    useValidateResetTokenMutation();
  const [resetPassword, { data: resetData, error: ResetError, isError: isResetError, isSuccess: isResetSuccess }] = useResetPasswordMutation();

  const toast = useToast();
  const navigate = useNavigate();

  //using formik to manage form
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: resetSchema,
    onSubmit: (values, action) => {
      resetPassword({ token, body: { email, password: values.password, token } });
      action.resetForm();
    },
  });

  useEffect(() => {
    // validate token here
    console.log("token", token, email);
    validateToken({ token, body: { email, token } });
  }, []);

  //displaying toast on successfull login and saving the userdata along with the token
  useEffect(() => {
    if (isResetSuccess) {
      toast({
        title: resetData,
        status: "success",
        isClosable: true,
      });
      navigate("/login");
    }
  }, [isResetSuccess, ResetError]);

  //displaying a toast if an error occurs
  useEffect(() => {
    if (isValidateError) {
      console.log(ValidateError);
      toast({
        title: `Reset Password Token Not Valid`,
        status: "error",
        isClosable: true,
      });
      navigate("/login");
    }
  }, [ValidateError, isValidateError]);

  useEffect(() => {
    if (isResetError) {
      console.log(ResetError);
      toast({
        title: `${ResetError}`,
        status: "error",
        isClosable: true,
      });
    }
  }, [ResetError, isResetError]);

  return (
    <main className="h-full  flex p-10">
      <section className="flex-1 flex items-center justify-center text-black ">
        <div className="w-full">
          <p className="w-full flex justify-center ">
            <img src={praanLogo} alt="praan logo" className="w-6/12  max-w-[150px] align-middle " />
          </p>
          <form onSubmit={handleSubmit} className="flex flex-col max-w-[600px] m-auto text-black ">
            <h1 className="font-medium text-[#323232] text-2xl text-center">Reset Password?</h1>
            <br />

            <br />

            <div className="flex items-center w-full bg-[#EDEDED] my-2 px-4 py-2 rounded-md ">
              <input
                className="bg-[#EDEDED] w-full focus:outline-none text-slate-700"
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {errors.password && touched.password ? <p className="text-red-600 text-left">{errors.password}</p> : null}

            <div className="flex items-center w-full bg-[#EDEDED] my-2 px-4 py-2 rounded-md ">
              <input
                className="bg-[#EDEDED] w-full focus:outline-none text-slate-700"
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                placeholder="Confirm Password"
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {errors.confirmPassword && touched.confirmPassword ? <p className="text-red-600 text-left">{errors.confirmPassword}</p> : null}

            <div onClick={() => navigate("/login")} className="forget cursor-pointer underline text-[#41464E] font-light underline-offset-2">
              Cancel
            </div>
            <div className="flex justify-center mt-8">
              <button
                type="submit"
                className="text-white w-4/5 bg-[#323232] hover:bg-blue-800 focus:ring-4 focus:outline-none h-8 font-medium rounded-lg text-sm text-center mr-3 md:mr-0"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </section>
    </main>
  );
};

export default ResetPassword;
