import UnClosableModal from "./UnClosableModal";
import { Spinner } from "@chakra-ui/react";

const Loading = () => {
  return (
    <UnClosableModal>
      <Spinner />
      <h1 className="font-bold text-2xl mt-2">In Progress</h1>
      <p className="text-high text-sm mt-2">Please Do Not Refresh The Page</p>
    </UnClosableModal>
  );
};
export default Loading;
