//React/Next/ThirdPart/Custom
import { useEffect, useState } from "react";

import { useFormik } from "formik";
import { useSignupMutation } from "../../store/services/authApi";
import { useAppDispatch } from "../../store/hooks";
import { setUser } from "../../store/features/authSlice";

import { border, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import * as Yup from "yup";

import praanLogo from "../../assets/images/praan.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "./signup.css";
import { isPossiblePhoneNumber, isValidPhoneNumber, validatePhoneNumberLength } from "libphonenumber-js";

const SignupSchema = Yup.object().shape({
  name: Yup.string().min(2, "Name is too short!").max(50, "Name is too long!").required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().min(8, "Password must be at least 8 characters").required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

const initialValues = {
  name: "",
  email: "",
  password: "",
  confirmPassword: "",
  phoneNumber: "",
  phoneObject: "",
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};
const SignupPage = () => {
  //importing api mutation to call api and access data
  const [signup, { data: signupData, error: signupError, isError: isSignupError, isSuccess: isSignupSuccess }] = useSignupMutation();
  const [rendered, setRendered] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const dispatch = useAppDispatch();

  const toast = useToast();
  const navigate = useNavigate();

  //using formik to manage form
  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, setFieldTouched, setFieldError } = useFormik({
    initialValues: initialValues,
    validationSchema: SignupSchema,
    onSubmit: (values, action) => {
      signup(values);
      action.resetForm();
    },
  });

  const checkValidPhone = (inputNumber, country) => {
    console.log(
      inputNumber.slice(country.dialCode.length),
      country,
      isValidPhoneNumber(inputNumber.slice(country.dialCode.length), country.countryCode.toUpperCase())
    );

    if (isValidPhoneNumber(inputNumber.slice(country.dialCode.length), country.countryCode.toUpperCase())) {
      setPhoneError(null);
    } else {
      setPhoneError("Please Enter a valid phone number");
    }
  };

  //displaying toast on successfull login and saving the userdata along with the token
  useEffect(() => {
    if (isSignupSuccess) {
      toast({
        title: "Success",
        description: "Please Validate your account before setting up rooms and devices in your account.",
        status: "success",
        isClosable: true,
      });
      dispatch(setUser({ data: signupData }));
      navigate("/dashboard");
    }
  }, [isSignupSuccess, signupError]);

  //displaying a toast if an error occurs
  useEffect(() => {
    if (isSignupError) {
      console.log(signupError);
      toast({
        title: `${signupError.message ? signupError.message : "Something went wrong, Please try again."}`,
        status: "error",
        isClosable: true,
      });
    }
  }, [isSignupError, signupError]);

  const classes = {
    borderClass: {
      "&.react-tel-input .form-control:focus .": {
        borderColor: "transparent",
        outline: "none",
      },
    },
  };

  return (
    <main className="h-full  flex p-10">
      <section className="flex-1 flex items-center justify-center text-black ">
        <div className="w-full max-w-[30rem]">
          <p className="w-full flex justify-center ">
            <img src={praanLogo} alt="praan logo" className="max-w-[10rem] w-6/12 align-middle " />
          </p>
          <form onSubmit={handleSubmit} className="flex flex-col text-black ">
            <h1 className="font-medium text-[#323232] text-2xl text-center ">Create Account</h1>
            <br />
            <div className="flex items-center w-full bg-[#EDEDED] my-2 px-4 py-2 rounded-md ">
              <input
                className="bg-[#EDEDED] w-full focus:outline-none text-slate-700"
                type="name"
                name="name"
                id="name"
                placeholder="Full Name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {errors.name && touched.name ? <p className="text-red-600 text-left">{errors.name}</p> : null}

            <div className="flex items-center w-full bg-[#EDEDED] my-2 px-4 py-2 rounded-md focus:ring-0  ">
              <input
                className="bg-[#EDEDED] w-full focus:outline-none focus:ring-0 text-slate-700"
                type="email"
                name="email"
                id="email"
                placeholder="abc@company.com"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {errors.email && touched.email ? <p className="text-red-600 text-left">{errors.email}</p> : null}

            <div className="flex items-center focus:ring-0   bg-[#EDEDED] my-2  rounded-md ">
              <PhoneInput
                autoFormat
                enableSearch
                disableSearchIcon
                className="focus:outline-none focus:ring-0"
                country={"in"}
                value={values.phoneNumber}
                // buttonStyle={{ "background-color": "black" }}
                containerClass={classes.borderClass}
                inputClass={classes.borderClass}
                inputStyle={{
                  backgroundColor: "#ededed",
                  border: "none",
                  height: "unset",
                  boxShadow: "none",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  borderRadius: "6px",
                }}
                name="phoneNumber"
                id="phoneNumber"
                onBlur={() => setFieldTouched("phoneNumber", true)}
                onClick={() => setFieldTouched("phoneNumber", true)}
                dropdownStyle={{ background: "#ededed" }}
                searchStyle={{ background: "#ededed", width: "90%", margin: "auto" }}
                containerStyle={{ background: "#ededed", width: "100%", margin: "none", padding: "none", borderRadius: "6px" }}
                onChange={(phone, phoneObject) => {
                  setFieldValue("phoneNumber", phone);
                  setFieldValue("phoneObject", phoneObject);
                  checkValidPhone(phone, phoneObject);
                }}
              />
            </div>
            {phoneError ? <p className="text-red-600 text-left">{phoneError}</p> : null}

            <div className="flex items-center w-full bg-[#EDEDED] my-2 px-4 py-2 rounded-md ">
              <input
                className="bg-[#EDEDED] w-full focus:outline-none text-slate-700"
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {errors.password && touched.password ? <p className="text-red-600 text-left">{errors.password}</p> : null}

            <div className="flex items-center w-full bg-[#EDEDED] my-2 px-4 py-2 rounded-md ">
              <input
                className="bg-[#EDEDED] w-full focus:outline-none text-slate-700"
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                placeholder="Confirm Password"
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {errors.confirmPassword && touched.confirmPassword ? <p className="text-red-600 text-left">{errors.confirmPassword}</p> : null}
            <br />
            <div
              onClick={() => {
                navigate("/login");
              }}
              className="forget cursor-pointer underline text-[#41464E] font-light underline-offset-2"
            >
              Already have an account? Sign In
            </div>
            <div className="flex justify-center mt-8">
              <button
                type="submit"
                className="text-white bg-[#323232] hover:bg-blue-800 focus:ring-4 focus:outline-none w-4/5 h-10 font-medium rounded-lg text-sm text-center mr-3 md:mr-0"
              >
                Create Account
              </button>
            </div>
          </form>
        </div>
      </section>
    </main>
  );
};

export default SignupPage;
