import React, { PureComponent } from "react";
import { ComposedChart, LineChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";

const AreaGraph = ({ graphData, dataKeyObject = { value: "p1", label: "PM 1" } }) => {
  const data = [
    {
      p1: 200,
      p25: 36,
      p10: 66,
      co2: 600,
      temperature: 35,
      humidity: 84,
      voc: 101,
      sound: 47,
      deviceId: "653ba74ec253207951cc7cd9",
      date: "2023-10-27",
      hour: 5,
    },
    {
      p1: 36,
      p25: 36,
      p10: 66,
      co2: 600,
      temperature: 35,
      humidity: 84,
      voc: 101,
      sound: 47,
      deviceId: "653ba74ec253207951cc7cd9",
      date: "2023-10-27",
      hour: 13,
    },
    {
      p1: 26,
      p25: 36,
      p10: 66,
      co2: 600,
      temperature: 35,
      humidity: 84,
      voc: 101,
      sound: 47,
      deviceId: "653ba74ec253207951cc7cd9",
      date: "2023-10-27",
      hour: 14,
    },

    {
      p1: 26,
      p25: 36,
      p10: 66,
      co2: 600,
      temperature: 35,
      humidity: 84,
      voc: 101,
      sound: 47,
      deviceId: "653ba74ec253207951cc7cd9",
      date: "2023-10-27",
      hour: 18,
    },
    {
      p1: 26,
      p25: 36,
      p10: 66,
      co2: 600,
      temperature: 35,
      humidity: 84,
      voc: 101,
      sound: 47,
      deviceId: "653ba74ec253207951cc7cd9",
      date: "2023-10-27",
      hour: 21,
    },
  ];

  const xAxisTicks = [0, 3, 6, 9, 12, 15, 18, 21, 24];
  const xAxisTicksMinutes = Array.from({ length: 1440 }, (_, i) => i);
  console.log(xAxisTicksMinutes);
  //   const xAxisTicks = [0, 6, 12, 18, 24];

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const hour = payload[0].payload.hour % 12 || 12; // Convert to 12-hour format
      const period = payload[0].payload.hour < 12 || payload[0].payload.hour > 23 ? "AM" : "PM";

      return (
        <div className="p-2 bg-white border border-gray-300 rounded">
          {/* check if hour is in decimals show hour and minutes */}
          <p></p>

          {Number.isInteger(hour) ? (
            <p className="label">{`Time: ${hour} ${period}`}</p>
          ) : (
            <p className="label">{`Time: ${hour.toString().split(".")[0]}:${payload[0].payload.minute || ""} ${period}`}</p>
          )}
          <p className="label">{`${dataKeyObject.value}: ${Math.round(payload[0].payload[`${dataKeyObject.value}`])}`}</p>
          {/* <p className="label">{`P25: ${payload[0].payload.p25}`}</p>
          <p className="label">{`P10: ${payload[0].payload.p10}`}</p> */}
        </div>
      );
    }
    return null;
  };

  const calculateGradientColor = (value) => {
    // Define your color breakpoints and corresponding values
    const breakpoints = [30, 60]; // Adjust the breakpoints as needed
    const colors = ["red", "orange", "green"]; // Red, Orange, Green

    // Find the corresponding color based on the value
    let color = colors[0]; // Default to the first color
    for (let i = 0; i < breakpoints.length; i++) {
      if (value >= breakpoints[i]) {
        color = colors[i + 1];
      }
    }

    return `url(#${color}Gradient)`;
  };

  return (
    <div className=" relative w-full h-64 sm:h-[56vh] m-auto">
      <ResponsiveContainer className={"absolute left-0 bottom-[39px] sm:bottom-[36px]"} width="100%" height="1%">
        <ComposedChart
          data={graphData?.fanData}
          margin={{
            top: 10,
            right: 20,
            left: 20,
            bottom: 12,
          }}
        >
          <XAxis
            dataKey="hour"
            type="number"
            stroke="#B8BEC8CC"
            fontSize={10}
            domain={[0, 24]} // Assuming hours range from 0 to 23
            interval={0}
            visibility={"hidden"}
            ticks={xAxisTicksMinutes}
            // tickCount={23}
            tickFormatter={(value) => {
              const hour = value % 12 || 12; // Convert to 12-hour format
              const period = value < 12 || value >= 23 ? "AM" : "PM";
              return `${hour} ${period}`;
            }}
          />
          {/* <YAxis /> */}
          <Tooltip />
          <Area
            type="step"
            dataKey={"fanMode"}
            stroke="#73C9F900"
            strokeOpacity={0.91}
            fill="#86b845" // Add the gradient color fill
          />

          {/* <CartesianGrid strokeOpacity={0.4} stroke="#B8BEC8CC" strokeDasharray="5 6" /> */}
        </ComposedChart>
      </ResponsiveContainer>
      <ResponsiveContainer className={"absolute left-0 top-0"} width="100%" height="100%">
        <ComposedChart
          data={graphData?.data}
          margin={{
            top: 10,
            right: 20,
            left: 20,
            bottom: 12,
          }}
        >
          <XAxis
            dataKey="hour"
            type="number"
            stroke="#B8BEC8CC"
            fontSize={10}
            domain={[0, 24]} // Assuming hours range from 0 to 23
            interval={0}
            ticks={xAxisTicks}
            tickMargin={8}
            // tickCount={23}
            tickFormatter={(value) => {
              const hour = value % 12 || 12; // Convert to 12-hour format
              const period = value < 12 || value >= 23 ? "AM" : "PM";
              return `${hour} ${period}`;
            }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Area
            type="monotone"
            dataKey={dataKeyObject.value}
            stroke="#73C9F900"
            strokeOpacity={0.91}
            fill="url(#colorGradient)" // Add the gradient color fill
          />
          <defs>
            <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1.5">
              <stop offset="10%" stopColor="#0B59B5" stopOpacity={0.4} />
              <stop offset="100%" stopColor="#73C9F900" stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeOpacity={0.4} stroke="#B8BEC8CC" strokeDasharray="5 6" />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AreaGraph;
