import Modal from "../common/Modal";
import { useNavigate, useParams } from "react-router-dom";
import { useFindRoomByIDQuery, useEditRoomMutation, useRemoveRoomMutation } from "../../store/services/roomsApi";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useToast } from "@chakra-ui/react";
import UnClosableModal from "../common/UnClosableModal";
import { roomTypes } from "../../Helper/helper";
import CustomDropdown from "../common/CustomDropdown";

const roomsSchema = Yup.object().shape({
  name: Yup.string().min(2, "Name is too short!").max(50, "Name is too long!").required("Name is required"),
  roomCategory: Yup.string().required("Room Category Is Required"),
  roomType: Yup.string().min(1).required("Type Of Room Is Required"),
  area: Yup.number().required("Area Is Required"),
  height: Yup.number().required("Height Is Required"),
});

const EditRooms = () => {
  const { roomId } = useParams();
  const toast = useToast();
  const navigate = useNavigate();

  let initialValues = {
    name: "",
    roomCategory: "",
    roomType: "",
    area: 0,
    height: 0,
  };
  const [showModal, setShowModal] = useState(false);

  const {
    data: roomData,
    isSuccess: roomSuccess,
    error: roomError,
    isError: isRoomError,
  } = useFindRoomByIDQuery(roomId, { refetchOnMountOrArgChange: true });

  const [editRoom, { data: editData, isSuccess: editSuccess, error: editError, isError: isEditError, isLoading: isLoading }] = useEditRoomMutation();

  const [removeRoom, { data: unlinkData, isSuccess: isUnlinkSuccess, error: unlinkError, isError: isUnlinkError }] = useRemoveRoomMutation();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: initialValues,
    validationSchema: roomsSchema,
    onSubmit: (values, action) => {
      console.log(values);
      const bodyObject = {
        room: roomId,
        body: values,
      };
      editRoom(bodyObject);
      //   action.resetForm();
    },
  });

  const handleUnlinkClick = () => {
    removeRoom(roomId);
    setShowModal(false);
  };

  useEffect(() => {
    if (isUnlinkSuccess) {
      toast({
        title: "Room Removed Successfully",
        status: "success",
        isClosable: true,
      });
      navigate("/settings");
    }
  }, [unlinkData, isUnlinkSuccess]);

  useEffect(() => {
    if (editSuccess) {
      toast({
        title: "Device Edited Successfully",
        status: "success",
        isClosable: true,
      });
      navigate(`/dashboard/rooms/info/${roomData._id}`);
    }
  }, [editData, editSuccess]);

  useEffect(() => {
    if (isEditError) {
      console.log(editError);
      toast({
        title: `${editError.message}`,
        status: "error",
        isClosable: true,
      });
    }
  }, [isEditError, editError]);

  useEffect(() => {
    if (roomSuccess) {
      setFieldValue("name", roomData.name);
      setFieldValue("area", roomData.area);
      setFieldValue("height", roomData.height);
      setFieldValue("roomCategory", roomData.roomCategory ? roomData.roomCategory : "");
      setFieldValue("roomType", roomData.roomType ? roomData.roomType : "");
    }
  }, [roomData, roomSuccess]);

  return (
    <>
      {showModal ? (
        <UnClosableModal>
          <h1 className="font-bold text-xl w-11/12 m-auto">Are you sure you want to Remove this room from your account?</h1>
          <p className="text-sm w-11/12 mt-5 m-auto">
            Please be advised that removing this rooom from your account will result in unlinking all of the devices in this room and will result in
            the removal of all associated data from those specific devices.
          </p>

          <div className="btns grid grid-cols-2 mt-5 gap-2">
            <button onClick={() => setShowModal(false)} className="w-full  bg-[#323232] text-white  font-semibold rounded-lg h-9">
              No
            </button>
            <button onClick={handleUnlinkClick} className="w-full bg-[#EFEFEF] text-black rounded-lg font-semibold h-9">
              Yes
            </button>
          </div>
        </UnClosableModal>
      ) : (
        <Modal isLoading={isLoading}>
          <h1 className="font-bold text-xl">Edit Rooms</h1>

          <form onSubmit={handleSubmit}>
            <div className="inpGrp text-left relative">
              <p className="text-left mt-5 mb-1 font-semibold">Edit Name</p>
              <input
                name="name"
                id="name"
                value={values.name}
                onChange={handleChange}
                type="text"
                placeholder="Device Name"
                className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md w-full"
              />
              <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.name && touched.name ? errors.name : ""}</span>
            </div>
            {/* room type and category */}
            <div className="inpGrp text-left mt-4  relative">
              <label className=" font-semibold">Room Category</label>
              <CustomDropdown
                options={roomTypes.map((categories) => {
                  return { label: categories.category, value: categories.category };
                })}
                selectedValue={values.roomCategory}
                onChange={(val) => {
                  // console.log(val);
                  setFieldValue("roomType", "");
                  setFieldValue("roomCategory", val);
                }}
              />
              <span className="text-red-500 text-xs absolute -bottom-4 left-0">
                {errors.roomCategory && touched.roomCategory ? errors.roomCategory : ""}
              </span>
            </div>
            {values.roomCategory && (
              <div className="inpGrp text-left mt-4  relative">
                <label className=" font-semibold">Room Type</label>
                {values.roomCategory !== "Others" ? (
                  <CustomDropdown
                    options={roomTypes
                      .find((cat) => cat.category == values.roomCategory)
                      .type.map((types) => {
                        return { label: types, value: types };
                      })}
                    selectedValue={values.roomType}
                    onChange={(val) => {
                      // console.log(val);
                      setFieldValue("roomType", val);
                    }}
                  />
                ) : (
                  <input
                    name="roomType"
                    id="roomType"
                    value={values.roomType}
                    onChange={handleChange}
                    type="String"
                    placeholder=""
                    className="bg-[#F5F5F5] font-semibold focus:ring-0 focus:outline-black px-2 py-2 rounded-md w-full relative"
                  />
                )}
                <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.roomType && touched.roomType ? errors.roomType : ""}</span>
              </div>
            )}
            {console.log(JSON.stringify(errors))}
            {/* room type and category */}
            <div className="inpGrp text-left relative">
              <p className="text-left mt-5 mb-1 font-semibold">Edit Area</p>
              <input
                name="area"
                id="area"
                value={values.area}
                onChange={handleChange}
                type="number"
                placeholder="Device Area"
                className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md w-full"
              />
              <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.area && touched.area ? errors.area : ""}</span>
            </div>
            <div className="inpGrp text-left relative">
              <p className="text-left mt-5 mb-1 font-semibold">Edit Height</p>
              <input
                name="height"
                id="height"
                value={values.height}
                onChange={handleChange}
                type="number"
                placeholder="Device Height"
                className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md w-full"
              />
              <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.height && touched.height ? errors.height : ""}</span>
            </div>

            {values.height && values.area ? (
              <span className="text-xs font-semibold mt-3 block">
                Recommended Number Of HIVEs for this room :{Math.round((5 * values.height * values.area) / (230 * 60)) || 1}
              </span>
            ) : (
              ""
            )}

            <button type="submit" className="bg-[#323232] text-white w-3/5 h-8 mt-6 rounded-md">
              Save
            </button>
          </form>
          <button onClick={() => setShowModal(true)} className="bg-[#EFEFEF] text-black w-3/5 font-semibold h-8 mt-3 rounded-md">
            Remove Room
          </button>

          <p onClick={() => navigate(`/dashboard/addDevice/${roomId}`)} className="text-center text-sm mt-3 text-gray-500 underline">
            + Add Device
          </p>
        </Modal>
      )}
    </>
  );
};
export default EditRooms;
