// import moment from "moment/moment";
// import { TimePicker } from "./TimePicker/index";
// import { useEffect, useState } from "react";

// const CustomTimePicker = ({ startTime, onStartTimeChange, placeholder }) => {
//   console.log(moment(`${startTime.hour}:${startTime.minute}`, "HH:mm"), "asojndaond", startTime);
//   const [time, setTime] = useState(moment(`${startTime.hour}:${startTime.minute}`, "HH:mm").toDate());

//   const handleChange = (val) => {
//     console.log(val, "val");
//     // 07:00 PM val
//     setTime(moment(val, "HH:mm A").toDate());
//   };

//   useEffect(() => {
//     onStartTimeChange({ hour: moment(time).format("HH:mm").split(":")[0], minute: moment(time).format("HH:mm").split(":")[1] });
//     console.log("time --> ", time, moment(time).format("HH:mm"));
//   }, [time]);
//   console.log("initial time --> ", time);

//   return <TimePicker value={moment(time).format("HH:mm")} use12Hours time={time} onSave={handleChange} placeHolder={placeholder} />;
// };

// export default CustomTimePicker;

const CustomTimePicker = ({ startTime, onStartTimeChange, placeholder }) => {
  console.log(startTime);
  const handleChange = (val) => {
    console.log(val.target.value, "val");
    onStartTimeChange({ hour: val.target.value.split(":")[0], minute: val.target.value.split(":")[1] });
  };

  return (
    // <div className="w-full bg-white  rounded-xl  h-10 flex justify-center items-center">
    <input
      className="px-4 w-full h-10 flex justify-start shadow-container font-normal text-black rounded-xl bg-white  placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
      type="time"
      value={`${startTime.hour}:${startTime.minute}`}
      onChange={handleChange}
      placeholder={placeholder}
    />
    // </div>
  );
};
export default CustomTimePicker;
