//React/Next/ThirdPart/Custom
import { useEffect } from "react";

import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import praanLogo from "../../assets/images/praan.svg";
import { useSearchParams } from "react-router-dom";

import { useValidateUserMutation } from "../../store/services/authApi";
import { TiTick } from "react-icons/ti";

const ValidateUser = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email");

  //importing api mutation to call api and access data
  const [validateToken, { data: validateData, error: ValidateError, isError: isValidateError, isSuccess: isValidateSuccess }] =
    useValidateUserMutation();

  const toast = useToast();
  const navigate = useNavigate();

  //using formik to manage form

  useEffect(() => {
    validateToken({ token, body: { email, token } });
  }, []);

  //displaying toast on successfull login and saving the userdata along with the token

  //displaying a toast if an error occurs
  useEffect(() => {
    if (isValidateError) {
      console.log(ValidateError);
      toast({
        title: `User Token Not Valid. Please Contact Support.`,
        status: "error",
        isClosable: true,
      });
      navigate("/login");
    }
  }, [ValidateError, isValidateError]);

  return (
    <main className="h-screen  flex p-10">
      <section className="flex-1 flex items-center justify-center text-black ">
        <div className="w-full">
          <p className="w-full flex justify-center ">
            <img src={praanLogo} alt="praan logo" className="w-6/12  max-w-[150px] align-middle " />
          </p>

          {isValidateSuccess && (
            <div className=" w-4/5 m-auto">
              <TiTick className="m-auto text-3xl" />
              <h1 className="text-xl font-bold w-4/5 m-auto">User Validated Successfully, you can now add rooms and devices to your Account</h1>
              <button
                onClick={() => navigate("/")}
                className="text-white max-w-sm mt-5 bg-[#323232] hover:bg-blue-800 focus:ring-4 focus:outline-none w-4/5 h-10 font-medium rounded-lg text-sm text-center mr-3 md:mr-0"
              >
                Home
              </button>
            </div>
          )}
        </div>
      </section>
    </main>
  );
};

export default ValidateUser;
