export const ambientSensorRange = {
  p1: { min: 10, max: 20, maxError: 80, solution: "Please close your windows, and turn on turbo/deepclean mode." }, //remaining //solution - Please close your windows, and turn on turbo/deepclean mode
  p25: { min: 50, max: 100, maxError: 81, solution: "Please close your windows, and turn on turbo/deepclean mode." }, //solution - Please close your windows, and turn on turbo/deepclean mode
  p10: { min: 50, max: 100, maxError: 310, solution: "Please close your windows, and turn on turbo/deepclean mode." }, //solution - Please close your windows, and turn on turbo/deepclean mode
  temperature: { min: 18, max: 30, maxError: 40, solution: "Please turn on your air conditioning." }, //need to discuss //please turn on ac
  humidity: { min: 30, max: 60, maxError: 100, solution: "Please turn on your air conditioning." }, //turn on ac
  co2: { min: 800, max: 1200, maxError: 1600, solution: "Please Open the door/window or changing the air in the room using your HVAC system" }, //Please Open the door/window or changing the air in the room using your HVAC system
  voc: { min: 50, max: 100, maxError: 150, solution: "Please use activated carbon pellet bags for additional VOC removal." }, //please use absorbant bags
  sound: { min: 60, max: 80, maxError: 80, solution: "Reduce sound" }, //reduce sound
};

export const roomTypes = [
  {
    category: "Residential",
    type: ["Bedroom", "Living Room", "Home Office", "Kitchen", "Foyer/Lobby"],
  },
  {
    category: "Commercial",
    type: [
      "Conference Room",
      "Cabin/Private Office",
      "Coworking Space",
      "Creative Office/Workshop",
      "Service Rooms(Cafeteria*)/Lounge/Break Room",
      "Stairway/Lobby",
      "Waiting Room/Reception",
    ],
  },
  {
    category: "Educational",
    type: ["Classroom", "Auditorium", "Staff Room", "Library", "Seminar Hall"],
  },
  {
    category: "Medical",
    type: [
      "Reception/Waiting Room",
      "Lobby",
      "Day Care",
      "Pantry/Canteen",
      "Patient Room",
      "Deluxe Room",
      "Twin Sharing",
      "Single Room",
      "Doctors Lounge",
      "Consultancy Room/Doctors Cabin",
      "Pharmacy",
    ],
  },
  {
    category: "Others",
  },
];
