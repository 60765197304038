import { useEffect, useState } from "react";
import { useFindRoomByIDQuery, useRoomGraphDataMutation, usePrevRoomAvgMutation, useRoomControlMutation } from "../../store/services/roomsApi";
import { useGetAlertCountMutation } from "../../store/services/alertsApi.js";
import { useDeviceListMutation } from "../../store/services/deviceApi";

import { useParams } from "react-router-dom";
import AreaGraph from "../common/AreaGraph.jsx";

import { useNavigate } from "react-router-dom";
import { Slider, SliderTrack, SliderFilledTrack, SliderThumb, SliderMark, Tooltip, SelectField } from "@chakra-ui/react";

import {
  HighCo2Icon,
  HumidityIcon,
  NoiseIcon,
  PmIcon,
  TemperatureIcon,
  VocIcon,
  ClockIcon,
  FanSilent,
  FanStandard,
  FanTurbo,
  FanDeep,
} from "../../assets/icons/icons.jsx";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { IoIosArrowForward, IoIosArrowBack, IoIosArrowDown } from "react-icons/io";

import { ambientSensorRange } from "../../Helper/helper.js";
import moment from "moment";

import { Switch, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";

import Loading from "../common/Loading.jsx";
import NetworkStrength from "../common/NetworkStrength.jsx";

import { Fade, ScaleFade, Slide, SlideFade, Collapse } from "@chakra-ui/react";
import notificationImage from "../../assets/icons/notification.png";
import Modal from "../common/Modal.jsx";
import { isDesktop } from "react-device-detect";

let initialValues = {
  displayStatus: false,
  preClean: "",
  fanMode: "",
};

const RoomInfoDeskTop = () => {
  const { id } = useParams();
  const toast = useToast();
  // fetch current data every 2 mins
  const {
    data: roomData,
    isSuccess: roomSuccess,
    error: roomError,
    isError: isRoomError,
    refetch,
  } = useFindRoomByIDQuery(id, { pollingInterval: 2 * 60 * 1000, refetchOnMountOrArgChange: true }); //calling every 2 mins

  // get data for graph
  const [getGraphData, { data: graphData, isSuccess: graphSuccess, error: graphError, isError: isGraphError }] = useRoomGraphDataMutation();
  // get avg data for previous days
  const [getPrevData, { data: prevRoomData, isSuccess: prevRoomSuccess, error: prevRoomError, isError: isPrevRoomError }] = usePrevRoomAvgMutation();
  // get avg data for previous days
  const [controlRoom, { data: controlData, isSuccess: controlSuccess, error: controlError, isError: isControlError, isLoading: controlLoading }] =
    useRoomControlMutation();
  // device list api in the room
  const [deviceList, { data: deviceData, error: deviceError, isError: isDeviceError, isSuccess: isDeviceSuccess, isLoading: isLoading }] =
    useDeviceListMutation();
  // device list api in the room
  const [getAlerts, { data: alertsData, error: alertsError, isError: isAlertsError, isSuccess: isAlertsSuccess, alertLoading }] =
    useGetAlertCountMutation();

  const [dataKeyObject, setDataKeyObject] = useState({ value: "p25", label: "PM 2.5" });
  const [currentRoomData, setCurrentRoomData] = useState();
  const [prevData, setPrevData] = useState(false);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [alertDate, setAlertDate] = useState(moment().format("YYYY-MM-DD"));
  const [powerStatus, setPowerStatus] = useState(false);
  const [connectedDevice, setConnectedDevice] = useState(0);
  const [showDevices, setShowDevices] = useState(false);
  const [notificationVisibility, setNotificationVisibility] = useState(false);
  const [selected, setSelected] = useState({ key: "p25" });
  const [formChanged, setFormChanged] = useState(false);

  const navigate = useNavigate();
  const userEmail = JSON.parse(localStorage.getItem("user") || "").email;

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
      console.log(values);
      let bodyValue = { displayStatus: values.displayStatus };
      // displayStatus: true,
      // preClean: "",
      // fanMode: "",
      if (values.preClean !== "") {
        // bodyValue["preClean"] = values.preClean;
        if (values.preClean == "01") {
          bodyValue["preClean"] = { fanSpeed: "04", time: ((roomData.area * roomData.height) / 230 / currentRoomData.numberOfDevices).toFixed(0) };
        } else if (values.preClean == "02") {
          bodyValue["preClean"] = { fanSpeed: "03", time: ((roomData.area * roomData.height) / 160 / currentRoomData.numberOfDevices).toFixed(0) };
        } else if (values.preClean == "03") {
          bodyValue["preClean"] = { fanSpeed: "02", time: ((roomData.area * roomData.height) / 70 / currentRoomData.numberOfDevices).toFixed(0) };
        }
      }
      if (values.fanMode !== "") {
        bodyValue["fanMode"] = values.fanMode;
      }
      const bodyObject = {
        room: id,
        body: bodyValue,
      };
      controlRoom(bodyObject);
      //   action.resetForm();
    },
  });

  const handleStopPreClean = (e) => {
    e.preventDefault();
    let bodyValue = { displayStatus: values.displayStatus };
    // displayStatus: true,
    // preClean: "",
    // fanMode: "",
    bodyValue["preClean"] = "-1";
    const bodyObject = {
      room: id,
      body: bodyValue,
    };
    controlRoom(bodyObject);
  };

  const handlePowerBtn = () => {
    // setPowerStatus((powerStatus) => !powerStatus);
    const bodyObject = {
      room: id,
      body: { powerStatus: !powerStatus },
    };
    controlRoom(bodyObject);
  };

  useEffect(() => {
    deviceList(id);
    getAlerts({ roomId: id, date: alertDate });
  }, []);

  useEffect(() => {
    if (isDeviceSuccess) {
      let count = 0;
      setPowerStatus(false);
      setFieldValue("displayStatus", false);
      deviceData.forEach((device) => {
        if (device.powerStatus) {
          setPowerStatus(true);
          count++;
        }
        if (device.displayStatus) {
          setFieldValue("displayStatus", true);
        }
        if (roomData?.preClean) {
          console.log("room in preClean", device);
          let val = +device.fanMode === 4 ? "01" : +device.fanMode === 3 ? "02" : +device.fanMode === 2 ? "03" : "";
          setFieldValue("preClean", val);
        }
      });
      setConnectedDevice(count);
    }
  }, [deviceData, isDeviceSuccess]);

  useEffect(() => {
    if (controlSuccess) {
      if (controlData.findIndex((data) => data == false) != -1) {
        toast({
          title: "Error!",
          description: "Error Sending command to all devices in room. please try again",
          status: "error",
          isClosable: true,
        });
      } else {
        toast({
          title: "Devices Updated!",
          status: "success",
          isClosable: true,
        });
      }
      deviceList(id);
      refetch();
      // window.location.reload(false);
    }
  }, [controlData, controlSuccess]);

  useEffect(() => {
    getGraphData({ roomID: id, date: date });
    if (date !== moment().format("YYYY-MM-DD")) {
      getPrevData({ roomID: id, date: date });
    }
    setAlertDate(date);
  }, [date]);

  useEffect(() => {
    getAlerts({ roomId: id, date: alertDate });
  }, [alertDate]);

  useEffect(() => {
    if (prevRoomSuccess) {
      setCurrentRoomData(prevRoomData);
    }
  }, [prevRoomData, prevRoomSuccess]);

  useEffect(() => {
    if (roomData && !prevData) {
      setCurrentRoomData(roomData);
    }
  }, [roomData, roomSuccess, prevData]);

  useEffect(() => {
    setPrevData(moment().format("YYYY-MM-DD") !== date);
  }, [date]);

  useEffect(() => {
    console.log(selected);
  }, [selected]);

  return (
    <div className="h-screen w-screen fixed left-0 z-20 bg-white">
      <div className={`${controlLoading ? "" : "hidden"} `}>
        <Loading />
      </div>
      {notificationVisibility && (
        <Modal
          modalStyle={"h-4/5 relative w-11/12"}
          onClose={() => {
            setAlertDate(date);
            setNotificationVisibility(false);
          }}
        >
          <div className="container  h-full 3xl:px-5">
            <div className="top relative">
              <div
                onClick={() => setNotificationVisibility(true)}
                className="w-7 cursor-pointer h-7 top-1/2 -translate-y-1/2  absolute left-0 z-30 bg-white"
              >
                {alertsData?.length > 0 && (
                  <div className="w-[4px] h-[4px] bg-high rounded-full absolute -bottom-0.5 left-1/2 -translate-x-1/2"></div>
                )}

                <img src={notificationImage} alt="notification" />
              </div>
              <h1 className="font-bold text-xl ">Alerts</h1>
            </div>

            <div className="date mt-10 ">
              <div className="head w-11/12 m-auto flex justify-between text-[#747474] text-xs items-center">
                <FaChevronLeft
                  className="cursor-pointer"
                  onClick={() => setAlertDate((prevDate) => moment(prevDate).subtract(1, "day").format("YYYY-MM-DD"))}
                />
                <div className="group">
                  <p className="text-sm   font-semibold">
                    {alertDate ? moment(alertDate).format("dddd, DD MMMM") : moment().format("dddd, DD MMMM")}
                  </p>
                </div>
                <FaChevronRight
                  className="cursor-pointer"
                  onClick={() =>
                    setAlertDate((prevDate) =>
                      moment().format("YYYY-MM-DD") == prevDate ? prevDate : moment(prevDate).add(1, "day").format("YYYY-MM-DD")
                    )
                  }
                />
              </div>
            </div>
            <div className="alerts mt-8 h-full">
              {alertsData && alertsData.length > 0 ? (
                alertsData.map((alert) => {
                  return (
                    <div className="border-b-[1px] flex items-center h-12 border-gray-300">
                      <div className="h-[6px] w-[6.5px] rounded-full bg-high " />
                      <div className="flex w-full items-center justify-between">
                        <p className="text-md  font-semibold ml-2">
                          {" "}
                          {alert.pollutant === "P1"
                            ? "PM 1.0"
                            : alert.pollutant == "P10"
                            ? "PM 10"
                            : alert.pollutant == "P25"
                            ? "PM 2.5"
                            : alert.pollutant}{" "}
                          Levels
                        </p>

                        <div className="numbers">
                          <p className="font-bold text-md leading-4"> {alert.count}</p>
                          <p className="text-xs  text-gray-400">Alerts</p>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="h-4/5  w-full flex justify-center items-center relative font-semibold text-gray-400">
                  <p className="">No Alerts For The day</p>
                </div>
              )}
            </div>
          </div>
          <div className="absolute w-full bottom-2 text-gray-500 font-semibold text-xs left-1/2 -translate-x-1/2">
            <p>Alerts Sent to {userEmail}</p>
          </div>
        </Modal>
      )}
      {currentRoomData && (
        <div className="w-full">
          <div className={`parent w-full ${isDesktop ? "flex relative h-[92vh]" : ""}`}>
            <div className={`left ${isDesktop ? "  overflow-auto w-2/5" : ""}`}>
              <div className="top relative flex justify-center items-center  mt-5 mb-8">
                <div className="w-[75%] flex items-center relative">
                  <div onClick={() => navigate(-1)} className="back cursor-pointer">
                    <IoIosArrowBack className=" text-2xl left-5" />
                  </div>
                  <h1 className="font-bold text-2xl ml-5">{currentRoomData.name}</h1>
                  <div className={`powerBtn absolute right-0 ${powerStatus ? "text-high" : "text-good"}`}>
                    <button
                      onClick={handlePowerBtn}
                      disabled={roomData.preClean}
                      className=" disabled:border-gray-400 3xl:py-2 px-3  3xl:mt-5 disabled:text-gray-400  text-current border-current border-2 py-1 rounded-xl mt-3 font-semibold text-xl"
                    >
                      Power {powerStatus ? `Off` : `On`}
                    </button>
                    {roomData.preClean && <p className="text-high text-sm">Device is in preclean mode</p>}
                  </div>
                </div>
              </div>
              {currentRoomData.sensorData && currentRoomData.numberOfDevices > 0 && (
                <div className="sensorInfo ">
                  {!isDesktop && (
                    <div className="">
                      <p className="text-sm text-slate-600 mt-[1.5px]">
                        No. Of Devices : <span className=" text-black"> {currentRoomData.numberOfDevices} </span>
                      </p>
                      <p className="mb-5 ">
                        <p onClick={() => setShowDevices((prev) => !prev)} className="text-slate-600 font-semibold text-sm cursor-pointer  ">
                          <span className="border-b-[1px] border-slate-500 inline-flex items-center justify-center">
                            Connection Status: {connectedDevice}/{deviceData?.length}{" "}
                            <span className={`${showDevices ? "transform rotate-90" : ""}`}>
                              {" "}
                              <IoIosArrowForward />
                            </span>
                          </span>
                        </p>
                      </p>
                      <div className={`deviceList   mb-10   `}>
                        <Collapse in={showDevices} animateOpacity>
                          {deviceData &&
                            deviceData.map((device) => {
                              return (
                                <div className="deviceContain flex mt-2 items-center justify-between border-[1px] border-slate-300 rounded-lg w-4/5 m-auto px-4 py-2 ">
                                  <p className="font-bold">{device.name}</p>
                                  <div className="rightIcons">
                                    <NetworkStrength signalStrength={device.networkStrength} signalType={device.networkType} />
                                  </div>
                                </div>
                              );
                            })}
                        </Collapse>
                      </div>
                    </div>
                  )}

                  {/* 
              <button
                onClick={() => navigate(`/dashboard/addDevice/${id}`)}
                className="bg-black fixed bottom-12 right-2 w-2/5 h-8 mt-10 text-lg mb-5 rounded-md text-white "
              >
                Add Device
              </button> */}
                </div>
              )}
              {currentRoomData.numberOfDevices == 0 && (
                <div className="info h-[75vh] flex flex-col justify-center  w-4/5 m-auto">
                  <div className=" text-[#9b9b9b]">
                    There are no Devices added to this room, please add a device.
                    <br /> <br />
                    <button
                      onClick={() => navigate(`/dashboard/addDevice/${currentRoomData._id}`)}
                      className="bg-[#F5F5F5] cursor-pointer w-3/5 h-10 font-semibold rounded-md text-[#818181] "
                    >
                      + Add Device
                    </button>
                  </div>
                </div>
              )}
              <div className="mid">
                {currentRoomData.numberOfDevices !== 0 && (
                  <div className="deviceControls mt-0  mb-0">
                    {/* <h1 className="font-bold text-xl">Device Controls</h1> */}
                    <div className="container mt-3 bg-[#F9F9F9] h-[78vh]  shadow-container voc card1by1 w-9/12 m-auto relative px-8 py-9 pt-4 rounded-2xl ">
                      {/* {!powerStatus && (
                    <div className="overlay w-full h-full absolute top-0 left-0 flex justify-center items-center bg-black bg-opacity-70 bg-blend-color-burn font-bold text-white text-xl rounded-2xl z-20">
                      Device Powered Off
                    </div>
                  )} */}
                      <form onSubmit={handleSubmit} className="flex flex-col justify-evenly h-full">
                        <div className="display flex justify-between items-center">
                          <h2 className="text-left font-bold text-lg 3xl:text-xl">Device Display</h2>
                          <Switch
                            id="displayStatus"
                            name="displayStatus"
                            isChecked={values.displayStatus}
                            size={"md"}
                            className="text-xl"
                            onChange={(e) => {
                              handleChange(e);
                              setFormChanged(true);
                            }}
                            colorScheme="blackAlpha"
                            sx={{ ".chakra-switch__track[data-checked]:not([data-theme])": { backgroundColor: "black" } }}
                          />
                        </div>

                        <div className="pre clean">
                          <h2 className="text-left font-bold mt-3 text-lg 3xl:text-xl 3xl:mt-5">Pre Clean</h2>
                          <div className="radio">
                            {/*  */}
                            <div className="grid mt-1 w-full grid-cols-3 gap-2 rounded-xl  py-2">
                              <div>
                                <input
                                  type="radio"
                                  id="preClean-1"
                                  name="preClean"
                                  value="01"
                                  disabled={roomData?.preClean}
                                  checked={values.preClean === "01"}
                                  onClick={(e) => {
                                    console.log(e.target.value, values.preClean);
                                    setFormChanged(true);

                                    if (e.target.value === values.preClean) {
                                      setFieldValue("preClean", "");
                                    }
                                  }}
                                  onChange={(e) => {
                                    // values.fanMode = "";
                                    setFieldValue("fanMode", "");
                                    handleChange(e);
                                  }}
                                  className="peer hidden"
                                />
                                <label
                                  htmlFor="preClean-1"
                                  className=" block bg-white text-[#AEAEAE] py-2 3xl:py-4 px-1 shadow-container cursor-pointer select-none rounded-xl  text-center peer-checked:bg-black peer-checked:font-bold peer-checked:text-white"
                                >
                                  <div className="">
                                    <ClockIcon className=" m-auto  stroke-current " />
                                    <p className="font-bold text-lg text-current ">
                                      {((roomData.area * roomData.height) / 230 / currentRoomData.numberOfDevices).toFixed(0)} Mins
                                    </p>
                                    <p className="font-bold text-xs text-current ">High Noise</p>
                                  </div>
                                </label>
                              </div>
                              <div>
                                <input
                                  type="radio"
                                  id="preClean-2"
                                  name="preClean"
                                  value="02"
                                  disabled={roomData?.preClean}
                                  checked={values.preClean === "02"}
                                  onClick={(e) => {
                                    console.log(e.target.value, values.preClean);
                                    setFormChanged(true);

                                    if (e.target.value === values.preClean) {
                                      setFieldValue("preClean", "");
                                    }
                                  }}
                                  onChange={(e) => {
                                    // values.fanMode = "";
                                    setFieldValue("fanMode", "");
                                    handleChange(e);
                                  }}
                                  className="peer hidden"
                                />
                                <label
                                  htmlFor="preClean-2"
                                  className=" block bg-white text-[#AEAEAE] py-2 px-1 3xl:py-4 shadow-container cursor-pointer select-none rounded-xl  text-center peer-checked:bg-black peer-checked:font-bold peer-checked:text-white"
                                >
                                  <div className="">
                                    <ClockIcon className=" m-auto  stroke-current " />
                                    <p className="font-bold text-lg text-current ">
                                      {((roomData.area * roomData.height) / 160 / currentRoomData.numberOfDevices).toFixed(0)} Mins
                                    </p>
                                    <p className="font-bold text-xs text-current ">Medium Noise</p>
                                  </div>
                                </label>
                              </div>
                              <div>
                                <input
                                  type="radio"
                                  id="preClean-3"
                                  name="preClean"
                                  disabled={roomData?.preClean}
                                  onClick={(e) => {
                                    console.log(e.target.value, values.preClean);
                                    setFormChanged(true);

                                    if (e.target.value === values.preClean) {
                                      setFieldValue("preClean", "");
                                    }
                                  }}
                                  value="03"
                                  checked={values.preClean === "03"}
                                  onChange={(e) => {
                                    // values.fanMode = "";
                                    setFieldValue("fanMode", "");
                                    handleChange(e);
                                  }}
                                  className="peer hidden"
                                />
                                <label
                                  htmlFor="preClean-3"
                                  className=" block bg-white text-[#AEAEAE] py-2 px-1  3xl:py-4 shadow-container cursor-pointer select-none rounded-xl  text-center peer-checked:bg-black peer-checked:font-bold peer-checked:text-white"
                                >
                                  <div className="">
                                    <ClockIcon className=" m-auto  stroke-current " />
                                    <p className="font-bold text-lg text-current ">
                                      {((roomData.area * roomData.height) / 70 / currentRoomData.numberOfDevices).toFixed(0)} Mins
                                    </p>
                                    <p className="font-bold text-xs text-current ">Low Noise</p>
                                  </div>
                                </label>
                              </div>
                            </div>
                            {/*  */}
                          </div>

                          {roomData.preClean && (
                            <div className="translate-y-3">
                              <button
                                onClick={handleStopPreClean}
                                className="w-full  text-high border-high border-2 py-1  3xl:py-2 rounded-xl mt-3 font-semibold text-lg"
                              >
                                Stop Pre Clean
                              </button>
                              <p className="text-sm text-high translate-y-1">Device in Pre Clean Mode</p>
                            </div>
                          )}
                        </div>

                        <div className="cleaning mode">
                          <div className="CleaningMode mt-3">
                            <h2 className="text-left font-bold text-lg 3xl:text-xl 3xl:mt-5">Cleaning Mode</h2>
                            <div className="grid mt-2 w-full grid-rows-4 gap-2 3xl:gap-3 rounded-xl  py-2">
                              <div>
                                <input
                                  type="radio"
                                  id="fanMode-1"
                                  name="fanMode"
                                  value="01"
                                  disabled={roomData?.preClean}
                                  onClick={(e) => {
                                    setFormChanged(true);

                                    if (e.target.value === values.fanMode) {
                                      setFieldValue("fanMode", "");
                                    }
                                  }}
                                  checked={values.fanMode === "01"}
                                  onChange={(e) => {
                                    setFieldValue("preClean", "");
                                    handleChange(e);
                                  }}
                                  className="peer hidden"
                                />
                                <label
                                  htmlFor="fanMode-1"
                                  className="block  bg-[#F1F1F1] text-[#AEAEAE] py-0 px-1 3xl:py-1 shadow-container cursor-pointer select-none rounded-xl  text-center peer-checked:bg-black peer-checked:font-bold peer-checked:text-white"
                                >
                                  <div className="flex w-full px-5 py-2  justify-between items-center">
                                    <p className="font-bold text-md text-current ">Silent</p>
                                    <div className="icon flex items-center ">
                                      <FanSilent className="  fill-current w-6 stroke-current " />
                                      <p className="ml-3">50 dB</p>
                                    </div>
                                  </div>
                                </label>
                              </div>

                              <div>
                                <input
                                  type="radio"
                                  id="fanMode-2"
                                  name="fanMode"
                                  value="02"
                                  disabled={roomData?.preClean}
                                  onClick={(e) => {
                                    setFormChanged(true);

                                    if (e.target.value === values.fanMode) {
                                      setFieldValue("fanMode", "");
                                    }
                                  }}
                                  checked={values.fanMode === "02"}
                                  onChange={(e) => {
                                    setFieldValue("preClean", "");
                                    handleChange(e);
                                  }}
                                  className="peer hidden"
                                />
                                <label
                                  htmlFor="fanMode-2"
                                  className="block  bg-[#F1F1F1] text-[#AEAEAE] py-0 px-1 3xl:py-1 shadow-container cursor-pointer select-none rounded-xl  text-center peer-checked:bg-black peer-checked:font-bold peer-checked:text-white"
                                >
                                  <div className="flex w-full px-5 py-2  justify-between items-center">
                                    <p className="font-bold text-md text-current ">Standard</p>
                                    <div className="icon flex items-center  ">
                                      <FanStandard className="  fill-current w-6 stroke-current " />
                                      <p className="ml-3">57 dB</p>
                                    </div>
                                  </div>
                                </label>
                              </div>

                              <div>
                                <input
                                  type="radio"
                                  id="fanMode-3"
                                  name="fanMode"
                                  disabled={roomData?.preClean}
                                  value="03"
                                  checked={values.fanMode === "03"}
                                  onClick={(e) => {
                                    setFormChanged(true);

                                    if (e.target.value === values.fanMode) {
                                      setFieldValue("fanMode", "");
                                    }
                                  }}
                                  onChange={(e) => {
                                    setFieldValue("preClean", "");
                                    handleChange(e);
                                  }}
                                  className="peer hidden"
                                />
                                <label
                                  htmlFor="fanMode-3"
                                  className="block  bg-[#F1F1F1] text-[#AEAEAE] py-0 px-1 3xl:py-1 shadow-container cursor-pointer select-none rounded-xl  text-center peer-checked:bg-black peer-checked:font-bold peer-checked:text-white"
                                >
                                  <div className="flex w-full px-5 py-2  justify-between items-center">
                                    <p className="font-bold text-md text-current ">Turbo</p>
                                    <div className="icon flex items-center ">
                                      <FanTurbo className="  fill-current w-6 stroke-current " />
                                      <p className="ml-3">69 dB</p>
                                    </div>
                                  </div>
                                </label>
                              </div>

                              <div>
                                <input
                                  type="radio"
                                  id="fanMode-4"
                                  name="fanMode"
                                  value="04"
                                  disabled={roomData?.preClean}
                                  checked={values.fanMode === "04"}
                                  onClick={(e) => {
                                    setFormChanged(true);

                                    if (e.target.value === values.fanMode) {
                                      setFieldValue("fanMode", "");
                                    }
                                  }}
                                  onChange={(e) => {
                                    setFieldValue("preClean", "");
                                    handleChange(e);
                                  }}
                                  className="peer hidden"
                                />
                                <label
                                  htmlFor="fanMode-4"
                                  className="block  bg-[#F1F1F1] text-[#AEAEAE] py-0 px-1 3xl:py-1 shadow-container cursor-pointer select-none rounded-xl  text-center peer-checked:bg-black peer-checked:font-bold peer-checked:text-white"
                                >
                                  <div className="flex w-full px-5 py-2  justify-between items-center">
                                    <p className="font-bold text-md text-current ">Deep Clean</p>
                                    <div className="icon flex items-center ">
                                      <FanDeep className="  fill-current  w-6 stroke-current " />
                                      <p className="ml-3">75 dB</p>
                                    </div>
                                  </div>
                                </label>
                              </div>

                              <div>
                                <input
                                  type="radio"
                                  id="fanMode-5"
                                  name="fanMode"
                                  value="05"
                                  disabled={roomData?.preClean}
                                  checked={values.fanMode === "05"}
                                  onClick={(e) => {
                                    setFormChanged(true);

                                    if (e.target.value === values.fanMode) {
                                      setFieldValue("fanMode", "");
                                    }
                                  }}
                                  onChange={(e) => {
                                    setFieldValue("preClean", "");
                                    handleChange(e);
                                  }}
                                  className="peer hidden"
                                />
                                <label
                                  htmlFor="fanMode-5"
                                  className="block  bg-[#F1F1F1] text-[#AEAEAE] py-0 px-1 3xl:py-1 shadow-container cursor-pointer select-none rounded-xl  text-center peer-checked:bg-black peer-checked:font-bold peer-checked:text-white"
                                >
                                  <div className="flex w-full px-5 py-2  justify-between items-center">
                                    <p className="font-bold text-md text-current ">Auto Mode</p>
                                    <div className="icon flex items-center ">
                                      <FanDeep className="  fill-current  w-6 stroke-current " />
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="btn">
                          <button
                            type="submit"
                            disabled={!formChanged}
                            className="w-full disabled:bg-white disabled:opacity-40 3xl:py-2 disabled:text-black disabled:border-2 disabled:border-black bg-black text-white py-1 rounded-xl mt-5 font-semibold text-lg"
                          >
                            Update
                          </button>
                        </div>
                      </form>
                    </div>
                    {/* <div className={`powerBtn mt-5 ${powerStatus ? "text-high" : "text-good"}`}>
                      <button
                        onClick={handlePowerBtn}
                        disabled={roomData.preClean}
                        className="w-7/12 disabled:border-gray-400 3xl:py-2 3xl:mt-5 disabled:text-gray-400  text-current border-current border-2 py-1 rounded-xl mt-3 font-semibold text-xl"
                      >
                        Power {powerStatus ? `Off` : `On`}
                      </button>
                      {roomData.preClean && <p className="text-high text-sm">Device is in preclean mode</p>}
                    </div> */}
                  </div>
                )}
              </div>
            </div>

            <div className={`right ${isDesktop ? "h-full w-4/5 overflow-auto pt-4 flex flex-col " : ""}`}>
              <div className="image m-auto w-11/12 h-[55vh] mt-3 mb-10">
                <div className="head w-11/12 m-auto relative flex justify-center gap-6  text-[#747474] text-xs items-center">
                  <FaChevronLeft
                    className="cursor-pointer"
                    onClick={() => setDate((prevDate) => moment(prevDate).subtract(1, "day").format("YYYY-MM-DD"))}
                  />
                  <div className="group">
                    <p className="text-base   font-semibold">{date ? moment(date).format("dddd, DD MMMM") : moment().format("dddd, DD MMMM")}</p>
                  </div>
                  <FaChevronRight
                    className="cursor-pointer"
                    onClick={() =>
                      setDate((prevDate) =>
                        moment().format("YYYY-MM-DD") == prevDate ? prevDate : moment(prevDate).add(1, "day").format("YYYY-MM-DD")
                      )
                    }
                  />

                  {/* notification icon in the header */}
                  <div
                    onClick={() => setNotificationVisibility(true)}
                    className="w-9 h-9 rounded-full border-2 cursor-pointer border-gray-200 p-1 absolute right-0 z-30 bg-white"
                  >
                    {alertsData?.length > 0 && <div className="w-[4px] h-[4px] bg-high rounded-full absolute -right-0.5 -top-0.5"></div>}
                    <img src={notificationImage} alt="notification" />
                  </div>
                </div>
                <h1 className="text-slate-600 font-semibold mb-2">{dataKeyObject.label}</h1>
                <div className="flex justify-center items-center h-full">
                  <AreaGraph dataKeyObject={dataKeyObject} graphData={graphData} />
                </div>
              </div>

              {!currentRoomData.sensorData && currentRoomData.numberOfDevices !== 0 && (
                <div className="info   w-4/5 mt-16 m-auto">No data recorded for this day</div>
              )}

              {currentRoomData.sensorData && (
                <div className={`  w-full ease-in duration-300  mt-3`}>
                  <div className="lineData w-11/12 px-5 m-auto  flex justify-center items-center h-14 pb-1  ">
                    <Slider
                      id="slider"
                      defaultValue={30}
                      value={
                        selected.key == "p1"
                          ? +currentRoomData.sensorData.p1 < ambientSensorRange.p1.min
                            ? (+currentRoomData.sensorData.p1 / ambientSensorRange.p1.min / 100) * 25 * 100
                            : +currentRoomData.sensorData.p1 < ambientSensorRange.p1.max
                            ? ((+currentRoomData.sensorData.p1 - ambientSensorRange.p1.min) /
                                (ambientSensorRange.p1.max - ambientSensorRange.p1.min)) *
                                50 +
                              25
                            : ((((+currentRoomData.sensorData.p1 - ambientSensorRange.p1.max) /
                                (ambientSensorRange.p1.maxError - ambientSensorRange.p1.max)) *
                                100) /
                                100) *
                                25 +
                              75
                          : selected.key == "p25"
                          ? +currentRoomData.sensorData.p25 < ambientSensorRange.p25.min
                            ? (+currentRoomData.sensorData.p25 / ambientSensorRange.p25.min / 100) * 25 * 100
                            : +currentRoomData.sensorData.p25 < ambientSensorRange.p25.max
                            ? ((+currentRoomData.sensorData.p25 - ambientSensorRange.p25.min) /
                                (ambientSensorRange.p25.max - ambientSensorRange.p25.min)) *
                                50 +
                              25
                            : ((((+currentRoomData.sensorData.p25 - ambientSensorRange.p25.max) /
                                (ambientSensorRange.p25.maxError - ambientSensorRange.p25.max)) *
                                100) /
                                100) *
                                25 +
                              75
                          : selected.key == "p10"
                          ? +currentRoomData.sensorData.p10 < ambientSensorRange.p10.min
                            ? (+currentRoomData.sensorData.p10 / ambientSensorRange.p10.min / 100) * 25 * 100
                            : +currentRoomData.sensorData.p10 < ambientSensorRange.p10.max
                            ? ((+currentRoomData.sensorData.p10 - ambientSensorRange.p10.min) /
                                (ambientSensorRange.p10.max - ambientSensorRange.p10.min)) *
                                50 +
                              25
                            : ((((+currentRoomData.sensorData.p10 - ambientSensorRange.p10.max) /
                                (ambientSensorRange.p10.maxError - ambientSensorRange.p10.max)) *
                                100) /
                                100) *
                                25 +
                              75
                          : selected.key == "voc"
                          ? +currentRoomData.sensorData.voc < ambientSensorRange.voc.min
                            ? (+currentRoomData.sensorData.voc / ambientSensorRange.voc.min / 100) * 25 * 100
                            : +currentRoomData.sensorData.voc < ambientSensorRange.voc.max
                            ? ((+currentRoomData.sensorData.voc - ambientSensorRange.voc.min) /
                                (ambientSensorRange.voc.max - ambientSensorRange.voc.min)) *
                                50 +
                              25
                            : ((((+currentRoomData.sensorData.voc - ambientSensorRange.voc.max) /
                                (ambientSensorRange.voc.maxError - ambientSensorRange.voc.max)) *
                                100) /
                                100) *
                                25 +
                              75
                          : selected.key == "humidity"
                          ? +currentRoomData.sensorData.humidity < ambientSensorRange.humidity.min
                            ? (+currentRoomData.sensorData.humidity / ambientSensorRange.humidity.min / 100) * 25 * 100
                            : +currentRoomData.sensorData.humidity < ambientSensorRange.humidity.max
                            ? ((+currentRoomData.sensorData.humidity - ambientSensorRange.humidity.min) /
                                (ambientSensorRange.humidity.max - ambientSensorRange.humidity.min)) *
                                50 +
                              25
                            : ((((+currentRoomData.sensorData.humidity - ambientSensorRange.humidity.max) /
                                (ambientSensorRange.humidity.maxError - ambientSensorRange.humidity.max)) *
                                100) /
                                100) *
                                25 +
                              75
                          : selected.key == "co2"
                          ? +currentRoomData.sensorData.co2 < ambientSensorRange.co2.min
                            ? (+currentRoomData.sensorData.co2 / ambientSensorRange.co2.min / 100) * 25 * 100
                            : +currentRoomData.sensorData.co2 < ambientSensorRange.co2.max
                            ? ((+currentRoomData.sensorData.co2 - ambientSensorRange.co2.min) /
                                (ambientSensorRange.co2.max - ambientSensorRange.co2.min)) *
                                50 +
                              25
                            : ((((+currentRoomData.sensorData.co2 - ambientSensorRange.co2.max) /
                                (ambientSensorRange.co2.maxError - ambientSensorRange.co2.max)) *
                                100) /
                                100) *
                                25 +
                              75
                          : selected.key == "temperature"
                          ? +currentRoomData.sensorData.temperature < ambientSensorRange.temperature.min
                            ? (+currentRoomData.sensorData.temperature / ambientSensorRange.temperature.min / 100) * 25 * 100
                            : +currentRoomData.sensorData.temperature < ambientSensorRange.temperature.max
                            ? ((+currentRoomData.sensorData.temperature - ambientSensorRange.temperature.min) /
                                (ambientSensorRange.temperature.max - ambientSensorRange.temperature.min)) *
                                50 +
                              25
                            : ((((+currentRoomData.sensorData.temperature - ambientSensorRange.temperature.max) /
                                (ambientSensorRange.temperature.maxError - ambientSensorRange.temperature.max)) *
                                100) /
                                100) *
                                25 +
                              75
                          : selected.key == "sound"
                          ? +currentRoomData.sensorData.sound < ambientSensorRange.sound.min
                            ? (+currentRoomData.sensorData.sound / ambientSensorRange.sound.min / 100) * 25 * 100
                            : +currentRoomData.sensorData.sound < ambientSensorRange.sound.max
                            ? ((+currentRoomData.sensorData.sound - ambientSensorRange.sound.min) /
                                (ambientSensorRange.sound.max - ambientSensorRange.sound.min)) *
                                50 +
                              25
                            : ((((+currentRoomData.sensorData.sound - ambientSensorRange.sound.max) /
                                (ambientSensorRange.sound.maxError - ambientSensorRange.sound.max)) *
                                100) /
                                100) *
                                25 +
                              75
                          : ""
                      }
                      min={0}
                      // max={selected.key == "p1" ? 350 : selected.key == "p25" ? 100 : selected.key == "p10" ? 300 : ""}
                      max={100}
                      colorScheme="yellow"
                      _disabled={"none"}
                      isDisabled
                      // onChange={(v) => setSliderValue(v)}
                      // onMouseEnter={() => setShowTooltip(true)}
                      // onMouseLeave={() => setShowTooltip(false)}
                    >
                      <SliderMark value={25} mt="-1" ml="-2.5" fontSize="xs" color={"#00000040"}>
                        <div className=" flex flex-col items-center">
                          <div className="w-[1px] h-2 mb-0.5 bg-current"></div>
                          {selected.key == "p1"
                            ? ambientSensorRange.p1.min
                            : selected.key == "p25"
                            ? ambientSensorRange.p25.min
                            : selected.key == "p10"
                            ? ambientSensorRange.p10.min
                            : selected.key == "voc"
                            ? ambientSensorRange.voc.min
                            : selected.key == "humidity"
                            ? ambientSensorRange.humidity.min
                            : selected.key == "co2"
                            ? ambientSensorRange.co2.min
                            : selected.key == "temperature"
                            ? ambientSensorRange.temperature.min
                            : selected.key == "sound"
                            ? ambientSensorRange.sound.min
                            : ""}
                        </div>
                      </SliderMark>
                      <SliderMark value={50} mt="-1" ml="-2.5" fontSize="xs" color={"#00000040"}>
                        <div className=" flex flex-col items-center">
                          <div className="w-[1px] h-2 mb-0.5 bg-current"></div>
                          {selected.key == "p1"
                            ? (ambientSensorRange.p1.min + (ambientSensorRange.p1.max - ambientSensorRange.p1.min) / 2).toFixed(0)
                            : selected.key == "p25"
                            ? (ambientSensorRange.p25.min + (ambientSensorRange.p25.max - ambientSensorRange.p25.min) / 2).toFixed(0)
                            : selected.key == "p10"
                            ? (ambientSensorRange.p10.min + (ambientSensorRange.p10.max - ambientSensorRange.p10.min) / 2).toFixed(0)
                            : selected.key == "voc"
                            ? (ambientSensorRange.voc.min + (ambientSensorRange.voc.max - ambientSensorRange.voc.min) / 2).toFixed(0)
                            : selected.key == "humidity"
                            ? (ambientSensorRange.humidity.min + (ambientSensorRange.humidity.max - ambientSensorRange.humidity.min) / 2).toFixed(0)
                            : selected.key == "co2"
                            ? (ambientSensorRange.co2.min + (ambientSensorRange.co2.max - ambientSensorRange.co2.min) / 2).toFixed(0)
                            : selected.key == "sound"
                            ? (ambientSensorRange.sound.min + (ambientSensorRange.sound.max - ambientSensorRange.sound.min) / 2).toFixed(0)
                            : selected.key == "temperature"
                            ? (
                                ambientSensorRange.temperature.min +
                                (ambientSensorRange.temperature.max - ambientSensorRange.temperature.min) / 2
                              ).toFixed(0)
                            : ""}
                        </div>
                      </SliderMark>
                      <SliderMark value={75} mt="-1" ml="-2.5" fontSize="xs" color={"#00000040"}>
                        <div className=" flex flex-col items-center">
                          <div className="w-[1px] h-2 mb-0.5 bg-current"></div>
                          {selected.key == "p1"
                            ? ambientSensorRange.p1.max
                            : selected.key == "p25"
                            ? ambientSensorRange.p25.max
                            : selected.key == "p10"
                            ? ambientSensorRange.p10.max
                            : selected.key == "voc"
                            ? ambientSensorRange.voc.max
                            : selected.key == "humidity"
                            ? ambientSensorRange.humidity.max
                            : selected.key == "co2"
                            ? ambientSensorRange.co2.max
                            : selected.key == "temperature"
                            ? ambientSensorRange.temperature.max
                            : selected.key == "sound"
                            ? ambientSensorRange.sound.max
                            : ""}
                        </div>
                      </SliderMark>
                      <SliderTrack
                        bgGradient={`${
                          selected.key == "temperature"
                            ? "linear-gradient(90deg, rgba(85,175,203,1) 14%, rgba(136,185,56,1) 31%, rgba(136,185,56,1) 66%, rgba(246,179,79,1) 83%, rgba(211,91,91,1) 100%);"
                            : selected.key == "humidity"
                            ? "linear-gradient( 90deg, hsl(0deg 58% 59%) 0%, hsl(14deg 74% 62%) 4%, hsl(25deg 86% 63%) 9%, hsl(36deg 90% 64%) 14%, hsl(48deg 65% 54%) 20%, hsl(63deg 56% 46%) 26%, hsl(83deg 54% 47%) 36%, hsl(63deg 56% 46%) 67%, hsl(48deg 65% 54%) 76%, hsl(36deg 90% 64%) 83%, hsl(25deg 86% 63%) 89%, hsl(14deg 74% 62%) 95%, hsl(0deg 58% 59%) 100% )"
                            : "linear-gradient( 90deg, hsl(83deg 54% 47%) 0%, hsl(77deg 55% 47%) 9%, hsl(71deg 56% 46%) 16%, hsl(66deg 56% 46%) 23%, hsl(61deg 56% 46%) 29%, hsl(57deg 56% 48%) 34%, hsl(52deg 58% 50%) 40%, hsl(49deg 63% 53%) 44%, hsl(45deg 69% 56%) 49%, hsl(42deg 76% 58%) 53%, hsl(39deg 83% 61%) 58%, hsl(36deg 90% 64%) 62%, hsl(33deg 89% 63%) 66%, hsl(30deg 87% 62%) 69%, hsl(28deg 85% 62%) 73%, hsl(25deg 82% 61%) 77%, hsl(22deg 79% 60%) 80%, hsl(19deg 75% 60%) 84%, hsl(15deg 72% 59%) 87%, hsl(12deg 68% 58%) 90%, hsl(8deg 63% 57%) 94%, hsl(4deg 59% 56%) 97%, hsl(0deg 55% 55%) 100% )"
                        }`}
                      >
                        {/* <SliderFilledTrack /> */}
                      </SliderTrack>

                      <SliderThumb boxSize={"2"} border={"1px"} bg={"white"} _disabled={"none"} borderColor={"#00000040"}>
                        {" "}
                        {/* <div className="bg-black w-0.5 h-3"></div>{" "} */}
                      </SliderThumb>
                    </Slider>
                  </div>
                </div>
              )}

              {currentRoomData.sensorData && (
                <div className="sensorData">
                  {/* row 1 --> pm values */}
                  <div className="row w-11/12 m-auto gap-2 grid grid-cols-8 p-1 px-4    mt-3">
                    <div
                      onClick={() => {
                        setDataKeyObject({ value: "p1", label: "PM 1" });
                        setSelected({ key: "p1" });
                      }}
                      className={`card1by1 ${dataKeyObject.value == "p1" ? "border-current border-2" : ""}  ${
                        ambientSensorRange.p1.max < currentRoomData?.sensorData.p1
                          ? "text-high"
                          : ambientSensorRange.p1.min > currentRoomData?.sensorData.p1
                          ? "text-good"
                          : "text-warn"
                      }  w-full p-5 py-3 cursor-pointer  relative rounded-2xl shadow-container flex flex-col justify-center items-center `}
                    >
                      <div className="icon aspect-auto w-3/12">
                        <PmIcon className="fill-current mb-1" />
                      </div>
                      <p className="font-semibold text-black">PM 1.0</p>
                      <h2 className="font-bold text-2xl  ">{Math.round(+currentRoomData?.sensorData?.p1).valueOf()} </h2>
                      <p className="unit font-bold leading-3 pb-2">µg/m³</p>
                    </div>

                    <div
                      onClick={() => {
                        setDataKeyObject({ value: "p25", label: "PM 2.5" });
                        setSelected({ key: "p25" });
                      }}
                      className={`card1by1 py-3 cursor-pointer w-full ${dataKeyObject.value == "p25" ? "border-current border-2" : ""}  ${
                        ambientSensorRange.p25.max < currentRoomData?.sensorData.p25
                          ? "text-high"
                          : ambientSensorRange.p25.min > currentRoomData?.sensorData.p25
                          ? "text-good"
                          : "text-warn"
                      } w-full p-5  relative rounded-2xl shadow-container flex flex-col justify-center items-center`}
                    >
                      <div className="icon w-4/12  ">
                        <PmIcon className="fill-current  m-auto mb-1" />
                      </div>
                      <p className="font-semibold text-black">PM 2.5</p>
                      <h2 className="font-bold text-2xl ">{Math.round(+currentRoomData?.sensorData?.p25).valueOf()} </h2>
                      <p className="unit font-bold  leading-3 pb-2">µg/m³</p>
                    </div>

                    <div
                      onClick={() => {
                        setDataKeyObject({ value: "p10", label: "PM 10" });
                        setSelected({ key: "p10" });
                      }}
                      className={`card1by1 py-3 cursor-pointer w-full ${dataKeyObject.value == "p10" ? "border-current border-2" : ""}  ${
                        ambientSensorRange.p10.max < currentRoomData?.sensorData.p10
                          ? "text-high"
                          : ambientSensorRange.p10.min > currentRoomData?.sensorData.p10
                          ? "text-good"
                          : "text-warn"
                      } w-full p-5  relative rounded-2xl shadow-container flex flex-col justify-center items-center`}
                    >
                      <div className="icon w-5/12  ">
                        <PmIcon className="fill-current  m-auto mb-1" />
                      </div>
                      <p className="font-semibold text-black">PM 10</p>
                      <h2 className="font-bold text-2xl ">{Math.round(+currentRoomData?.sensorData?.p10).valueOf()} </h2>
                      <p className="unit font-bold  leading-3 pb-2">µg/m³</p>
                    </div>

                    <div
                      onClick={() => {
                        setDataKeyObject({ value: "voc", label: "VOC" });
                        setSelected({ key: "voc" });
                      }}
                      className={`voc card1by1 cursor-pointer col-span-3   ${dataKeyObject.value == "voc" ? "border-current border-2" : ""}  ${
                        ambientSensorRange.voc.max < currentRoomData?.sensorData.voc
                          ? "text-high"
                          : ambientSensorRange.voc.min > currentRoomData?.sensorData.voc
                          ? "text-good"
                          : "text-warn"
                      } relative px-5 py-3 rounded-2xl shadow-container `}
                    >
                      <div className="icon absolute  right-6 top-5">
                        {" "}
                        <VocIcon className="fill-current  m-auto " />
                      </div>
                      <p className="font-semibold text-left text-black">VOCs</p>
                      <h2 className="font-bold text-left text-2xl ">{Math.round(+currentRoomData?.sensorData?.voc).valueOf()} Unit</h2>
                      <div className="flex justify-between items-end relative">
                        <p className="text-left text-sm mt-1 text-black">
                          High levels of VOC can cause dizziness, <br /> nausea, and loss of coordination.
                        </p>
                      </div>
                    </div>

                    <div
                      onClick={() => {
                        setDataKeyObject({ value: "humidity", label: "Humidity" });
                        setSelected({ key: "humidity" });
                      }}
                      className={`card1by1 col-span-2 cursor-pointer w-full p-5  rounded-2xl shadow-container py-4 px-5 flex flex-col justify-around relative ${
                        dataKeyObject.value == "humidity" ? "border-current border-2" : ""
                      }  ${
                        ambientSensorRange.humidity.max < currentRoomData?.sensorData.humidity
                          ? "text-high"
                          : ambientSensorRange.humidity.min < currentRoomData?.sensorData.humidity
                          ? "text-good"
                          : "text-warn"
                      }   `}
                    >
                      <div className=" relative w-full">
                        <p className="font-semibold text-left text-black">Humidity</p>
                      </div>
                      <div className="val flex justify-between  mt-3">
                        <h2 className="font-bold text-2xl ">{Math.round(+currentRoomData?.sensorData?.humidity).valueOf()}%</h2>
                        <div className="icon text-xs">
                          <HumidityIcon className="fill-current  m-auto " />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* line graph */}
                  {/* {(selected.key == "p1" || selected.key == "p25" || selected.key == "p10") && ( */}

                  {/* )} */}
                  {/* row 2 --> voc */}
                  {/* row 3 --> co2 */}
                  <div className="row w-11/12 m-auto gap-2 grid grid-cols-8 p-1 px-4 mb-10 ">
                    <div
                      onClick={() => {
                        setDataKeyObject({ value: "co2", label: "CO2" });
                        setSelected({ key: "co2" });
                      }}
                      className={`co2 col-span-3 cursor-pointer card1by1  ${dataKeyObject.value == "co2" ? "border-current border-2" : ""}  ${
                        ambientSensorRange.co2.max < currentRoomData?.sensorData.co2
                          ? "text-high"
                          : ambientSensorRange.co2.min > currentRoomData?.sensorData.co2
                          ? "text-good"
                          : "text-warn"
                      } relative px-5 py-2 rounded-2xl shadow-container `}
                    >
                      <div className="icon absolute right-6 top-5">
                        {" "}
                        <HighCo2Icon className="fill-current  m-auto " />
                        {/* <img src={HighCo2Icon} alt="CO2" className="fill-current  m-auto " /> */}
                      </div>
                      <p className="font-semibold text-left text-black">CO2</p>
                      <h2 className="font-bold text-left text-2xl ">{Math.round(+currentRoomData?.sensorData?.co2).valueOf()} ppb</h2>

                      <div className="flex justify-between items-end relative ">
                        <p className="text-left text-sm  w-11/12  mt-1 text-black">
                          High Levels of CO2 can result in headaches, nausea, and fatigue.
                        </p>
                        {/* <div
                      onClick={() => {
                        if (selected.key == "co2") setSelected({ key: "" });
                        else setSelected({ key: "co2" });
                      }}
                      className={`selector  absolute -bottom-2 -right-2 ${
                        selected.key == "co2" ? "rotate-180 " : ""
                      } w-9 h-9 flex justify-center items-center ease-in duration-300`}
                    >
                      <IoIosArrowDown className="text-gray-500 text-xl" />
                    </div> */}
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        setDataKeyObject({ value: "temperature", label: "Temperature" });
                        setSelected({ key: "temperature" });
                      }}
                      className={`card1by1 col-span-2 cursor-pointer w-full p-5 ${
                        dataKeyObject.value == "temperature" ? "border-current border-2" : ""
                      }  ${
                        ambientSensorRange.temperature.max < currentRoomData?.sensorData.temperature
                          ? "text-high"
                          : ambientSensorRange.temperature.min > currentRoomData?.sensorData.temperature
                          ? "text-good"
                          : "text-warn"
                      } rounded-2xl shadow-container py-3 flex flex-col justify-between py-4 px-5 relative `}
                    >
                      <div className=" relative w-full">
                        <p className="font-semibold text-left text-black">Temperature</p>
                      </div>
                      <div className="val flex justify-between mt-3">
                        <h2 className="font-bold text-2xl ">{Math.round(+currentRoomData?.sensorData?.temperature).valueOf()}°C</h2>
                        <div className="icon text-xs">
                          {" "}
                          <TemperatureIcon className="fill-current  m-auto " />
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        setDataKeyObject({ value: "sound", label: "Noise" });
                        setSelected({ key: "sound" });
                      }}
                      className={`voc col-span-3  cursor-pointer card1by1  ${dataKeyObject.value == "sound" ? "border-current border-2" : ""}  ${
                        ambientSensorRange.sound.max < currentRoomData?.sensorData.sound
                          ? "text-high"
                          : ambientSensorRange.sound.min > currentRoomData?.sensorData.sound
                          ? "text-good"
                          : "text-warn"
                      }  relative px-5 py-2 rounded-2xl shadow-container `}
                    >
                      <div className="icon absolute right-6 top-5">
                        {" "}
                        <NoiseIcon className="fill-current  m-auto " />
                      </div>
                      <p className="font-semibold text-left text-black">Noise</p>
                      <h2 className="font-bold text-left text-2xl ">{Math.round(+currentRoomData?.sensorData?.sound).valueOf()} dB</h2>

                      <div className="flex justify-between items-end relative">
                        <p className="text-left text-sm  mt-1 text-black">
                          High Noise levels over a prolonged period of time may start to damage your hearing.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="no of devices and connection status mb-5">
                <p className="text-sm text-slate-600 mt-[1.5px]">
                  No. Of Devices : <span className=" text-black"> {currentRoomData?.numberOfDevices ? currentRoomData?.numberOfDevices : "-"} </span>
                </p>
                <p className="mb-5 ">
                  <p onClick={() => setShowDevices((prev) => !prev)} className="text-slate-600 cursor-pointer font-semibold text-sm   ">
                    <span className="border-b-[1px] border-slate-500 inline-flex items-center justify-center">
                      Connection Status: {connectedDevice}/{deviceData?.length}{" "}
                      <span className={`${showDevices ? "transform rotate-90" : ""}`}>
                        {" "}
                        <IoIosArrowForward />
                      </span>
                    </span>
                  </p>
                </p>
                <div className={`deviceList   `}>
                  <Collapse in={showDevices} animateOpacity>
                    {deviceData &&
                      deviceData.map((device) => {
                        return (
                          <div className="deviceContain flex mt-2 items-center justify-between border-[1px] border-slate-300 rounded-lg w-4/5 m-auto px-4 py-2 ">
                            <p className="font-bold">{device.name}</p>
                            <div className="rightIcons">
                              <NetworkStrength signalStrength={device.networkStrength} signalType={device.networkType} />
                            </div>
                          </div>
                        );
                      })}
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default RoomInfoDeskTop;
