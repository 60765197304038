import "./App.css";
import { BrowserView, MobileView, isDesktop } from "react-device-detect";
import { useAppSelector } from "./store/hooks";
import { Route, Routes } from "react-router-dom";

import DesktopPage from "./pages/desktopPage/DesktopPage";
import NavBar from "./components/NavBar";
import Header from "./components/Header";
import LoginPage from "./pages/login/Login";
import SignupPage from "./pages/signup/Signup";
import Dashboard from "./pages/dashboard/Dashboard";
import AddRoom from "./components/dashboard/AddRooms";
import AddDevice from "./components/dashboard/AddDevice";
import Settings from "./pages/setting/Settings";
import DeviceControl from "./components/settings/DeviceControl";
import EditDevice from "./components/settings/EditDevice";
import EditRooms from "./components/settings/EditRooms";
import Support from "./pages/support/Support";
import Offline from "./pages/offline/Offline";
import ForgotPassword from "./pages/forgetPassword/ForgotPassword";
import ResetPassword from "./pages/forgetPassword/ResetPassword";
import InternalDevice from "./pages/internalDevices/InternalDevice";
import ValidateUser from "./pages/validateUser/ValidateUser";
import Clients from "./pages/clients/Clients";
import Schedule from "./pages/schedule/Schedule";
import DeleteAccount from "./pages/DeleteAccount/DeleteAccount";
// desktop components
import NavBarDesktop from "./components/NavBarDesktop";
import PerformancePage from "./pages/performancePage/PerformancePage";

function App() {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const userRole = useAppSelector((state) => state?.auth?.user?.response?.role);

  window.addEventListener("visibilitychange", function () {
    console.log("Visibility changed");
    if (document.visibilityState === "visible" && !isDesktop) {
      console.log("APP resumed");
      window.location.reload();
    }
  });

  return (
    <div className="App select-none w-full pb-[env(safe-area-inset-bottom)]  ">
      <BrowserView>
        {/* <Routes>
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/validateUser" element={<ValidateUser />} />
          <Route path="/*" element={<DesktopPage />} />
        </Routes> */}

        <div className="w-full h-screen fixed overflow-auto m-auto bg-white">
          <div className="w-screen  h-full m-auto bg-white">
            <Routes>
              <Route path="/offline" element={<Offline />} />
            </Routes>
            <div className="container w-full m-auto bg-white ">
              {!isAuthenticated && (
                <div className="h-screen mb-safe-offset-10 	">
                  <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/deleteAcc" element={<DeleteAccount />} />
                    <Route path="/signup" element={<SignupPage />} />
                    <Route path="/forgotPassword" element={<ForgotPassword />} />
                    <Route path="/resetPassword" element={<ResetPassword />} />
                    <Route path="/validateUser" element={<ValidateUser />} />
                    <Route path="/*" element={<LoginPage />} />
                  </Routes>
                </div>
              )}
              {isAuthenticated && (
                <div className=" pt-[8vh] pl-48 m-auto w-full h-full  ">
                  <NavBarDesktop />
                  <Header />
                  <Routes>
                    <Route path="/dashboard/addRoom" element={<AddRoom />} />
                    <Route path="/validateUser" element={<ValidateUser />} />
                    <Route path="/dashboard/addDevice/:roomID" element={<AddDevice />} />
                    <Route path="/dashboard/*" element={<Dashboard />} />
                    <Route path="/support/*" element={<Support />} />
                    <Route path="/settings/deviceControls/:deviceId" element={<DeviceControl />} />
                    <Route path="/settings/editDevice/:deviceName/:deviceId/:roomId" element={<EditDevice />} />
                    <Route path="/settings/editRooms/:roomId/" element={<EditRooms />} />
                    <Route path="/settings/schedule/:roomId/" element={<Schedule />} />
                    <Route path="/settings/*" element={<Settings />} />
                    <Route path="/*" element={<Dashboard />} />
                    {(userRole == "admin" || userRole == "super-admin") && <Route path="/internalDevices" element={<InternalDevice />} />}
                    {(userRole == "admin" || userRole == "super-admin") && <Route path="/clients" element={<Clients />} />}
                    {(userRole == "admin" || userRole == "super-admin") && <Route path="/PerformancePage" element={<PerformancePage />} />}
                  </Routes>
                </div>
              )}
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <Routes>
          <Route path="/offline" element={<Offline />} />
        </Routes>
        <div className="container w-full m-auto ">
          {!isAuthenticated && (
            <div className="h-screen mb-safe-offset-10 	">
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/signup" element={<SignupPage />} />
                <Route path="/forgotPassword" element={<ForgotPassword />} />
                <Route path="/resetPassword" element={<ResetPassword />} />
                <Route path="/validateUser" element={<ValidateUser />} />
                <Route path="/*" element={<LoginPage />} />
              </Routes>
            </div>
          )}
          {isAuthenticated && (
            <div className=" pt-16 mb-safe pb-16 m-auto w-full ">
              <NavBar />
              <Header />
              <Routes>
                <Route path="/validateUser" element={<ValidateUser />} />
                <Route path="/dashboard/addRoom" element={<AddRoom />} />
                <Route path="/dashboard/addDevice/:roomID" element={<AddDevice />} />
                <Route path="/dashboard/*" element={<Dashboard />} />
                <Route path="/support/*" element={<Support />} />
                <Route path="/settings/deviceControls/:deviceId" element={<DeviceControl />} />
                <Route path="/settings/editDevice/:deviceName/:deviceId/:roomId" element={<EditDevice />} />
                <Route path="/settings/editRooms/:roomId/" element={<EditRooms />} />
                <Route path="/settings/schedule/:roomId/" element={<Schedule />} />
                <Route path="/settings/*" element={<Settings />} />
                <Route path="/*" element={<Dashboard />} />
                {(userRole == "admin" || userRole == "super-admin") && <Route path="/internalDevices" element={<InternalDevice />} />}
                {(userRole == "admin" || userRole == "super-admin") && <Route path="/clients" element={<Clients />} />}
              </Routes>
            </div>
          )}
        </div>
      </MobileView>
    </div>
  );
}

export default App;
