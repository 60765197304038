import { useEffect, useState } from "react";
import { useInternalDeviceListMutation, useCreateInternalDeviceMutation, useCreateInternalDeviceBulkMutation } from "../../store/services/deviceApi";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/common/Modal";
import { SkeletonText } from "@chakra-ui/react";
import hiveImg from "../../assets/images/hiveImage.png";
import { useFormik } from "formik";
import { isDesktop } from "react-device-detect";
import { useZxing } from "react-zxing";
import { FaCamera } from "react-icons/fa6";
import { useToast } from "@chakra-ui/react";
import xlsxParser from "xlsx-parse-json";
import { setAdmintargetUserID } from "../../store/features/authSlice";
import { useAppDispatch } from "../../store/hooks";

const initialValues = {
  deviceID: "",
  networkType: "RSSI",
  clientType: "b2b",
  deviceType: "full",
  sensors: ["pm", "temperature", "voc", "sound", "co2", "humidity"],
};

const InternalDevice = () => {
  const [list, { data: deviceData, error: deviceError, isError: isDeviceError, isSuccess: isDeviceSuccess, isLoading: isLoading }] =
    useInternalDeviceListMutation();

  const [createDevice, { data: deviceCreateData, error: deviceCreateError, isError: isDeviceCreateError, isSuccess: isDeviceCreateSuccess }] =
    useCreateInternalDeviceMutation();
  const [
    createBulkDevice,
    { data: deviceCreateBulkData, error: deviceCreateBulkError, isError: isDeviceCreateBulkError, isSuccess: isDeviceCreateBulkSuccess },
  ] = useCreateInternalDeviceBulkMutation();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: initialValues,
    // validationSchema: roomsSchema,
    onSubmit: (values, action) => {
      console.log(values);

      createDevice(values);
      action.resetForm();
    },
  });

  const [isinternalDeviceOpen, setIsinternalDeviceOpen] = useState(false);

  // const [deviceList, setDeviceList] = useState([]);

  const handleUpload = async (e) => {
    console.log("upload");
    console.log(e.target);

    const file = e.target.files[0];

    // Check if a file is selected
    if (file) {
      // Read the contents of the file as a Blob
      const blob = await readFileAsBlob(file);

      // Use the blob data as needed
      console.log("blob data = ", blob);

      let jsonData = await xlsxParser.onFileSelection(blob, { showNullProperties: false, hideEmptyRows: false });
      console.log("json data = ", jsonData);
      createBulkDevice({ devices: jsonData["Sheet1"] });
    }
    e.target.value = null;
  };
  const readFileAsBlob = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      // Set up the onload event for the FileReader
      reader.onload = () => {
        // Resolve with the result as a Blob
        resolve(new Blob([reader.result], { type: file.type }));
      };

      // Set up the onerror event for the FileReader
      reader.onerror = () => {
        // Reject if there is an error
        reject(new Error("Error reading file."));
      };

      // Read the file as ArrayBuffer (binary data)
      reader.readAsArrayBuffer(file);
    });
  };

  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useAppDispatch();

  const handleSetUser = (selectedUser, deviceId) => {
    if (selectedUser) {
      dispatch(setAdmintargetUserID(selectedUser));
      // navigate(`dashboard/devices/${deviceId}`);
      navigate(`dashboard/`);
    }
  };

  useEffect(() => {
    list();
  }, []);

  useEffect(() => {
    if (isDeviceCreateBulkSuccess) {
      toast({
        title: "Devices Added Successfully",
        status: "success",
        isClosable: true,
      });
      list();

      // navigate(`/dashboard/devices/${deviceData._id}`);
    }
  }, [isDeviceCreateBulkSuccess, deviceCreateBulkError]);
  useEffect(() => {
    if (isDeviceCreateSuccess) {
      toast({
        title: "Device Added Successfully",
        status: "success",
        isClosable: true,
      });
      list();

      // navigate(`/dashboard/devices/${deviceData._id}`);
    }
  }, [isDeviceCreateSuccess, deviceCreateError]);

  useEffect(() => {
    if (isDeviceCreateBulkError) {
      console.log(deviceCreateBulkError);
      toast({
        title: `${deviceCreateBulkError.message}`,
        status: "error",
        isClosable: true,
      });
    }
  }, [isDeviceCreateBulkError, deviceCreateBulkError]);

  useEffect(() => {
    if (isDeviceCreateError) {
      console.log(deviceCreateError);
      toast({
        title: `${deviceCreateError.message}`,
        status: "error",
        isClosable: true,
      });
    }
  }, [isDeviceCreateError, deviceCreateError]);

  const [openCamera, setOpenCamera] = useState(false);

  const { ref } = useZxing({
    onError(err) {
      console.log("error", err);
    },
    paused: !openCamera,
    onDecodeResult(result) {
      console.log(typeof +result.getText());
      if (+result.getText()) {
        setFieldValue("deviceID", result.getText());
        setOpenCamera(false);
      }
    },
    // deviceId: selectedDevice,
  });

  return (
    <div className={`pb-5 w-screen m-auto fixed bg-white left-0 z-50 h-screen top-0 mt-16 `}>
      {isLoading && (
        <div className={`   ${isDesktop ? "w-10/12 mt-10" : "w-11/12"} flex flex-col gap-5 m-auto`}>
          {/* <SkeletonCircle /> */}
          <SkeletonText speed={2} startColor="#ededed" />
        </div>
      )}
      <div className="topbar h-14 mb-5 flex justify-between items-center border-b-2 pb-3 mt-3 px-10 m-auto">
        {isinternalDeviceOpen && (
          <Modal modalStyle=" min-w-[40%]" className="" onClose={() => setIsinternalDeviceOpen(false)}>
            {openCamera && (
              <Modal onClose={() => setOpenCamera(false)}>
                <div className="my-4 relative">
                  <h1 className="font-semibold  text-xl">Scan Bar Code On Device</h1>
                  {/* <p className="font-semibold text-left">Select Camera</p>
            <p>{JSON.stringify(devices)}</p>
            <CustomDropdown
              options={devices.map((device) => {
                return { value: device.deviceId, label: device.label };
              })}
              onChange={(val) => setSelectedDevice(val)}
              selectedValue={selectedDevice}
            /> */}
                </div>
                <video ref={ref} className={`${openCamera ? "block" : "hidden"}`} />
              </Modal>
            )}

            {/* {reportLoading && (
              <div className="h-screen w-screen fixed top-0 left-0 flex justify-center items-center bg-black backdrop-blur-[2px] bg-opacity-80 z-50">
                <Spinner color="white" />
              </div>
            )} */}
            <h1 className="font-bold text-xl mb-9 ">Add Internal Device</h1>

            <form action="">
              <form className="mt-5" action="">
                <div className="inpGrp text-left relative">
                  <label className=" font-semibold">Device ID</label>
                  <div className="flex bg-[#F5F5F5] rounded-md pr-3">
                    <input
                      name="deviceID"
                      id="deviceID"
                      value={values.deviceID}
                      onChange={handleChange}
                      type="text"
                      placeholder=""
                      className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md w-full relative"
                    />
                    <button type="button" onClick={() => setOpenCamera(true)}>
                      <FaCamera />
                    </button>
                  </div>
                  <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.deviceId && touched.deviceId ? errors.deviceId : ""}</span>
                </div>

                <div className="inpGrp text-left relative mt-4">
                  <label className=" font-semibold">Device Network Type</label>
                  {/* <input
                    name="networkType"
                    id="networkType"
                    value={values.networkType}
                    onChange={handleChange}
                    type="text"
                    placeholder=""
                    className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md w-full"
                  /> */}
                  <div className="radio">
                    {/*  */}
                    <div className="grid mt-2 w-full grid-cols-2 gap-2 rounded-xl  py-2">
                      <div>
                        <input
                          type="radio"
                          id="networkType-1"
                          name="networkType"
                          value="RSSI"
                          checked={values.networkType === "RSSI"}
                          onChange={(e) => {
                            // values.fanMode = "";
                            // setFieldValue("fanMode", "");
                            handleChange(e);
                          }}
                          className="peer hidden"
                        />
                        <label
                          htmlFor="networkType-1"
                          className=" block bg-white text-[#AEAEAE] py-3 px-1 shadow-container cursor-pointer select-none rounded-xl  text-center peer-checked:bg-black peer-checked:font-bold peer-checked:text-white"
                        >
                          <div className="">
                            <p className="font-bold text-lg text-current ">RSSI</p>
                          </div>
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="networkType-2"
                          name="networkType"
                          value="CSQ"
                          checked={values.networkType === "CSQ"}
                          onChange={(e) => {
                            // values.fanMode = "";
                            // setFieldValue("fanMode", "");
                            handleChange(e);
                          }}
                          className="peer hidden"
                        />
                        <label
                          htmlFor="networkType-2"
                          className=" block bg-white text-[#AEAEAE] py-3 px-1 shadow-container cursor-pointer select-none rounded-xl  text-center peer-checked:bg-black peer-checked:font-bold peer-checked:text-white"
                        >
                          <div className="">
                            <p className="font-bold text-lg text-current ">CSQ</p>
                          </div>
                        </label>
                      </div>
                    </div>
                    {/*  */}
                  </div>
                  <span className="text-red-500 text-xs absolute -bottom-4 left-0">
                    {errors.networkType && touched.networkType ? errors.networkType : ""}
                  </span>
                </div>

                <div className="inpGrp text-left relative mt-4">
                  <label className=" font-semibold">Client Type</label>
                  {/* <input
                    name="networkType"
                    id="networkType"
                    value={values.networkType}
                    onChange={handleChange}
                    type="text"
                    placeholder=""
                    className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md w-full"
                  /> */}
                  <div className="radio">
                    {/*  */}
                    <div className="grid mt-2 w-full grid-cols-2 gap-2 rounded-xl  py-2">
                      <div>
                        <input
                          type="radio"
                          id="clientType-1"
                          name="clientType"
                          value="b2b"
                          checked={values.clientType === "b2b"}
                          onChange={(e) => {
                            // values.fanMode = "";
                            // setFieldValue("fanMode", "");
                            handleChange(e);
                          }}
                          className="peer hidden"
                        />
                        <label
                          htmlFor="clientType-1"
                          className=" block bg-white text-[#AEAEAE] py-3 px-1 shadow-container cursor-pointer select-none rounded-xl  text-center peer-checked:bg-black peer-checked:font-bold peer-checked:text-white"
                        >
                          <div className="">
                            <p className="font-bold text-lg text-current ">b2b</p>
                          </div>
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="clientType-2"
                          name="clientType"
                          value="b2c"
                          checked={values.clientType === "b2c"}
                          onChange={(e) => {
                            // values.fanMode = "";
                            // setFieldValue("fanMode", "");
                            handleChange(e);
                          }}
                          className="peer hidden"
                        />
                        <label
                          htmlFor="clientType-2"
                          className=" block bg-white text-[#AEAEAE] py-3 px-1 shadow-container cursor-pointer select-none rounded-xl  text-center peer-checked:bg-black peer-checked:font-bold peer-checked:text-white"
                        >
                          <div className="">
                            <p className="font-bold text-lg text-current ">b2c</p>
                          </div>
                        </label>
                      </div>
                    </div>
                    {/*  */}
                  </div>
                  <span className="text-red-500 text-xs absolute -bottom-4 left-0">
                    {errors.clientType && touched.clientType ? errors.clientType : ""}
                  </span>
                </div>

                <div className="inpGrp text-left relative mt-4">
                  <label className=" font-semibold">Device Type</label>
                  {/* <input
                    name="networkType"
                    id="networkType"
                    value={values.networkType}
                    onChange={handleChange}
                    type="text"
                    placeholder=""
                    className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md w-full"
                  /> */}
                  <div className="radio">
                    {/*  */}
                    <div className="grid mt-2 w-full grid-cols-2 gap-2 rounded-xl  py-2">
                      <div>
                        <input
                          type="radio"
                          id="deviceType-1"
                          name="deviceType"
                          value="full"
                          checked={values.deviceType === "full"}
                          onChange={(e) => {
                            // values.fanMode = "";
                            // setFieldValue("fanMode", "");
                            handleChange(e);
                          }}
                          className="peer hidden"
                        />
                        <label
                          htmlFor="deviceType-1"
                          className=" block bg-white text-[#AEAEAE] py-3 px-1 shadow-container cursor-pointer select-none rounded-xl  text-center peer-checked:bg-black peer-checked:font-bold peer-checked:text-white"
                        >
                          <div className="">
                            <p className="font-bold text-lg text-current ">Full</p>
                          </div>
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="deviceType-2"
                          name="deviceType"
                          value="lite"
                          checked={values.deviceType === "lite"}
                          onChange={(e) => {
                            // values.fanMode = "";
                            // setFieldValue("fanMode", "");
                            handleChange(e);
                          }}
                          className="peer hidden"
                        />
                        <label
                          htmlFor="deviceType-2"
                          className=" block bg-white text-[#AEAEAE] py-3 px-1 shadow-container cursor-pointer select-none rounded-xl  text-center peer-checked:bg-black peer-checked:font-bold peer-checked:text-white"
                        >
                          <div className="">
                            <p className="font-bold text-lg text-current ">Lite</p>
                          </div>
                        </label>
                      </div>
                    </div>
                    {/*  */}
                  </div>
                  <span className="text-red-500 text-xs absolute -bottom-4 left-0">
                    {errors.deviceType && touched.deviceType ? errors.deviceType : ""}
                  </span>
                </div>

                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit(e);
                  }}
                  type="submit"
                  className="bg-[#323232] text-white w-3/5 h-8 mt-6 rounded-md"
                >
                  Add Device
                </button>
              </form>
            </form>
          </Modal>
        )}
        <h1 className="text-xl font-semibold">Internal Devices</h1>
        <div className="flex">
          <button onClick={() => navigate("/clients")} className="rounded-lg h-10 border-[1px] px-5 border-black font-semibold mr-5">
            Clients
          </button>
          <input type="file" onChange={handleUpload} name="Upload Devices" id="BulkImport" className="hidden" />
          <label htmlFor="BulkImport" className="mr-4 rounded-lg border border-black px-2 flex justify-center items-center font-semibold">
            Bulk Import
          </label>
          <button onClick={() => setIsinternalDeviceOpen(true)} className="rounded-lg h-10 border-[1px] px-5 border-black font-semibold">
            Add Internal Device
          </button>
        </div>
      </div>
      <div className={` overflow-auto ${isDesktop ? "mb-36" : "pb-20"} h-[90vh] w-screen px-10 pb-24 `}>
        {deviceData && (
          <div className={`grid ${isDesktop ? "grid-cols-4 gap-5" : "grid-cols-1 gap-1"} `}>
            {deviceData.length == 0 && <h1 className="w-4/5 m-auto text-[#9b9b9b]">There are no Devices added yet. </h1>}
            {deviceData.map((device) => {
              return (
                <div
                  key={device._id}
                  onClick={() => handleSetUser(device?.user, device.deviceID)}
                  // onClick={() => navigate(`/dashboard/devices/${device._id}`)}
                  className={`container relative mt-3 px-4 py-3.5 m-auto shadow-container h-[7.3rem]  rounded-2xl border border-opacity-10`}
                >
                  <h4 className="font-semibold text-left">{device.deviceID}</h4>
                  <p className="text-slate-500 text-xs text-left font-light ml-1">Device Type - {device.deviceType} </p>
                  <p className="text-slate-500 text-xs text-left font-light ml-1"> Client Type - {device.clientType} </p>
                  <p className="text-slate-500 text-xs text-left font-light ml-1">
                    {device?.registeredToUser ? "Registered To User" : "Not Registered To User"}
                  </p>
                  <p className="text-slate-500 text-xs text-left font-light ml-1"> User Email - {device?.userObject?.email} </p>
                  {/* <p className=" text-slate-500 text-left text-xs">{device.roomObject.name}</p> */}

                  {/* <img className="w-1/2 max-w-[200px] mt-1" src={hiveImg} alt="" /> */}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default InternalDevice;
