import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { Buffer } from "buffer";
window.Buffer = Buffer;

export const reportApi = createApi({
  reducerPath: "reportApi",
  // configuring baseQuery
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = JSON.parse(localStorage.getItem("user") || "").token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        headers.set("targetUserID", getState().auth.targetUserID);
      }
    },
  }),
  endpoints: (builder) => ({
    // downloadReport: builder.mutation({
    //   queryFn: async (body, api, extraOptions, baseQuery) => {
    //     try {
    //       const result = await baseQuery({
    //         url: `/report`,
    //         method: "post",
    //         body,

    //         responseHandler: (response) => {
    //           console.log("response -->", response);
    //           return response;
    //         },
    //       });
    //       console.log("result --> ", result.error.data.message);
    //       var hiddenElement = document.createElement("a");
    //       var url = window.URL || window.webkitURL;
    //       var blobPDF = url.createObjectURL(result.data);
    //       hiddenElement.href = blobPDF;
    //       hiddenElement.target = "_blank";
    //       hiddenElement.download = `HIVE Report.pdf`;
    //       hiddenElement.click();
    //       return { data: null };
    //     } catch (err) {
    //       console.log(err);
    //     }
    //   },
    // }),

    downloadReport: builder.mutation({
      query: (body) => ({
        url: `/report`,
        method: "post",
        body,
      }),
      transformResponse: async (response) => {
        console.log("response --> ", response.data);

        const buffer = Buffer.from(response.data);
        const blob = new Blob([buffer]);

        console.log("blob created");
        // Create a Blob URL
        const blobURL = URL.createObjectURL(blob);

        // Create an anchor element
        const downloadLink = document.createElement("a");

        // Set the download link attributes
        downloadLink.href = blobURL;
        downloadLink.target = "_blank";
        downloadLink.download = `HIVE_Report.pdf`;

        // Append the link to the document
        document.body.appendChild(downloadLink);

        // Trigger a click on the link to initiate the download
        downloadLink.click();

        // Remove the link from the document
        document.body.removeChild(downloadLink);

        // Return null since you've initiated the download and don't need to update the state
        return { data: null };
      },
      transformErrorResponse: async (error) => {
        if (error.response) {
          // The request was made and the server responded with a non-OK status
          const data = await error.response.json();
          console.error("API Error Response:", data);
          return { error: data };
        } else if (error.request) {
          // The request was made but no response was received
          console.error("No response received from the server");
          return { error: "No response received from the server" };
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error setting up the request:", error);
          return { message: error.data?.message };
        }
      },
    }),
  }),
});

export const { useDownloadReportMutation } = reportApi;
