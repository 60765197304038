import { useEffect, useState } from "react";
import { useUserListMutation } from "../../store/services/usersApi";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/common/Modal";
import { SkeletonText } from "@chakra-ui/react";
import hiveImg from "../../assets/images/hiveImage.png";
import { isDesktop } from "react-device-detect";
import { setAdmintargetUserID } from "../../store/features/authSlice";
import { useAppDispatch } from "../../store/hooks";

const Clients = () => {
  const [list, { data: userData, error: userError, isError: isUserError, isSuccess: isUserSuccess, isLoading: isLoading }] = useUserListMutation();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [role, setRole] = useState("");
  useEffect(() => {
    list(role);
  }, []);

  const handleSetUser = (selectedUser) => {
    dispatch(setAdmintargetUserID(selectedUser));
    navigate("/dashboard");
  };

  return (
    <div className={`pb-5 w-screen m-auto fixed bg-white left-0 z-50 h-screen top-0 mt-16`}>
      {isLoading && (
        <div className={`   ${isDesktop ? "w-10/12 mt-10" : "w-11/12"} flex flex-col gap-5 m-auto`}>
          {/* <SkeletonCircle /> */}
          <SkeletonText speed={2} startColor="#ededed" />
        </div>
      )}
      <div className="topbar h-14 mb-5 flex justify-between items-center border-b-2 pb-3 mt-3 px-10 m-auto">
        <h1 className="text-xl font-semibold">Clients</h1>
        <div className="">
          <button onClick={() => navigate("/internalDevices")} className="rounded-lg border-[1px] h-10 px-5 border-black font-semibold">
            Internal Devices
          </button>
          <button onClick={() => navigate("/PerformancePage")} className="rounded-lg border-[1px] h-10 px-5 border-black font-semibold ml-3">
            Performance Analyze
          </button>
        </div>
      </div>

      <div className={` overflow-auto ${isDesktop ? "mb-36" : "pb-20"} h-[90vh] w-screen px-10 pb-24 `}>
        {userData && (
          <div className={`grid ${isDesktop ? "grid-cols-4 gap-5" : "grid-cols-1 gap-1"} `}>
            {userData.length == 0 && <h1 className="w-4/5 m-auto text-[#9b9b9b]">There are No Clients yet. </h1>}
            {userData.map((user) => {
              return (
                <div
                  key={user._id}
                  onClick={() => handleSetUser(user._id)}
                  className={`container cursor-pointer relative mt-3 px-4 py-3.5 m-auto shadow-container h-[7.3rem]  rounded-2xl border border-opacity-10`}
                >
                  <h4 className="font-semibold text-left">{user.name}</h4>
                  <p className="text-slate-500 text-xs text-left font-light ml-1">Email - {user.email} </p>
                  <p className="text-slate-500 text-xs text-left font-light ml-1">Phone Number - {user.phoneNumber} </p>
                  <p className="text-slate-500 text-xs text-left font-light ml-1"> Role - {user.role} </p>
                  <p className="text-slate-500 text-xs text-left font-light ml-1">Timezone - {user?.timeZone}</p>
                  {/* <p className=" text-slate-500 text-left text-xs">{user.roomObject.name}</p> */}

                  {/* <img className="w-1/2 max-w-[200px] mt-1" src={hiveImg} alt="" /> */}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Clients;
