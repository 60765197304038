import { useListRoomsMutation } from "../../store/services/roomsApi";
import { IoIosArrowForward } from "react-icons/io";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../common/Modal";
import { SkeletonText } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";

import { useAppSelector } from "../../store/hooks";
import roomImage from "../../assets/images/room.png";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { useDownloadReportMutation } from "../../store/services/reportsApi";
import { useToast } from "@chakra-ui/react";
import xlsx from "json-as-xlsx";
import JSZip from "jszip";
import { saveAs } from "file-saver";

import { useMassExportPerformanceMutation } from "../../store/services/deviceApi";

const RoomListsDesktop = () => {
  const [list, { data: roomData, error: roomError, isError: isRoomError, isSuccess: isRoomSuccess, isLoading: isLoading }] = useListRoomsMutation();
  const [downloadReport, { data: reportData, error: reportError, isError: isReportError, isSuccess: isReportSuccess, isLoading: reportLoading }] =
    useDownloadReportMutation();

  const [
    getAllData,
    { data: performanceData, error: performanceError, isError: isperformanceError, isSuccess: isperformanceSuccess, isLoading: performanceLoading },
  ] = useMassExportPerformanceMutation();

  const userRole = useAppSelector((state) => state.auth.user.response.role);
  const userId = useAppSelector((state) => state.auth.targetUserID);
  console.log("user role --> ", userRole);

  const [isReportOpen, setIsReportOpen] = useState(false);
  const [showDateSelection, setShowDateSelection] = useState(false);

  const navigate = useNavigate();
  const toast = useToast();

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const onDownloadReport = () => {
    downloadReport(dateRange);
  };

  const handleSubmit = () => {
    let bodyObject = {
      body: dateRange[0],
      params: {
        user: userId,
      },
    };
    getAllData(bodyObject);
  };

  useEffect(() => {
    if (isperformanceSuccess) {
      const zip = new JSZip();
      for (let i = 0; i < performanceData.deviceDatas.length; i++) {
        let currentDeviceData = performanceData.deviceDatas[i];

        let excelData = [
          {
            sheet: "Overall Report",
            columns: [
              { label: "User Email", value: (row) => row?.deviceData?.userEmail },
              { label: "Device ID", value: (row) => row?.deviceData?.deviceNo },
              { label: "Pre Clean Used", value: (row) => row?.preCleanUsedCount + " times" },
              // { label: "Fan Speed", value: (row) => row?.preCleanUsedCount?.fanMode },
              // { label: "Time In Fan Speed", value: (row) => row?.preCleanUsedCount?.totalDurationInMinutes },
              // { label: "Event End Date", value: "event_stopped_at" },
              // { label: "Drop Percent", value: "pmDropPercent" },
              // { label: "Power Status", value: "devicePower" },
              // { label: "Room Name", value: "roomName" },
              // { label: "Room Area", value: "roomArea" },
              // { label: "Room Height", value: "roomHeight" },
            ],
            content: currentDeviceData?.devicePerformanceData?.overall,
          },
          {
            sheet: "Fan Speed Usage Report",
            columns: [
              { label: "Date", value: (row) => row?.date },
              { label: "Off", value: (row) => row?.fanData?.find((x) => x.fanMode == "00")?.duration },
              { label: "Silent", value: (row) => row?.fanData?.find((x) => x.fanMode == "01")?.duration },
              { label: "Standard", value: (row) => row?.fanData?.find((x) => x.fanMode == "02")?.duration },
              { label: "Turbo", value: (row) => row?.fanData?.find((x) => x.fanMode == "03")?.duration },
              { label: "Deep Clean", value: (row) => row?.fanData?.find((x) => x.fanMode == "04")?.duration },
              { label: "Auto Mode", value: (row) => row?.fanData?.find((x) => x.fanMode == "05")?.duration },
              // { label: "Time In Fan Speed (Minutes)", value: (row) => row?.totalDurationInMinutes + " Minutes" },
            ],
            content: currentDeviceData?.devicePerformanceData?.deviceWorkingCount,
          },
          {
            sheet: "Pre Clean Report",
            columns: [
              // { label: "User Email", value: "userEmail" },
              // { label: "Device ID", value: "deviceNo" },
              { label: "Event Start Date", value: "converted_start_date" },
              { label: "Event End Date", value: "converted_end_date" },
              { label: "Fan Mode", value: "fanMode" },
              { label: "Pre Clean Time", value: (row) => row.preCleanTime + " Minutes" },
              { label: "Initial PM", value: (row) => row?.sensorData[0]?.initial?.p25 },
              { label: "Final PM", value: (row) => row?.sensorData[0]?.final?.p25 },
              { label: "Power Status", value: "devicePower" },
              { label: "Room Name", value: "roomName" },

              // { label: "Room Area", value: "roomArea" },
              // { label: "Room Height", value: "roomHeight" },
            ],
            content: currentDeviceData?.devicePerformanceData?.devicePreCleanHistory,
          },
          {
            sheet: "CADR Report",
            columns: [
              // { label: "User Email", value: "userEmail" },
              // { label: "Device ID", value: "deviceNo" },
              { label: "Event Start Date", value: "event_started_at" },
              { label: "Event End Date", value: "event_stopped_at" },
              { label: "Fan Mode", value: "fanMode" },
              { label: "CADR", value: "cadr" },
              { label: "Drop Percent", value: "pmDropPercent" },
              { label: "Initial PM", value: (row) => row?.sensorData[0]?.initial?.p25 },
              { label: "Final PM", value: (row) => row?.sensorData[0]?.final?.p25 },
              { label: "Power Status", value: "devicePower" },
              { label: "Room Name", value: "roomName" },
              { label: "Room Area", value: "roomArea" },
              { label: "Room Height", value: "roomHeight" },
            ],
            content: currentDeviceData?.devicePerformanceData?.fanData,
          },
        ];
        let settings = {
          fileName: `${currentDeviceData.deviceNo}_Performance Report`, // Name of the resulting spreadsheet
          extraLength: 5, // A bigger number means that columns will be wider
          writeMode: "write", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
          writeOptions: {
            type: "buffer",
            bookType: "xlsx",
          }, // Style options from https://docs.sheetjs.com/docs/api/write-options
          RTL: false, // Display the columns from right-to-left (the default value is false)
        };
        let excelBlob = xlsx(excelData, settings);

        zip.file(`${currentDeviceData.deviceNo}.xlsx`, excelBlob);
      }

      zip.generateAsync({ type: "blob" }).then(function (content) {
        // see FileSaver.js
        saveAs(content, performanceData.userInfo.name);
      });
    }
  }, [performanceData, isperformanceSuccess]);

  useEffect(() => {
    console.log(dateRange);
  }, [dateRange]);

  useEffect(() => {
    if (isReportError) {
      toast({
        title: `${reportError.message ? reportError.message : "Something went wrong. Please Try Again"}`,
        status: "error",
        isClosable: true,
      });
    }
  }, [isReportError, reportError]);

  const [roomsList, setRoomList] = useState([]);
  useEffect(() => {
    list();
  }, []);

  useEffect(() => {
    if (isRoomSuccess) {
      console.log(roomData);
      setRoomList(roomData);
      console.log("room list updated-> ", roomsList);
    }
  }, [roomData, isRoomSuccess]);

  return (
    <div className="pl-10 pr-5">
      {isLoading && (
        <div className="w-11/12  flex flex-col gap-5 m-auto">
          {/* <SkeletonCircle /> */}
          <SkeletonText speed={2} startColor="#ededed" />
        </div>
      )}

      {(userRole == "industry" || userRole == "admin" || userRole == "super-admin") && (
        <div className="topbar h-14 mb-5 flex justify-end border-b-2 pb-3">
          {isReportOpen && (
            <Modal modalStyle="w-auto" className="" onClose={() => setIsReportOpen(false)}>
              {reportLoading && (
                <div className="h-screen w-screen fixed top-0 left-0 flex justify-center items-center bg-black backdrop-blur-[2px] bg-opacity-80 z-50">
                  <Spinner color="white" />
                </div>
              )}
              <h1 className="font-bold text-xl mb-9 ">Download HIVE Report</h1>
              <div className="">
                <DateRangePicker
                  onChange={(item) => setDateRange([item.selection])}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={1}
                  ranges={dateRange}
                  direction="vertical"
                  inputRanges={[]}
                  maxDate={new Date()}
                />
              </div>
              <button
                onClick={onDownloadReport}
                className="rounded-lg border-[1px] mt-10 h-9 px-5 border-black font-semibold hover:bg-[#4e9bff] hover:text-white hover:border-none"
              >
                Download Now!
              </button>
            </Modal>
          )}

          {(userRole == "admin" || userRole == "super-admin") && (
            <div className="">
              {showDateSelection && (
                <Modal modalStyle="w-auto" className="" onClose={() => setShowDateSelection(false)}>
                  <div className="">
                    <DateRangePicker
                      onChange={(item) => setDateRange([item.selection])}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={1}
                      ranges={dateRange}
                      direction="vertical"
                      inputRanges={[]}
                      maxDate={new Date()}
                    />
                  </div>
                  <button
                    className="rounded-lg border-[1px] mt-8 h-9 px-5 border-black font-semibold hover:bg-[#4e9bff] hover:text-white hover:border-none"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </Modal>
              )}
            </div>
          )}
          {(userRole == "admin" || userRole == "super-admin") && (
            <button onClick={() => setShowDateSelection(true)} className="rounded-lg mr-3 border-[1px] px-5 border-black font-semibold">
              Download Performance Report
            </button>
          )}
          <button onClick={() => setIsReportOpen(true)} className="rounded-lg border-[1px] px-5 border-black font-semibold">
            Download Report
          </button>
        </div>
      )}
      <div className="grid grid-cols-3 gap-5 ">
        {roomsList &&
          roomsList.map((data) => {
            return (
              <div
                key={data._id}
                onClick={() => navigate(`/dashboard/rooms/info/${data._id}`)}
                className="container relative shadow-container cursor-pointer  px-4 py-3 m-auto flex  rounded-2xl border border-opacity-10"
              >
                <div className="lef">
                  <img src={roomImage} className="w-40" alt="" />
                </div>
                <div className="right ml-3 mt-4">
                  <h4 className="font-semibold text-left">{data.name}</h4>
                  <h5 className="  absolute right-4 bottom-4 ">
                    <IoIosArrowForward className="text-2xl text-slate-500" />
                  </h5>
                  <p className=" text-slate-500 text-left text-sm">
                    {data.area}sq. ft. x {data.height}ft.
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default RoomListsDesktop;
