import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const Topnav = () => {
  const navigate = useNavigate();
  const location = useLocation();

  let [navActive, setNav] = useState({
    rooms: true,
    devices: false,
  });

  useEffect(() => {
    // location.pathname.includes("dashboard")
    if (location.pathname.includes("rooms")) {
      setNav({ rooms: true, devices: false });
    }
    if (location.pathname.includes("devices")) {
      setNav({ rooms: false, devices: true });
    }
  }, [window.location.href]);

  return (
    <div className="topBtns w-11/12 mt-2 flex justify-between m-auto">
      <button
        onClick={() => navigate("/dashboard/rooms")}
        className={`w-[49%] ${navActive.rooms ? "bg-black text-white" : "bg-[#F8F8F8] text-[#7B818B]  "}  font-semibold text-lg rounded-lg h-10`}
      >
        Rooms
      </button>
      <button
        onClick={() => navigate("/dashboard/devices")}
        className={`w-[49%] ${navActive.devices ? "bg-black text-white" : "bg-[#F8F8F8] text-[#7B818B]  "}  font-semibold text-lg   rounded-lg h-10`}
      >
        Devices
      </button>
    </div>
  );
};
export default Topnav;
