import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const ticketApi = createApi({
  reducerPath: "ticketApi",
  // configuring baseQuery
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
    prepareHeaders: (headers, { getState }) => {
      console.log("getState", getState);
      const token = JSON.parse(localStorage.getItem("user") || "").token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        headers.set("targetUserID", getState().auth.targetUserID);
      }
    },
  }),
  endpoints: (builder) => ({
    // check if the user has room
    newTicket: builder.mutation({
      query: (deviceObject) => {
        return {
          url: `/ticket/${deviceObject.deviceId}`,
          method: "post",
          body: deviceObject.body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
  }),
});

export const { useNewTicketMutation } = ticketApi;
