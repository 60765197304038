import Modal from "../common/Modal";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useToast } from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useEditDeviceMutation, useUnlinkDeviceMutation } from "../../store/services/deviceApi";
import { useListRoomsMutation } from "../../store/services/roomsApi";
import CustomDropdown from "../../components/common/CustomDropdown";

import UnClosableModal from "../common/UnClosableModal";

const deviceSchema = Yup.object().shape({
  name: Yup.string().min(2, "Name is too short!").max(50, "Name is too long!").required("Device Name is required"),
});

const EditDevice = () => {
  const { deviceName, deviceId, roomId } = useParams();

  const [editDevice, { data: deviceData, isSuccess: isDeviceSuccess, error: deviceError, isError: isDeviceError }] = useEditDeviceMutation();
  const [unlinkDevice, { data: unlinkData, isSuccess: isUnlinkSuccess, error: unlinkError, isError: isUnlinkError }] = useUnlinkDeviceMutation();
  const [listRoom, { data: roomData, isSuccess: isRoomSuccess, error: roomError, isError: isRoomError }] = useListRoomsMutation();

  const toast = useToast();
  const navigate = useNavigate();
  const [options, setOptions] = useState([{ label: "", value: "" }]);
  const [showModal, setShowModal] = useState(false);

  let initialValues = {
    name: deviceName,
    room: roomId,
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: initialValues,
    validationSchema: deviceSchema,
    onSubmit: (values, action) => {
      console.log(values);
      const bodyObject = {
        device: deviceId,
        body: values,
      };
      editDevice(bodyObject);
      //   action.resetForm();
    },
  });

  const handleUnlinkClick = () => {
    console.log("unlingk ---> ", deviceId, deviceName);
    unlinkDevice(deviceId);
    setShowModal(false);
  };

  useEffect(() => {
    listRoom();
  }, []);

  useEffect(() => {
    if (isUnlinkSuccess) {
      toast({
        title: "Device Unlinked Successfully",
        status: "success",
        isClosable: true,
      });
      navigate("/settings");
    }
  }, [unlinkData, isUnlinkSuccess]);

  useEffect(() => {
    if (isRoomSuccess) {
      let optionValues = [];
      for (let i = 0; i < roomData.length; i++) {
        let room = roomData[i];
        optionValues.push({ label: room.name, value: room._id });
      }
      setOptions(optionValues);
    }
  }, [isRoomSuccess, roomData]);

  useEffect(() => {
    if (isDeviceSuccess) {
      toast({
        title: "Device Edited Successfully",
        status: "success",
        isClosable: true,
      });
      navigate(`/dashboard/devices/${deviceData._id}`);
    }
  }, [isDeviceSuccess, deviceError]);

  useEffect(() => {
    if (isDeviceError) {
      console.log(deviceError);
      toast({
        title: `${deviceError.message}`,
        status: "error",
        isClosable: true,
      });
    }
  }, [isDeviceError, deviceError]);

  return (
    <>
      {showModal ? (
        <UnClosableModal>
          <h1 className="font-bold text-xl w-11/12 m-auto">Are you sure you want to Unlink this Device from your account?</h1>
          <p className="text-sm w-11/12 mt-5 m-auto">
            Please be advised that unlinking this device from your account will result in the removal of all associated data from this specific
            device.
          </p>

          <div className="btns grid grid-cols-2 mt-5 gap-2">
            <button onClick={() => setShowModal(false)} className="w-full  bg-[#323232] text-white  font-semibold rounded-lg h-9">
              No
            </button>
            <button onClick={handleUnlinkClick} className="w-full bg-[#EFEFEF] text-black rounded-lg font-semibold h-9">
              Yes
            </button>
          </div>
        </UnClosableModal>
      ) : (
        <Modal>
          <h1 className="font-bold text-xl">Edit Device</h1>
          <form onSubmit={handleSubmit}>
            <div className="inpGrp text-left relative">
              <p className="text-left mt-5 mb-1 font-semibold">Edit Name</p>
              <input
                name="name"
                id="name"
                value={values.name}
                onChange={handleChange}
                type="text"
                placeholder="Device Name"
                className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md w-full"
              />
              <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.name && touched.name ? errors.name : ""}</span>
            </div>
            <div className="inpGrp text-left relative">
              <p className="text-left mt-5 mb-1 font-semibold">Change Device Room</p>
              <CustomDropdown options={options} selectedValue={values.room} onChange={(room) => setFieldValue("room", room)} />

              <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.name && touched.name ? errors.name : ""}</span>
            </div>

            <button type="submit" className="bg-[#323232] text-white w-3/5 font-semibold h-8 mt-6 rounded-md">
              Save
            </button>
            <button onClick={() => setShowModal(true)} className="bg-[#EFEFEF] text-black w-3/5 font-semibold h-8 mt-3 rounded-md">
              Unlink Device
            </button>
          </form>
        </Modal>
      )}
    </>
  );
};

export default EditDevice;
