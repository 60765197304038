import { useNavigate } from "react-router-dom";
import { SkeletonText } from "@chakra-ui/react";
import { isDesktop } from "react-device-detect";

const Modal = (props) => {
  const { isLoading } = props.isLoading ? props.isLoading : false;
  const navigate = useNavigate();

  return (
    <div className="w-screen h-screen z-40 bg-black backdrop-blur-[2px] bg-opacity-80  fixed top-0 left-0">
      <div className={`modal bg-white rounded-2xl absolute top-1/2 -translate-x-1/2 left-1/2 -translate-y-1/2 ${isDesktop ? "w-4/12" : "w-4/5"}`}>
        <div className="relative w-full  h-full py-8 px-5">
          {props.isLoading && (
            <div className="w-11/12   flex flex-col gap-5 m-auto">
              {/* <SkeletonCircle /> */}
              <SkeletonText speed={2} startColor="#ededed" />
            </div>
          )}
          {props.children}
        </div>
      </div>
    </div>
  );
};
export default Modal;
