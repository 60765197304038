const NetworkStrength = ({ signalStrength, signalType, height = "sm" }) => {
  let maxSignalStrength = 100;
  let minSignalStrength = 0;
  // signalStrength = -100;

  if (signalType == "CSQ") {
    maxSignalStrength = 31;
    minSignalStrength = 0;
  } else {
    // maxSignalStrength = -30;
    maxSignalStrength = -40;
    minSignalStrength = -90;
  }

  // Calculate the signal strength percentage
  const percentage = ((signalStrength - minSignalStrength) / (maxSignalStrength - minSignalStrength)) * 100;
  console.log("network percentage --> ", percentage, signalStrength);

  return (
    <div className="flex space-x-0.5 items-end">
      {/* <div className={`h-1 w-[3px] ${percentage >= 10 ? "bg-green-500" : "bg-gray-300"}`} /> */}
      <div className={`h-1 w-1 ${percentage >= 10 ? "bg-good" : "bg-gray-200"}`} />
      <div className={`h-2  w-1 ${percentage >= 50 ? "bg-good" : "bg-gray-200"}`} />
      <div className={`h-3  w-1 ${percentage >= 75 ? "bg-good" : "bg-gray-200"}`} />
    </div>
  );
};

export default NetworkStrength;
