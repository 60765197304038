import moment from "moment";
import React from "react";
import { AreaChart, XAxis, Tooltip, ResponsiveContainer, Area, CartesianGrid, Line, LineChart, ComposedChart } from "recharts";

const PerDayData = ({ graphData, dataKeyObject = { value: "p1", label: "PM 1" } }) => {
  // Ensure that you have data for all 24 hours, even if it's just dummy data
  const xAxisTicks = Array.from({ length: 24 }, (_, index) => {
    const date = new Date(graphData[0]?.saved_at); // Use the first data point's date as a reference
    date.setHours(index);
    return date.getTime();
  });

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="p-2 bg-white bg-opacity-10 border border-gray-300 rounded">
          <p className="label text-left font-sm ">
            {``} <span className="font-bold text-right">{moment(payload[0]?.payload.saved_at).format("h:mm a")}</span>{" "}
            {/* <span>{JSON.stringify(payload[0].payload)}</span> */}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="h-full w-full m-auto flex items-center bg-gray-100">
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          data={graphData}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <XAxis
            dataKey="saved_at"
            type="number"
            // type="number"
            stroke="#B8BEC8CC"
            domain={[xAxisTicks[0], xAxisTicks[xAxisTicks.length - 1]]}
            ticks={xAxisTicks}
            tickFormatter={(timestamp) => moment(timestamp).format("h:mm a")}
            scale="time"
            hide
            // fontSize={3}
          />
          <Tooltip content={<CustomTooltip />} />
          <Area connectNulls dataKey="powerStatus" stroke="#e82c2c" strokeWidth={100} fill="#e82c2c" />
          <Line
            type="step"
            connectNulls={false}
            strokeWidth={0}
            // cy={(val) => val + 40}
            strokeDasharray="4"
            r={10}
            dataKey="powerStatus"
            stroke="#1fa342"
            // strokeOpacity={1}
            // isAnimationActive={true}
            fill={"#1fa342"}
          />
          {/* <CartesianGrid strokeOpacity={0.4} stroke="#B8BEC8CC" strokeDasharray="5 6" /> */}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PerDayData;

// import moment from "moment";
// import React, { PureComponent } from "react";
// import { AreaChart, LineChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";

// const PerDayData = ({ graphData, dataKeyObject = { value: "p1", label: "PM 1" } }) => {
//   console.log("ihbasbhikadfkbhjsdfbhjk", graphData);
//   //   const CustomTooltip = ({ active, payload }) => {
//   const CustomTooltip = ({ active, payload }) => {
//     if (active && payload && payload.length) {
//       return (
//         <div className="p-2 bg-white border border-gray-300 rounded">
//           <p className="label text-left font-sm ">
//             {`Date -`} <span className="font-bold text-right">{moment(payload[0]?.payload.saved_at).format("h:mm a")}</span>{" "}
//           </p>

//           {/* <p className="label">{`P25: ${payload[0].payload.p25}`}</p>
//           <p className="label">{`P10: ${payload[0].payload.p10}`}</p> */}
//         </div>
//       );
//     }
//     return null;
//   };

//   const xAxisTicks = Array.from({ length: 24 }, (_, index) => new Date(0, 0, 1, index));

//   return (
//     <div className="h-full w-full m-auto flex items-center ">
//       <ResponsiveContainer width="100%" height="100%">
//         <AreaChart
//           data={graphData}
//           margin={{
//             top: 0,
//             right: 20,
//             left: 20,
//             bottom: 0,
//           }}
//         >
//           {console.log(
//             "asdsikbh",
//             xAxisTicks.map((tick) => tick.getTime())
//           )}
//           <XAxis
//             dataKey="saved_at"
//             type="number"
//             // type="category" // Use 'category' type for date objects

//             stroke="#B8BEC8CC"
//             domain={["auto", "auto"]}
//             fontSize={10}
//             // ticks={xAxisTicks.map((tick) => tick.getTime())} // Use milliseconds as ticks
//             // tickcount={24}
//             // mintickgap={60 * 60 * 1000}
//             interval={30}
//             hide
//             tickFormatter={(timestamp) => moment(timestamp).format("h:mm a")}
//             scale="time"
//           />
//           {/* <YAxis /> */}
//           <Tooltip content={<CustomTooltip />} />
//           {/* <Tooltip /> */}
//           <Area
//             // type="monotone"
//             type="step"
//             dataKey="powerStatus"
//             stroke="#73C9F900"
//             strokeOpacity={0.91}
//             fill="url(#colorGradient)" // Add the gradient color fill
//           />
//           <defs>
//             <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1.5">
//               <stop offset="10%" stopColor="#0B59B5" stopOpacity={0.4} />
//               <stop offset="100%" stopColor="#73C9F900" stopOpacity={0.1} />
//             </linearGradient>
//           </defs>
//           <CartesianGrid strokeOpacity={0.4} stroke="#B8BEC8CC" strokeDasharray="5 6" />
//         </AreaChart>
//       </ResponsiveContainer>
//     </div>
//   );
// };

// export default PerDayData;
