import React, { PureComponent } from "react";
import { AreaChart, LineChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Bar, BarChart } from "recharts";

const LineGraph = ({ graphData, dataKeyObject = { value: "p1", label: "PM 1" } }) => {
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="p-2 bg-white border border-gray-300 rounded">
          <p className="label text-left font-sm ">
            {`Date -`} <span className="font-bold text-right">{payload[0]?.payload?.minTime?.split(" ")[0]}</span>{" "}
          </p>
          <p className="label text-left font-sm ">
            {`Greatest Drop Observed - `} <span className="font-bold text-right">{payload[0]?.value}%</span>{" "}
          </p>
          <p className="label text-left font-sm ">
            {`Maximum PM before Drop: `} <span className="font-bold text-right">{payload[0]?.payload?.maxPm}</span>{" "}
          </p>
          <p className="label text-left font-sm ">
            {`Minimum PM after Drop: `} <span className="font-bold text-right">{payload[0]?.payload?.minPm}</span>{" "}
          </p>
          <p className="label text-left font-sm ">
            {`CADR: `} <span className="font-bold text-right">{payload[0]?.payload?.cadr}</span>{" "}
          </p>
          <p className="label text-left font-sm ">
            {`Time taken for drop: `} <span className="font-bold text-right">{payload[0]?.payload?.timeDiff}</span>{" "}
          </p>
          {/* <p className="label">{`P25: ${payload[0].payload.p25}`}</p>
          <p className="label">{`P10: ${payload[0].payload.p10}`}</p> */}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="h-full w-full m-auto">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={graphData}
          margin={{
            top: 10,
            right: 20,
            left: 20,
            bottom: 12,
          }}
        >
          {/* <XAxis
            dataKey="maxTime"
            // type="number"
            stroke="#B8BEC8CC"
            fontSize={10}
            // domain={[0, 24]} // Assuming hours range from 0 to 23
            // interval={0}
          /> */}
          {/* <YAxis /> */}
          <Tooltip content={<CustomTooltip />} />
          <Bar
            type="monotone"
            dataKey={dataKeyObject.value}
            stroke="#73C9F900"
            strokeOpacity={0.91}
            fill="url(#colorGradient)" // Add the gradient color fill
          />
          <defs>
            <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1.5">
              <stop offset="10%" stopColor="#0B59B5" stopOpacity={0.4} />
              <stop offset="100%" stopColor="#73C9F900" stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeOpacity={0.4} stroke="#B8BEC8CC" strokeDasharray="5 6" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineGraph;
