import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const authApi = createApi({
  reducerPath: "authApi",
  // configuring baseQuery
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
  }),
  endpoints: (builder) => ({
    // login api call
    login: builder.mutation({
      query: (body) => {
        return {
          url: "/auth/login",
          method: "post",
          body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
    // signup api call
    signup: builder.mutation({
      query: (body) => {
        return {
          url: "/auth/signup",
          method: "post",
          body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
    forgotPassword: builder.mutation({
      query: (body) => {
        return {
          url: "/auth/forgotPassword",
          method: "post",
          body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
    validateResetToken: builder.mutation({
      query: (bodyObject) => {
        return {
          url: `/auth/validateResetToken`,
          method: "post",
          body: bodyObject.body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
    validateUser: builder.mutation({
      query: (bodyObject) => {
        return {
          url: `/auth/validateUser`,
          method: "post",
          body: bodyObject.body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
    deleteUser: builder.mutation({
      query: (body) => {
        return {
          url: `/auth/delete`,
          method: "delete",
          body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
    resetPassword: builder.mutation({
      query: (bodyObject) => {
        return {
          url: `/auth/resetPassword`,
          method: "post",
          body: bodyObject.body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useSignupMutation,
  useForgotPasswordMutation,
  useValidateResetTokenMutation,
  useResetPasswordMutation,
  useValidateUserMutation,
  useDeleteUserMutation,
} = authApi;
