import { useEffect, useState } from "react";
import homeIcon from "../assets/navIcons/home.svg";
import settingIcon from "../assets/navIcons/setting.svg";
import supportIcon from "../assets/navIcons/support.svg";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { HomeIcon, SupportIcon, SettingsOffIcon, SettingsOnIcon, LogoutIcon, HiveHex, HiveHexBold } from "../assets/icons/icons";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { logout } from "../store/features/authSlice";
import Modal from "./common/Modal";
import { BiHive } from "react-icons/bi";
import { AiOutlineDatabase } from "react-icons/ai";
import { clearAdmintargetUserID } from "../store/features/authSlice";

const NavBarDesktop = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [visible, setVisible] = useState(false);
  const dispatch = useAppDispatch();

  const userRole = useAppSelector((state) => state.auth.user.response.role);
  console.log("user role --> ", userRole);

  const handleClientsClick = () => {
    dispatch(clearAdmintargetUserID());
    navigate("/clients");
  };
  const logoutUser = () => {
    dispatch(logout());
    navigate("/");
  };

  let [navActive, setNav] = useState({
    nav1: true,
    nav2: false,
    nav3: false,
    nav4: false,
    nav5: false,
  });
  useEffect(() => {
    // location.pathname.includes("dashboard")
    if (location.pathname.includes("dashboard")) {
      setNav({ nav1: true, nav2: false, nav3: false, nav4: false, nav5: false });
    }
    if (location.pathname.includes("dashboard/devices")) {
      setNav({ nav1: false, nav2: false, nav3: false, nav4: true, nav5: false });
    }
    if (location.pathname.includes("support")) {
      setNav({ nav1: false, nav2: true, nav3: false, nav4: false, nav5: false });
    }
    if (location.pathname.includes("settings")) {
      setNav({ nav1: false, nav2: false, nav3: true, nav4: false, nav5: false });
    }
    if (location.pathname.includes("clients")) {
      setNav({ nav1: false, nav2: false, nav3: false, nav4: false, nav5: true });
    }
  }, [window.location.href]);

  return (
    <div className="left-navbar w-52  pb-safe h-full border-0 border-r-[1px]  pt-5 overflow-hidden bg-white z-20   fixed  left-0">
      {visible && (
        <Modal onClose={() => setVisible(false)}>
          <h1 className="font-bold text-center text-xl w-11/12 m-auto">Are you sure you want to Logout?</h1>

          <div className="btns grid grid-cols-2 mt-10 gap-2">
            <button onClick={() => setVisible(false)} className="w-full  bg-[#323232] text-white  font-semibold rounded-lg h-9">
              No
            </button>
            <button onClick={logoutUser} className="w-full bg-[#EFEFEF] text-black rounded-lg font-semibold h-9">
              Yes
            </button>
          </div>
        </Modal>
      )}
      <div className="navigations w-full items-center relative  h-full ">
        <div onClick={() => navigate("/dashboard/rooms")} className={`w-4/5 cursor-pointer pl-6 h-14 my-1 flex items-center relative`}>
          <HomeIcon className={`${navActive.nav1 && "fill-black"}`} />{" "}
          <h1 className={` ml-7 ${navActive.nav1 ? "font-bold" : "font-semibold"}`}>Rooms</h1>
          {navActive.nav1 && <div className=" h-4/5 w-1 absolute -left-0 bg-black rounded-lg" />}
          {/* <img src={homeIcon} alt="home icon" /> {navActive.nav1 && <div className=" h-4/5 w-1 absolute -left-0 bg-black rounded-lg" />} */}
        </div>
        <div onClick={() => navigate("/dashboard/devices")} className={`w-4/5  cursor-pointer pl-6 h-14 my-1 flex items-center relative`}>
          {navActive.nav4 ? <HiveHexBold className={``} /> : <HiveHex className={``} />}
          <h1 className={` ml-7 ${navActive.nav4 ? "font-bold" : "font-semibold"}`}>Devices</h1>
          {navActive.nav4 && <div className=" h-4/5 w-1 absolute -left-0 bg-black rounded-lg" />}
          {/* <img src={supportIcon} alt="support icon" /> {navActive.nav2 && <div className=" h-4/5 w-1 absolute -left-0 bg-black rounded-lg" />} */}
        </div>
        <div onClick={() => navigate("/support")} className={`w-4/5  cursor-pointer pl-6 h-14 my-1 flex items-center relative`}>
          <SupportIcon className={`${navActive.nav2 ? "stroke-[2.5]" : "stroke-[1.5]"}`} />{" "}
          <h1 className={` ml-7 ${navActive.nav2 ? "font-bold" : "font-semibold"}`}>Support</h1>
          {navActive.nav2 && <div className=" h-4/5 w-1 absolute -left-0 bg-black rounded-lg" />}
          {/* <img src={supportIcon} alt="support icon" /> {navActive.nav2 && <div className=" h-4/5 w-1 absolute -left-0 bg-black rounded-lg" />} */}
        </div>
        <div onClick={() => navigate("/settings")} className={`w-4/5 cursor-pointer pl-6 h-14 my-1 flex items-center relative`}>
          {navActive.nav3 ? <SettingsOnIcon /> : <SettingsOffIcon />}{" "}
          <h1 className={` ml-7 ${navActive.nav3 ? "font-bold" : "font-semibold"}`}>Settings</h1>
          {navActive.nav3 && <div className=" h-4/5 w-1 absolute -left-0 bg-black rounded-lg" />}
        </div>
        {(userRole == "admin" || userRole == "super-admin") && (
          <div onClick={handleClientsClick} className={`w-4/5 cursor-pointer pl-6 h-14  my-1 flex items-center relative`}>
            {navActive.nav5 ? <AiOutlineDatabase className="text-4xl" /> : <AiOutlineDatabase className="text-3xl" />}{" "}
            <h1 className={` ml-7 ${navActive.nav5 ? "font-bold" : "font-semibold"}`}>Clients</h1>
            {navActive.nav5 && <div className=" h-4/5 w-1 absolute -left-0 bg-black rounded-lg" />}
          </div>
        )}

        <div
          onClick={() => setVisible(true)}
          className={`w-4/5  absolute bottom-20
          cursor-pointer pl-6 h-14 my-1 flex items-center `}
        >
          <LogoutIcon className={`${navActive.nav2 ? "stroke-[2.5]" : "stroke-[1.5]"}`} /> <h1 className="font-semibold ml-7">Logout</h1>
          {/* <img src={supportIcon} alt="support icon" /> {navActive.nav2 && <div className=" h-4/5 w-1 absolute -left-0 bg-black rounded-lg" />} */}
        </div>
      </div>
    </div>
  );
};
export default NavBarDesktop;
